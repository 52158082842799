const GET_COUNTRIES = 'GET_COUNTRIES'
const GET_MORE_COUNTRIES = 'GET_MORE_COUNTRIES'
const CREATE_COUNTRY = 'CREATE_COUNTRY'
const EDIT_COUNTRY = 'EDIT_COUNTRY'
const DELETE_COUNTRY = 'DELETE_COUNTRY'

const CLEAR_COUNTRIES = 'CLEAR_COUNTRIES'

export default {
  GET_COUNTRIES,
  GET_MORE_COUNTRIES,
  CLEAR_COUNTRIES,
  CREATE_COUNTRY,
  EDIT_COUNTRY,
  DELETE_COUNTRY,
}
