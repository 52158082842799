import { requests, formDataHeaders } from './api'
import { filesToFormData } from 'utils/serializer'

const createVariant = ({ data, productId }) =>
  requests.post({ url: `/products/${productId}/variants`, data })

const uploadVariantFiles = ({ data, productId, variantId }) =>
  requests.post({
    url: `/products/${productId}/variants/${variantId}/files`,
    data: filesToFormData(data),
    headers: formDataHeaders,
  })

const deleteVariantFiles = ({ data, productId, variantId }) =>
  requests.delete({
    url: `/products/${productId}/variants/${variantId}/files`,
    params: { file_ids: data },
    headers: formDataHeaders,
  })

const updateVariantContent = ({ productId, data, variantId, langId }) =>
  requests.put({
    url: `products/${productId}/variants/${variantId}/content/${langId}`,
    data,
  })
const addVariantContent = ({ productId, data, variantId }) =>
  requests.post({
    url: `products/${productId}/variants/${variantId}/content`,
    data,
  })
const deleteVariantContent = ({ productId, langId, variantId }) =>
  requests.delete({
    url: `products/${productId}/variants/${variantId}/content/${langId}`,
  })
const getVariantContent = ({ productId, params, variantId }) =>
  requests.get({
    url: `products/${productId}/variants/${variantId}/content`,
    params,
  })

const getVariants = ({ params = {}, productId }) =>
  requests.get({ url: `products/${productId}/variants`, params })

const getVariant = ({ productId, variantId }) =>
  requests.get({ url: `products/${productId}/variants/${variantId}` })

const updateVariant = ({ productId, variantId, data }) =>
  requests.put({ url: `products/${productId}/variants/${variantId}`, data })

const deleteVariant = ({ productId, variantId }) =>
  requests.delete({ url: `products/${productId}/variants/${variantId}` })

const getDefaultVariants = ({ params = {} }) =>
  requests.get({ url: 'product-variants', params }).then((res) => res.data)

export default {
  createVariant,
  uploadVariantFiles,
  deleteVariantFiles,
  getVariants,
  getVariant,
  updateVariant,
  deleteVariant,
  updateVariantContent,
  addVariantContent,
  deleteVariantContent,
  getVariantContent,
  getDefaultVariants,
}
