import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import Popup from 'reactjs-popup'

import { authSelectors, authActions } from 'store/ducks/auth'
import { commonActions, commonSelectors } from 'store/ducks/common'

import { THEMES } from 'constants/configs'
import routes, { COMPANY, USER } from 'constants/routes'

import Breadcrumbs from './Breadcrumbs'
import GlobalSearch from './GlobalSearch'
import Toggle from 'components/buttons/Toggle'
import Avatar from 'components/Avatar'

import profileIcon from 'assets/icons/common/profile.svg'
import logoutIcon from 'assets/icons/common/logout.svg'

import styles from './Navbar.module.scss'

const Navbar = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user, isAdmin } = useSelector(authSelectors.getAuthInfo)

  const activeTheme = useSelector(commonSelectors.getActiveTheme)

  const popupItems = useMemo(
    () => [
      {
        name: t('common:darkMode'),
        Component: () => (
          <Toggle
            onChange={() => dispatch(commonActions.setTheme())}
            isChecked={activeTheme === THEMES.dark}
          />
        ),
        handleClick: () => dispatch(commonActions.setTheme()),
      },
      {
        name: t('common:profile'),
        icon: profileIcon,
        handleClick: () =>
          navigate(
            isAdmin
              ? `${routes.companyManagement.root}/${COMPANY}/${user?.company?.uuid}/${USER}/${user?.uuid}`
              : `${routes.companyManagement.myCompany}/${USER}/${user?.uuid}`
          ),
      },
      {
        name: t('common:logout'),
        icon: logoutIcon,
        handleClick: () => dispatch(authActions.logout()),
      },
    ],
    [t, dispatch, activeTheme, navigate, user, isAdmin]
  )

  return (
    <div className={styles.navbarContainer}>
      <Breadcrumbs />
      <div className={styles.navbarContainer_row}>
        <div className={styles.navbarContainer_search}>
          <GlobalSearch />
        </div>
        <Popup
          trigger={
            <div>
              <Avatar
                image={user?.profile}
                firstname={user?.firstname}
                lastname={user?.lastname}
              />
            </div>
          }
          position={'bottom right'}
          arrow={false}
        >
          <div className={`${styles.popper} ${activeTheme}`}>
            {popupItems.map((el) => (
              <div key={el.name} className={styles.popper_row}>
                <div className={styles.popper_row_imageContainer}>
                  {el?.icon ? (
                    <img
                      src={el.icon}
                      alt={el.name}
                      className={styles.popper_row_imageContainer_image}
                      onClick={el.handleClick}
                    />
                  ) : (
                    el.Component()
                  )}
                </div>
                <p className={styles.popper_row_title} onClick={el.handleClick}>
                  {el.name}
                </p>
              </div>
            ))}
          </div>
        </Popup>
      </div>
    </div>
  )
}

export default Navbar
