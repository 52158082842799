const emptyObject = {}
const emptyArray = []

const getReports = (state) => state.report.reports.items
const getReportsLoading = (state) => state.report.reports.loading
const getReportsPages = (state) => state.report.reports.totalPages

const getReport = (state) => state.report.activeReport.item
const getReportLoading = (state) => state.report.activeReport.loading

const getReportRows = (state) => state.report.reportRows.items
const getReportRowLoading = (state) => state.report.reportRows.loading
const getReportRowsPages = (state) => state.report.reportRows.totalPages

const getReportRowProblems = (state, rowId) =>
  state.report.rowsInfo[rowId]?.problems || emptyArray
const getReportRowProblemsLoading = (state, rowId) =>
  state.report.rowsInfo[rowId]?.isProblemsLoading || false
const getReportRowCertificates = (state, rowId) =>
  state.report.rowsInfo[rowId]?.certificates || emptyArray
const getReportRowCertificatesLoading = (state, rowId) =>
  state.report.rowsInfo[rowId]?.isCertificatesLoading || false
const getReportRowHistory = (state, rowId) =>
  state.report.rowsInfo[rowId]?.history || emptyObject
const getIsReportRowProblemsExist = (state, rowId) =>
  !!state.report.rowsInfo[rowId]

const getReportRowFields = (state, rowId) =>
  state.report.rowsInfo[rowId]?.fields || emptyObject

const getReportDefectCategories = (state) => state.report.defectCategories.items
const getReportDefectCategoriesLoading = (state) =>
  state.report.defectCategories.loading
const getReportDefectCategoriesPages = (state) =>
  state.report.defectCategories.totalPages
const getReportDefectCategoriesMoreLoading = (state) =>
  state.report.defectCategories.moreLoading

const getReportDefects = (state) => state.report.defects.items
const getReportDefectsLoading = (state) => state.report.defects.loading
const getReportDefectsPages = (state) => state.report.defects.totalPages
const getReportDefectsMoreLoading = (state) => state.report.defects.moreLoading

const getReportOrders = (state) => state.report.reportOrders.items
const getReportOrdersLoading = (state) => state.report.reportOrders.loading
const getReportOrdersPages = (state) => state.report.reportOrders.totalPages

export default {
  getReports,
  getReportsLoading,
  getReportsPages,
  getReport,
  getReportLoading,
  getReportRows,
  getReportRowLoading,
  getReportRowsPages,
  getReportRowProblems,
  getReportRowProblemsLoading,
  getIsReportRowProblemsExist,
  getReportDefectCategories,
  getReportDefectCategoriesLoading,
  getReportDefectCategoriesPages,
  getReportDefectCategoriesMoreLoading,
  getReportDefects,
  getReportDefectsLoading,
  getReportDefectsPages,
  getReportDefectsMoreLoading,
  getReportRowCertificates,
  getReportRowCertificatesLoading,
  getReportRowHistory,
  getReportRowFields,
  getReportOrders,
  getReportOrdersLoading,
  getReportOrdersPages,
}
