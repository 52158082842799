const GET_PRODUCT_DEFECTS = 'GET_PRODUCT_DEFECTS'
const GET_MORE_PRODUCT_DEFECTS = 'GET_MORE_PRODUCT_DEFECTS'
const GET_PRODUCT_DEFECT = 'GET_PRODUCT_DEFECT'
const CREATE_PRODUCT_DEFECT = 'CREATE_PRODUCT_DEFECT'
const DELETE_PRODUCT_DEFECT = 'DELETE_PRODUCT_DEFECT'
const EDIT_PRODUCT_DEFECT = 'EDIT_PRODUCT_DEFECT'

const CLEAR_PRODUCT_DEFECTS = 'CLEAR_PRODUCT_DEFECTS'

export default {
  GET_PRODUCT_DEFECTS,
  GET_MORE_PRODUCT_DEFECTS,
  GET_PRODUCT_DEFECT,
  CREATE_PRODUCT_DEFECT,
  DELETE_PRODUCT_DEFECT,
  EDIT_PRODUCT_DEFECT,
  CLEAR_PRODUCT_DEFECTS,
}
