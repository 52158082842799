export default {
  search: 'of products',
  accessTitle: 'Collection Access Settings',
  newProduct: 'New Product',
  emptyDescription: 'There are no products under this category right now',
  collection: 'Collection',
  createCollection: 'Create New Collection',
  collectionName: 'Collection Name',
  collectionNameHolder: 'Enter Collection Name',
  CollectionDescHolder: 'Enter Collection Description',
  create: 'Create Collection',
  delete_collection: 'Delete Collection',
  delete_collection_title: 'You are about to delete the collection',
  delete_collection_description: 'This will delete the collection from catalog',
}
