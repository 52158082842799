import { requests } from '../api'

const initialEndpoint = 'languages'

const getLanguages = ({ params }) =>
  requests.get({ url: initialEndpoint, params }).then((res) => res.data)

const createLanguage = ({ data }) =>
  requests.post({ url: initialEndpoint, data }).then((res) => res.data)

const editLanguage = ({ data, id }) =>
  requests
    .put({ url: `${initialEndpoint}/${id}`, data })
    .then((res) => res.data)

const deleteLanguage = ({ id }) =>
  requests.delete({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)

const getActiveLanguage = ({ isoCode }) =>
  requests.get({ url: `${initialEndpoint}/by-iso/${isoCode}` })

export default {
  getLanguages,
  getActiveLanguage,
  createLanguage,
  editLanguage,
  deleteLanguage,
}
