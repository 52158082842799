import { toast } from 'react-toastify'

import customFields from 'services/customFields'
import { templateActions } from '../template'
import types from './actionTypes'
import templateTypes from '../template/actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import { clearParams } from 'utils/helpers'
import i18n from 'translations'

const fetchCustomFields =
  ({ params, isTemplate = false }) =>
  (dispatch) => {
    const activeType = isTemplate
      ? templateTypes.GET_TEMPLATE_FIELDS
      : types.GET_CUSTOM_FIELDS
    dispatch({ type: PENDING(activeType) })
    clearParams({ params })
    customFields
      .getCustomFields({ params })
      .then((res) => dispatch({ type: SUCCESS(activeType), payload: res }))
      .catch(() =>
        dispatch({
          type: FAILURE(activeType),
        })
      )
  }

const fetchCustomField =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_CUSTOM_FIELD) })
    customFields
      .getCustomField({ id })
      .then((res) =>
        dispatch({ type: SUCCESS(types.GET_CUSTOM_FIELD), payload: res.data })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_CUSTOM_FIELD),
        })
      )
  }

const createCustomField =
  ({ data, companyId, templateId = '' }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.CREATE_CUSTOM_FIELD) })
    const requestData = {
      ...data,
      company_id: companyId,
      type: data?.type?.value,
    }
    const { is_required, ...values } = requestData
    return customFields
      .createCustomField({ data: values })
      .then((res) => {
        toast.success(
          `${i18n.t('quality_control:templates:field')} ${i18n.t(
            'common:create_success'
          )}`
        )
        dispatch({
          type: SUCCESS(types.CREATE_CUSTOM_FIELD),
          payload: res.data,
        })
        if (templateId) {
          return dispatch(
            templateActions.addTemplateField({
              templateId,
              fieldId: res.data.uuid,
              data: { is_required },
              payload: res.data,
            })
          ).then(() => res)
        } else {
          return res
        }
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.CREATE_CUSTOM_FIELD),
        })

        throw err
      })
  }

const deleteCustomField =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_CUSTOM_FIELD) })
    return customFields
      .deleteCustomField({ id })
      .then((res) => {
        toast.success(
          `${i18n.t('quality_control:templates:field')} ${i18n.t(
            'common:delete_success'
          )}`
        )
        dispatch({
          type: SUCCESS(types.DELETE_CUSTOM_FIELD),
          payload: res.data,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.DELETE_CUSTOM_FIELD),
        })

        throw err
      })
  }

const editCustomField =
  ({ data, id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.EDIT_CUSTOM_FIELD) })
    return customFields
      .editCustomField({ data, id })
      .then((res) => {
        toast.success(
          `${i18n.t('quality_control:templates:field')} ${i18n.t(
            'common:edit_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.EDIT_CUSTOM_FIELD), payload: res.data })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.EDIT_CUSTOM_FIELD),
        })

        throw err
      })
  }

const clearCustomFields = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_CUSTOM_FIELDS) })

export default {
  fetchCustomFields,
  fetchCustomField,
  createCustomField,
  deleteCustomField,
  editCustomField,
  clearCustomFields,
}
