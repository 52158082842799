import { toast } from 'react-toastify'

import countries from 'services/globalAttributes/countries'
import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import i18n from 'translations'
import { clearParams } from 'utils/helpers'

const fetchCountries =
  ({ params, isPaginate = false }) =>
  (dispatch) => {
    const activeType = isPaginate
      ? types.GET_MORE_COUNTRIES
      : types.GET_COUNTRIES
    dispatch({ type: PENDING(activeType) })
    clearParams({ params })
    countries
      .getCountries({ params })
      .then((res) => dispatch({ type: SUCCESS(activeType), payload: res }))
      .catch(() =>
        dispatch({
          type: FAILURE(activeType),
        })
      )
  }

const createCountry =
  ({ data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.CREATE_COUNTRY) })
    return countries
      .createCountry({ data })
      .then((res) => {
        toast.success(
          `${i18n.t('attributes:countries:single_name')} ${i18n.t(
            'common:create_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.CREATE_COUNTRY), payload: res })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.CREATE_COUNTRY),
        })
      )
  }

const editCountry =
  ({ data, id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.EDIT_COUNTRY) })
    return countries
      .editCountry({ data, id })
      .then((res) => {
        toast.success(
          `${i18n.t('attributes:countries:single_name')} ${i18n.t(
            'common:edit_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.EDIT_COUNTRY), payload: res })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.EDIT_COUNTRY),
        })
      )
  }

const deleteCountry =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_COUNTRY) })
    countries
      .deleteCountry({ id })
      .then(() => {
        toast.success(
          `${i18n.t('attributes:countries:single_name')} ${i18n.t(
            'common:delete_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.DELETE_COUNTRY), payload: id })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.DELETE_COUNTRY),
        })
      )
  }

const clearCountries = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_COUNTRIES) })

export default {
  fetchCountries,
  clearCountries,
  createCountry,
  editCountry,
  deleteCountry,
}
