import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const initialState = {
  templates: {
    items: [],
    isLoading: true,
    error: '',
    totalPages: 0,
    isMoreLoading: false,
  },
  activeTemplate: {
    item: null,
    isLoading: true,
    error: '',
  },
  templateFields: {
    items: [],
    isLoading: true,
    error: '',
  },
}

const templateReducer = (state = initialState, { payload, type }) => {
  let index
  let newItems
  switch (type) {
    case PENDING(types.GET_TEMPLATES):
      return {
        ...state,
        templates: {
          ...state.templates,
          isLoading: true,
        },
      }
    case SUCCESS(types.GET_TEMPLATES):
      return {
        ...state,
        templates: {
          ...state.templates,
          items: payload.data,
          totalPages: payload.meta.last_page,
          isLoading: false,
        },
      }
    case FAILURE(types.GET_TEMPLATES):
      return {
        ...state,
        templates: {
          ...state.templates,
          isLoading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_MORE_TEMPLATES):
      return {
        ...state,
        templates: {
          ...state.templates,
          isMoreLoading: true,
        },
      }
    case SUCCESS(types.GET_MORE_TEMPLATES):
      return {
        ...state,
        templates: {
          ...state.templates,
          items: [...state.templates.items, ...payload.data],
          isMoreLoading: false,
        },
      }
    case FAILURE(types.GET_MORE_TEMPLATES):
      return {
        ...state,
        templates: {
          ...state.templates,
          isMoreLoading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_TEMPLATE):
      return {
        ...state,
        activeTemplate: {
          ...state.activeTemplate,
          isLoading: true,
        },
      }
    case SUCCESS(types.GET_TEMPLATE):
      return {
        ...state,
        activeTemplate: {
          ...state.activeTemplate,
          item: payload,
          isLoading: false,
        },
      }
    case FAILURE(types.GET_TEMPLATE):
      return {
        ...state,
        activeTemplate: {
          ...state.activeTemplate,
          isLoading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_TEMPLATE_FIELDS):
      return {
        ...state,
        templateFields: {
          ...state.templateFields,
          isLoading: true,
        },
      }
    case SUCCESS(types.GET_TEMPLATE_FIELDS):
      return {
        ...state,
        templateFields: {
          ...state.templateFields,
          items: payload.data,
          isLoading: false,
        },
      }
    case FAILURE(types.GET_TEMPLATE_FIELDS):
      return {
        ...state,
        templateFields: {
          ...state.templateFields,
          isLoading: false,
          error: payload,
        },
      }
    case SUCCESS(types.ADD_FIELD_TO_TEMPLATE):
      return {
        ...state,
        templateFields: {
          ...state.templateFields,
          items: [...state.templateFields.items, payload],
        },
      }
    case SUCCESS(types.REMOVE_FIELD_FROM_TEMPLATE):
      index = state.templateFields.items.findIndex((el) => el.uuid === payload)
      return {
        ...state,
        templateFields: {
          ...state.templateFields,
          items: [
            ...state.templateFields.items.slice(0, index),
            ...state.templateFields.items.slice(index + 1),
          ],
        },
      }
    case SUCCESS(types.CHANGE_FIELD_POSITION):
      newItems = state.templateFields.items
      const item = newItems.splice(payload.oldPosition - 1, 1)[0]
      newItems.splice(payload.position - 1, 0, item)
      return {
        ...state,
        templateFields: {
          ...state.templateFields,
          items: newItems,
        },
      }
    case SUCCESS(types.CREATE_TEMPLATE):
      return {
        ...state,
        templates: {
          ...state.templates,
          items: [...state.templates.items, payload],
        },
      }
    case SUCCESS(types.DELETE_TEMPLATE):
      return {
        ...state,
        activeTemplate: {
          item: null,
          isLoading: true,
          error: '',
        },
      }
    case SUCCESS(types.EDIT_TEMPLATE):
      return {
        ...state,
        activeTemplate: {
          item: payload,
          isLoading: false,
          error: '',
        },
      }
    case SUCCESS(types.EDIT_TEMPLATE_FIELD):
      newItems = state.templateFields.items
      index = newItems.findIndex((el) => el.uuid === payload.fieldId)
      if (index >= 0) {
        newItems[index] = {
          ...newItems[index],
          is_required: payload.data.is_required,
        }
      }
      return {
        ...state,
        templateFields: {
          ...state.templateFields,
          items: [...newItems],
        },
      }
    case SUCCESS(types.CLEAR_TEMPLATE):
      return {
        ...state,
        activeTemplate: {
          item: null,
          isLoading: true,
          error: '',
        },
        templateFields: {
          items: [],
          isLoading: true,
          error: '',
        },
      }
    case SUCCESS(types.CLEAR_TEMPLATES):
      return {
        ...state,
        templates: {
          items: [],
          isLoading: true,
          error: '',
          totalPages: 0,
        },
      }
    default:
      return state
  }
}

export default templateReducer
