import { toast } from 'react-toastify'
import certifications from 'services/certifications'
import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import i18n from 'translations'
import { clearParams, isFieldsEqual } from 'utils/helpers'

const fetchCertificates =
  ({ params }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_CERTIFICATES) })
    clearParams({ params })
    certifications
      .getCertificates({ params })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_CERTIFICATES),
          payload: res,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_CERTIFICATES),
          payload: err.response.data.message,
        })
      )
  }

const createCertificate =
  ({ data }) =>
  (dispatch) => {
    const { logo, ...values } = data
    dispatch({ type: PENDING(types.CREATE_CERTIFICATE) })
    return certifications
      .createCertificate({ data: values })
      .then((res) => {
        toast.success(
          `${i18n.t('attributes:company_certificates:single_name')} ${i18n.t(
            'common:create_success'
          )}`
        )
        dispatch({
          type: SUCCESS(types.CREATE_CERTIFICATE),
          payload: res,
        })
        dispatch(uploadCertificateLogo({ data: logo, id: res.data.uuid }))
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.CREATE_CERTIFICATE),
          payload: err.response.data.message,
        })
      )
  }

const editCertificate =
  ({ data, id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.EDIT_CERTIFICATE) })
    return certifications
      .editCertificate({ data, id })
      .then((res) => {
        toast.success(
          `${i18n.t('attributes:company_certificates:single_name')} ${i18n.t(
            'common:edit_success'
          )}`
        )
        dispatch({
          type: SUCCESS(types.EDIT_CERTIFICATE),
          payload: res,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.EDIT_CERTIFICATE),
          payload: err.response.data.message,
        })
      )
  }

const deleteCertificate =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_CERTIFICATE) })
    return certifications
      .deleteCertificate({ id })
      .then(() => {
        toast.success(
          `${i18n.t('attributes:company_certificates:single_name')} ${i18n.t(
            'common:delete_success'
          )}`
        )
        dispatch({
          type: SUCCESS(types.DELETE_CERTIFICATE),
          payload: id,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.DELETE_CERTIFICATE),
          payload: err.response.data.message,
        })
      )
  }

const uploadCertificateLogo =
  ({ data, id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.UPLOAD_CERTIFICATE_LOGO) })
    certifications
      .uploadCertificateLogo({ data, id })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.UPLOAD_CERTIFICATE_LOGO),
          payload: res,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.UPLOAD_CERTIFICATE_LOGO),
          payload: err.response.data.message,
        })
      )
  }

const changeCertificateInfo =
  ({ data, certificate }) =>
  (dispatch) => {
    const { logo, ...values } = data
    const isEqual = isFieldsEqual(values, certificate)

    const promises = []

    if (!isEqual) {
      promises.push(
        dispatch(editCertificate({ id: certificate.uuid, data: values }))
      )
    }

    if (logo?.preview) {
      promises.push(
        dispatch(uploadCertificateLogo({ id: certificate.uuid, data: logo }))
      )
    }

    return Promise.all(promises)
  }

const clearCertificates = () => (dispatch) =>
  dispatch({
    type: SUCCESS(types.CLEAR_CERTIFICATES),
  })

export default {
  fetchCertificates,
  createCertificate,
  editCertificate,
  deleteCertificate,
  uploadCertificateLogo,
  changeCertificateInfo,
  clearCertificates,
}
