import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const initialState = {
  customFields: {
    items: [],
    isLoading: true,
    error: '',
    totalPages: 0,
  },
}

const customFieldReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case PENDING(types.GET_CUSTOM_FIELDS):
      return {
        ...state,
        customFields: {
          ...state.customFields,
          isLoading: true,
        },
      }
    case SUCCESS(types.GET_CUSTOM_FIELDS):
      return {
        ...state,
        customFields: {
          ...state.customFields,
          items: payload.data,
          totalPages: payload.meta.last_page,
          isLoading: false,
        },
      }
    case FAILURE(types.GET_CUSTOM_FIELDS):
      return {
        ...state,
        customFields: {
          ...state.customFields,
          isLoading: false,
          error: payload,
        },
      }
    case SUCCESS(types.CREATE_CUSTOM_FIELD):
      return {
        ...state,
        customFields: {
          ...state.customFields,
          items: [...state.customFields.items, payload],
        },
      }
    case SUCCESS(types.CLEAR_CUSTOM_FIELDS):
      return {
        ...state,
        customFields: {
          items: [],
          isLoading: true,
          error: '',
          totalPages: 0,
        },
      }
    default:
      return state
  }
}

export default customFieldReducer
