import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const initialState = {
  permissionGroupTools: {
    items: [],
    loading: false,
    error: '',
  },
  permissionGroupToolsMatrix: {
    item: null,
    loading: false,
    error: '',
  },
}

const permissionGroupReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case PENDING(types.GET_PERMISSIONS_GROUPS_TOOLS):
      return {
        ...state,
        permissionGroupTools: {
          ...state.permissionGroupTools,
          loading: true,
        },
      }
    case SUCCESS(types.GET_PERMISSIONS_GROUPS_TOOLS):
      return {
        ...state,
        permissionGroupTools: {
          ...state.permissionGroupTools,
          items: payload.data,
          loading: false,
        },
      }
    case FAILURE(types.GET_PERMISSIONS_GROUPS_TOOLS):
      return {
        ...state,
        permissionGroupTools: {
          ...state.permissionGroupTools,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_PERMISSIONS_GROUPS_TOOLS_MATRIX):
      return {
        ...state,
        permissionGroupToolsMatrix: {
          ...state.permissionGroupToolsMatrix,
          loading: true,
        },
      }
    case SUCCESS(types.GET_PERMISSIONS_GROUPS_TOOLS_MATRIX):
      return {
        ...state,
        permissionGroupToolsMatrix: {
          ...state.permissionGroupToolsMatrix,
          item: {
            ...state.permissionGroupToolsMatrix.item,
            [payload.activeTool]: payload.data,
          },
          loading: false,
        },
      }
    case FAILURE(types.GET_PERMISSIONS_GROUPS_TOOLS_MATRIX):
      return {
        ...state,
        permissionGroupToolsMatrix: {
          ...state.permissionGroupToolsMatrix,
          loading: false,
          error: payload,
        },
      }
    case SUCCESS(types.CLEAR_PERMISSIONS_GROUPS):
      return {
        ...state,
        permissionGroupTools: {
          items: [],
          loading: false,
          error: '',
        },
        permissionGroupToolsMatrix: {
          item: null,
          loading: false,
          error: '',
        },
      }
    default:
      return state
  }
}

export default permissionGroupReducer
