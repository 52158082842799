import React from 'react'
import PropTypes from 'prop-types'

import testImg from 'assets/images/test2.png'

import styles from './SearchCard.module.scss'

const SearchCard = ({
  name,
  description = '',
  logo = null,
  files = [],
  link,
  handleClick,
}) => {
  return (
    <div className={styles.searchCard} onClick={() => handleClick(link)}>
      <div className={styles.searchCard_info}>
        <img
          src={logo?.full_path || testImg}
          alt={logo?.file_name || 'test image'}
          className={styles.searchCard_info_logo}
        />
        <div>
          <h3>{name}</h3>
          {description && !description?.includes('undefined') ? (
            <p className={styles.searchCard_info_description}>{description}</p>
          ) : null}
        </div>
      </div>
    </div>
  )
}

SearchCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  logo: PropTypes.object,
  files: PropTypes.array,
  link: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default SearchCard
