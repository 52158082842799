import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './en'

const resources = {
  en,
}

if (!localStorage.getItem('i18nextLng')) {
  localStorage.setItem('i18nextLng', 'en')
}

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('i18nextLng') || 'en',
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: 'en',
})

export default i18n
