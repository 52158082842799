export default {
  size: 'Size',
  color: 'Color',
  material: 'Material',
  global: 'Global Attributes',
  symbol: 'Symbol',
  unicode_format: 'Unicode format',
  iso_code: 'ISO Code',
  iso_code_placeholder: 'Enter ISO Code',
  flag: 'Flag',
  currencies: {
    name: 'Currencies',
    empty_description: 'There is no currencies yet',
    add: 'Add New Currency',
    name_placeholder: 'Enter currency name',
    create: 'Create Currency',
    edit: 'Edit Currency',
    delete_title: 'You are about to delete a currency',
    delete_description: 'This will delete currency from catalog',
    empty_preview: 'Enter Currency Info',
    single_name: 'Currency',
  },
  languages: {
    name: 'Languages',
    empty_description: 'There is no languages yet',
    add: 'Add New Language',
    name_placeholder: 'Enter language name',
    create: 'Create Language',
    edit: 'Edit Language',
    delete_title: 'You are about to delete a language',
    delete_description: 'This will delete language from catalog',
    empty_preview: 'Enter Language Info',
    single_name: 'Language',
  },
  countries: {
    name: 'Countries',
    empty_description: 'There is no countries yet',
    add: 'Add New Country',
    name_placeholder: 'Enter Country name',
    create: 'Create Country',
    edit: 'Edit Country',
    delete_title: 'You are about to delete a country',
    delete_description: 'This will delete country from catalog',
    empty_preview: 'Enter Country Info',
    single_name: 'Country',
  },
  company_certificates: {
    name: 'Company Ceritifcates',
    empty_description: 'There is no ceritifcates yet',
    add: 'Add New Ceritifcate',
    name_placeholder: 'Enter Ceritifcate name',
    create: 'Create Ceritifcate',
    edit: 'Edit Ceritifcate',
    delete_title: 'You are about to delete a ceritifcate',
    delete_description: 'This will delete ceritifcate from catalog',
    empty_preview: 'Enter Ceritifcate Info',
    authority_name: 'Authority Name',
    authority_name_placeholder: 'Enter Authority Name',
    single_name: 'Certificate',
  },
  regions: {
    name: 'Regions',
    add: 'Add New Region',
    empty_description: 'There is no regions yet',
    name_placeholder: 'Enter Region name',
    create: 'Create Region',
    edit: 'Edit Region',
    delete_title: 'You are about to delete a region',
    delete_description: 'This will delete a region from catalog',
    empty_preview: 'Enter Region Info',
    single_name: 'Region',
  },
}
