import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const initialState = {
  collections: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
    moreLoading: false,
  },
  sharedCollections: {
    items: [],
    loading: false,
    error: '',
    totalItems: 0,
    totalPages: 0,
  },
  activeCollection: {
    item: null,
    error: '',
    loading: false,
    isContentLoading: false,
  },
}

const collectionReducer = (state = initialState, { payload, type }) => {
  let activeIndex = 0
  switch (type) {
    case PENDING(types.GET_COLLECTION_BY_ID):
      return {
        ...state,
        activeCollection: {
          ...state.activeCollection,
          loading: true,
        },
      }
    case SUCCESS(types.GET_COLLECTION_BY_ID):
      return {
        ...state,
        activeCollection: {
          ...state.activeCollection,
          item: payload.data,
          error: '',
          loading: false,
        },
      }
    case FAILURE(types.GET_COLLECTION_BY_ID):
      return {
        ...state,
        activeCollection: {
          ...state.activeCollection,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_COLLECTIONS):
      return {
        ...state,
        collections: {
          ...state.collections,
          loading: true,
        },
      }
    case SUCCESS(types.GET_COLLECTIONS):
      return {
        ...state,
        collections: {
          items: payload.data,
          error: '',
          loading: false,
          totalPages: payload?.meta?.last_page || 0,
        },
      }
    case FAILURE(types.GET_COLLECTIONS):
      return {
        ...state,
        collections: {
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_MORE_COLLECTIONS):
      return {
        ...state,
        collections: {
          ...state.collections,
          moreLoading: true,
        },
      }
    case SUCCESS(types.GET_MORE_COLLECTIONS):
      return {
        ...state,
        collections: {
          ...state.collections,
          items: [...state.collections.items, ...payload.data],
          error: '',
          moreLoading: false,
        },
      }
    case FAILURE(types.GET_MORE_COLLECTIONS):
      return {
        ...state,
        collections: {
          ...state.collections,
          moreLoading: false,
          error: payload,
        },
      }
    case SUCCESS(types.CLEAR_COLLECTION):
      return {
        ...state,
        activeCollection: {
          item: null,
          error: '',
          loading: false,
        },
      }
    case PENDING(types.GET_SHARED_COLLECTIONS):
      return {
        ...state,
        sharedCollections: {
          ...state.sharedCollections,
          loading: true,
        },
      }
    case SUCCESS(types.GET_SHARED_COLLECTIONS):
      return {
        ...state,
        sharedCollections: {
          ...state.sharedCollections,
          items: payload.data,
          error: '',
          loading: false,
          totalItems: payload?.meta?.total || 0,
          totalPages: payload?.meta?.last_page || 0,
        },
      }
    case FAILURE(types.GET_SHARED_COLLECTIONS):
      return {
        ...state,
        sharedCollections: {
          ...state.sharedCollections,
          loading: false,
          error: payload,
        },
      }

    case SUCCESS(types.UPDATE_COLLECTION_CONTENT):
      return {
        ...state,
        activeCollection: {
          ...state.activeCollection,
          item: {
            ...state.activeCollection.item,
            content: payload.data,
          },
        },
      }
    case SUCCESS(types.ADD_COLLECTION_CONTENT):
      return {
        ...state,
        activeCollection: {
          ...state.activeCollection,
          item: {
            ...state.activeCollection.item,
            content: payload.data,
            language_ids: [
              ...state.activeCollection.item.language_ids,
              payload.data.language.uuid,
            ],
          },
        },
      }
    case SUCCESS(types.DELETE_COLLECTION_CONTENT):
      activeIndex = state.activeCollection.item.language_ids.findIndex(
        (el) => el === payload
      )
      return {
        ...state,
        activeCollection: {
          ...state.activeCollection,
          item: {
            ...state.activeCollection.item,
            language_ids: [
              ...state.activeCollection.item.language_ids.slice(0, activeIndex),
              ...state.activeCollection.item.language_ids.slice(
                activeIndex + 1
              ),
            ],
          },
        },
      }
    case PENDING(types.GET_COLLECTION_CONTENT):
      return {
        ...state,
        activeCollection: {
          ...state.activeCollection,
          isContentLoading: true,
        },
      }
    case SUCCESS(types.GET_COLLECTION_CONTENT):
      return {
        ...state,
        activeCollection: {
          ...state.activeCollection,
          isContentLoading: false,
          item: {
            ...state.activeCollection.item,
            content: payload?.data[0],
          },
        },
      }
    case FAILURE(types.GET_COLLECTION_CONTENT):
      return {
        ...state,
        activeCollection: {
          ...state.activeCollection,
          isContentLoading: false,
        },
      }
    case SUCCESS(types.CLEAR_COLLECTIONS):
      return {
        ...state,
        collections: {
          items: [],
          loading: true,
          error: '',
          totalPages: 0,
          moreLoading: false,
        },
      }
    default:
      return state
  }
}

export default collectionReducer
