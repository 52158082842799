import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'

import styles from './CommonAccordion.module.scss'
import { ArrowIcon } from 'assets/icons/common'

const CommonAccordion = ({
    Icon = null,
    name,
    children,
    customIsActive = false,
}) => {
    const [isActive, setIsActive] = useState(false)

    const handleAccordion = useCallback(() => {
        setIsActive((prev) => !prev)
    }, [])

    return (
        <>
            <div onClick={handleAccordion} className={styles.commonAccordion_head}>
                <div className={styles.commonAccordion_head_info}>
                    {Icon ? (
                        <Icon className={styles.commonAccordion_head_info_icon} />
                    ) : null}
                    <p className={styles.commonAccordion_head_info_name}>{name}</p>
                </div>
                <ArrowIcon
                    className={`${styles.commonAccordion_head_arrow} ${isActive || customIsActive
                            ? styles.commonAccordion_head_activeArrow
                            : ''
                        }`}
                />
            </div>
            <AnimateHeight
                duration={500}
                height={isActive || customIsActive ? 'auto' : 0}
            >
                {children}
            </AnimateHeight>
        </>
    )
}

CommonAccordion.propTypes = {
    Icon: PropTypes.func,
    name: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    customIsActive: PropTypes.bool,
}

export default CommonAccordion
