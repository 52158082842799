import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { commonActions } from 'store/ducks/common'

import SearchField from 'components/fields/SearchField'
import SearchPopup from './helpers/SearchPopup'

import styles from './GlobalSearch.module.scss'

const GlobalSearch = () => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [isPopup, setIsPopup] = useState(false)

  const ref = useRef(null)

  const { t } = useTranslation()

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current) {
        if (!ref.current.contains(event.target) && isPopup) {
          setIsPopup(false)
        }
        if (ref.current.contains(event.target) && search && !isPopup) {
          setIsPopup(true)
        }
      }
    },
    [isPopup, search]
  )

  useEffect(() => {
    if (search) {
      setIsPopup(true)
      dispatch(commonActions.fetchGlobalSearch({ params: { search } }))
    } else {
      setIsPopup(false)
    }
  }, [dispatch, search])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  const handleSearch = useCallback((search) => {
    setSearch(search)
  }, [])

  const handlePopup = useCallback((value) => {
    setIsPopup(value)
  }, [])

  return (
    <div className={styles.globalSearch} ref={ref}>
      <SearchField
        placeholder={t('common:global_search')}
        onChange={handleSearch}
      />
      {isPopup ? <SearchPopup handlePopup={handlePopup} /> : null}
    </div>
  )
}

export default GlobalSearch
