import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import 'translations'
import configureStore from './store'
import { Provider } from 'react-redux'

import './index.scss'
import './theme.scss'
import 'react-loading-skeleton/dist/skeleton.css'

import Loader from 'components/Loader'

const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loader />}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)
