import { toast } from 'react-toastify'

import products from 'services/products'
import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import { clearParams, handleBaseInfo } from 'utils/helpers'
import i18n from 'translations'

import { collectionActions } from '../collection'

const fetchProductsByCollection =
  ({ params, isPaginate = false }) =>
  (dispatch) => {
    const activeType = isPaginate
      ? types.GET_MORE_PRODUCTS
      : types.GET_PRODUCTS_BY_COLLECTION
    dispatch({ type: PENDING(activeType) })
    clearParams({ params })
    products
      .getCollectionProducts({ params })
      .then((res) =>
        dispatch({
          type: SUCCESS(activeType),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(activeType),
          payload: err.response.message,
        })
      )
  }

const getBaseInfo =
  ({ productId, collectionId }) =>
  (dispatch) => {
    dispatch(collectionActions.fetchCollectionById({ id: collectionId }))
    dispatch(fetchProduct({ id: productId }))
    dispatch(fetchProductAttributesMatrix({ id: productId }))
    dispatch(fetchAttributes())
  }

const changeBaseInfo =
  ({ data, product }) =>
  (dispatch) =>
    handleBaseInfo({
      data,
      item: product,
      requests: {
        uploadFiles: (files) =>
          dispatch(
            uploadProductFiles({
              data: files,
              id: product.uuid,
            })
          ),
        deleteFiles: (ids) =>
          dispatch(
            deleteProductFiles({
              data: ids,
              id: product.uuid,
            })
          ),
        addContent: (content) =>
          dispatch(
            addProductContent({
              productId: product.uuid,
              data: content,
            })
          ),
        updateContent: (content) =>
          dispatch(
            updateProductContent({
              productId: product.uuid,
              data: content,
              langId: data?.language_id,
            })
          ),
      },
    })

const deleteProductFiles =
  ({ data, id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_PRODUCT_FILES) })
    return products
      .deleteProductFiles({ data, id })
      .then(() =>
        dispatch({
          type: SUCCESS(types.DELETE_PRODUCT_FILES),
          payload: data,
        })
      )
      .catch((err) => {
        dispatch({
          type: FAILURE(types.DELETE_PRODUCT_FILES),
          payload: err.response.message,
        })
        return err
      })
  }

const createProduct =
  ({ data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.CREATE_PRODUCT) })
    return products
      .createProduct({ data: data.creation })
      .then((res) => {
        if (data.files.length) {
          dispatch(
            uploadProductFiles({
              data: data.files,
              id: res.data.data.uuid,
            })
          )
        }
        toast.success(
          `${i18n.t('product:product')} ${i18n.t('common:create_success')}`
        )
        dispatch({
          type: SUCCESS(types.CREATE_PRODUCT),
          payload: res.data,
        })
        return res.data
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.CREATE_PRODUCT),
          payload: err.response.message,
        })
      )
  }

const uploadProductFiles =
  ({ data, id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.UPLOAD_PRODUCT_FILES) })
    return products
      .uploadProductFiles({ data, id })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.UPLOAD_PRODUCT_FILES),
          payload: res.data,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.UPLOAD_PRODUCT_FILES),
          payload: err.response.message,
        })
        throw err
      })
  }

const fetchProduct =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_PRODUCT) })
    products
      .getProduct({ id })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_PRODUCT),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_PRODUCT),
          payload: err.response.message,
        })
      )
  }

const fetchAttributes = () => (dispatch) => {
  dispatch({ type: PENDING(types.GET_ATTRIBUTES) })
  products
    .getAttributes()
    .then((res) =>
      dispatch({
        type: SUCCESS(types.GET_ATTRIBUTES),
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: FAILURE(types.GET_ATTRIBUTES),
        payload: err.response.message,
      })
    )
}

const fetchAttributesOptions =
  ({ params = {} }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_ATTRIBUTES_OPTIONS) })
    products
      .getAttributesOptions({ params })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_ATTRIBUTES_OPTIONS),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_ATTRIBUTES_OPTIONS),
          payload: err.response.message,
        })
      )
  }

const fetchProductAttributesMatrix =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_PRODUCT_ATTRIBUTES_MATRIX) })
    products
      .getProductAttributesMatrix({ id })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_PRODUCT_ATTRIBUTES_MATRIX),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_PRODUCT_ATTRIBUTES_MATRIX),
          payload: err.response.message,
        })
      )
  }

const fetchActiveAttributesMatrix =
  ({ id, params }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_ACTIVE_ATTRIBUTES_MATRIX) })
    products
      .getProductAttributesMatrix({ id, params })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_ACTIVE_ATTRIBUTES_MATRIX),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_ACTIVE_ATTRIBUTES_MATRIX),
          payload: err.response.message,
        })
      )
  }

const fetchAttributesMatrix =
  ({ params }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_ATTRIBUTES_MATRIX) })
    products
      .getAttributesMatrix({ params })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_ATTRIBUTES_MATRIX),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_ATTRIBUTES_MATRIX),
          payload: err.response.message,
        })
      )
  }

const shareProduct =
  ({ params, id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.SHARE_PRODUCT) })
    products
      .shareProduct({ params, id })
      .then((res) => {
        toast.success(
          `${i18n.t('product:product')} ${i18n.t('common:shared_successfully')}`
        )
        dispatch({
          type: SUCCESS(types.SHARE_PRODUCT),
          payload: res.data,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.SHARE_PRODUCT),
          payload: err.response.message,
        })
      )
  }

const unshareProduct =
  ({ params, id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.UNSHARE_PRODUCT) })
    products
      .unshareProduct({ params, id })
      .then((res) => {
        toast.success(
          `${i18n.t('product:product')} ${i18n.t(
            'common:unshared_successfully'
          )}`
        )
        dispatch({
          type: SUCCESS(types.UNSHARE_PRODUCT),
          payload: { data: res.data, companyIds: params.company_ids },
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.UNSHARE_PRODUCT),
          payload: err.response.message,
        })
      )
  }

const fetchProductInstances =
  ({ id, params }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_PRODUCT_INSTANCES) })
    clearParams({ params })
    products
      .getProductInstance({ params, id })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_PRODUCT_INSTANCES),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_PRODUCT_INSTANCES),
          payload: err.response.message,
        })
      )
  }

const createProductInstance =
  ({ productId, variantId, data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.CREATE_PRODUCT_INSTANCE) })
    return products
      .createProductInstance({ productId, variantId, data })
      .then((res) => {
        toast.success(
          `${i18n.t('product:serialNumber')} ${i18n.t('common:create_success')}`
        )
        dispatch({
          type: SUCCESS(types.CREATE_PRODUCT_INSTANCE),
          payload: res.data,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.CREATE_PRODUCT_INSTANCE),
          payload: err.response.message,
        })

        throw err
      })
  }

const deleteProductInstance =
  ({ productId, variantId, instanceId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_PRODUCT_INSTANCE) })
    products
      .deleteProductInstance({ productId, variantId, instanceId })
      .then((res) => {
        toast.success(
          `${i18n.t('product:serialNumber')} ${i18n.t('common:delete_success')}`
        )
        dispatch({
          type: SUCCESS(types.DELETE_PRODUCT_INSTANCE),
          payload: { data: res.data, instanceId: instanceId },
        })
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.DELETE_PRODUCT_INSTANCE),
          payload: err.response.message,
        })
      })
  }

const editProductInstance =
  ({ productId, variantId, instanceId, data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.EDIT_PRODUCT_INSTANCE) })
    products
      .editProductInstance({ productId, variantId, instanceId, data })
      .then((res) => {
        toast.success(
          `${i18n.t('product:serialNumber')} ${i18n.t('common:edit_success')}`
        )
        dispatch({
          type: SUCCESS(types.EDIT_PRODUCT_INSTANCE),
          payload: { data: res.data, instanceId },
        })
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.EDIT_PRODUCT_INSTANCE),
          payload: err.response.message,
        })
      })
  }

const deleteProduct =
  ({ productId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_PRODUCT) })
    return products
      .deleteProduct({ productId })
      .then((res) => {
        toast.success(
          `${i18n.t('product:product')} ${i18n.t('common:delete_success')}`
        )
        dispatch({
          type: SUCCESS(types.DELETE_PRODUCT),
          payload: { data: res.data },
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.DELETE_PRODUCT),
          payload: err.response.message,
        })
      )
  }

const addProductContent =
  ({ productId, data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.ADD_PRODUCT_CONTENT) })
    products
      .addProductContent({ id: productId, data })
      .then((res) => {
        toast.success(i18n.t('common:content_added_successfully'))
        dispatch({
          type: SUCCESS(types.ADD_PRODUCT_CONTENT),
          payload: res.data,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.ADD_PRODUCT_CONTENT),
          payload: err.response.message,
        })
      )
  }

const updateProductContent =
  ({ productId, data, langId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.UPDATE_PRODUCT_CONTENT) })
    products
      .updateProductContent({ id: productId, data, langId })
      .then((res) => {
        toast.success(i18n.t('common:content_changed_successfully'))
        dispatch({
          type: SUCCESS(types.UPDATE_PRODUCT_CONTENT),
          payload: res.data,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.UPDATE_PRODUCT_CONTENT),
          payload: err.response.message,
        })
      )
  }

const getProductContent =
  ({ productId, params }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_PRODUCT_CONTENT) })
    products
      .getProductContent({ id: productId, params })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.GET_PRODUCT_CONTENT),
          payload: res.data,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_PRODUCT_CONTENT),
          payload: err.response.message,
        })
      )
  }

const deleteProductContent =
  ({ productId, langId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_PRODUCT_CONTENT) })
    return products
      .deleteProductContent({ id: productId, langId })
      .then(() => {
        toast.success(i18n.t('common:content_removed_successfully'))
        dispatch({
          type: SUCCESS(types.DELETE_PRODUCT_CONTENT),
          payload: langId,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.DELETE_PRODUCT_CONTENT),
          payload: err.response.message,
        })
      )
  }

const clearProducts = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_PRODUCTS) })

const clearProduct = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_PRODUCT) })

export default {
  fetchProductsByCollection,
  createProduct,
  fetchProduct,
  changeBaseInfo,
  getBaseInfo,
  fetchAttributes,
  fetchAttributesOptions,
  fetchAttributesMatrix,
  fetchProductAttributesMatrix,
  fetchActiveAttributesMatrix,
  shareProduct,
  unshareProduct,
  fetchProductInstances,
  createProductInstance,
  deleteProductInstance,
  editProductInstance,
  deleteProduct,
  uploadProductFiles,
  deleteProductFiles,
  addProductContent,
  updateProductContent,
  getProductContent,
  deleteProductContent,
  clearProducts,
  clearProduct,
}
