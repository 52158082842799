const getUser = (state) => state.user.user.item
const activeUserLoading = (state) => state.user.user.loading

const getUserPermissions = (state) => state.user.userPermissions.item

export default {
  getUser,
  activeUserLoading,
  getUserPermissions,
}
