import { requests } from './api'

const initialEndpoint = 'permissions'

const getPermissions = ({ params }) =>
  requests.get({ url: initialEndpoint }).then((res) => res.data)

const addUserPermissions = ({ userId, data }) =>
  requests
    .post({ url: `${initialEndpoint}/${userId}/add`, data })
    .then((res) => res.data)

const removeUserPermissions = ({ userId, data }) =>
  requests
    .delete({ url: `${initialEndpoint}/${userId}/remove`, params: data })
    .then((res) => res.data)

export default {
  getPermissions,
  addUserPermissions,
  removeUserPermissions,
}
