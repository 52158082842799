const LOGIN = 'LOGIN'
const LOGOUT = 'LOGOUT'
const GET_ACTIVE_USER = 'GET_ACTIVE_USER'
const UPDATE_ACTIVE_USER = 'UPDATE_ACTIVE_USER'
const REFRESH_TOKEN = 'REFRESH_TOKEN'
const RESET_PASSWORD = 'RESET_PASSWORD'
const SET_PASSWORD = 'SET_PASSWORD'

const GET_MY_TOOLS = 'GET_MY_TOOLS'
const GET_MY_PERMISSIONS = 'GET_MY_PERMISSIONS'

const GET_ACTIVE_USER_PERMISSIONS = 'GET_ACTIVE_USER_PERMISSIONS'

export default {
  LOGIN,
  LOGOUT,
  GET_ACTIVE_USER,
  UPDATE_ACTIVE_USER,
  REFRESH_TOKEN,
  RESET_PASSWORD,
  SET_PASSWORD,
  GET_ACTIVE_USER_PERMISSIONS,
  GET_MY_TOOLS,
  GET_MY_PERMISSIONS,
}
