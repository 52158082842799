import React from 'react'
import MoonLoader from 'react-spinners/MoonLoader'

import styles from './Loader.module.scss'

const Loader = () => {
  return (
    <div className={styles.loader} onClick={(e) => e.stopPropagation()}>
      <MoonLoader loading={true} size={100} color={'#000062'} />
    </div>
  )
}

export default Loader
