import moment from 'moment'
import { toast } from 'react-toastify'

import productionOrders from 'services/orders/productionOrders'
import collections from 'services/collections'
import variants from 'services/variants'
import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import { clearParams } from 'utils/helpers'
import i18n from 'translations'
import { productActions } from 'store/ducks/product'

const fetchProductionOrders =
  ({ params, isPaginate = false }) =>
  (dispatch) => {
    const activeType = isPaginate
      ? types.GET_MORE_PRODUCTION_ORDERS
      : types.GET_PRODUCTION_ORDERS
    dispatch({ type: PENDING(activeType) })
    clearParams({ params })
    productionOrders
      .getProductionOrders({ params })
      .then((res) => dispatch({ type: SUCCESS(activeType), payload: res }))
      .catch(() =>
        dispatch({
          type: FAILURE(activeType),
        })
      )
  }

const fetchProductionOrder =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_PRODUCTION_ORDER) })
    productionOrders
      .getProductionOrder({ id })
      .then((res) =>
        dispatch({ type: SUCCESS(types.GET_PRODUCTION_ORDER), payload: res })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_PRODUCTION_ORDER),
        })
      )
  }

const createProductionOrder =
  ({ data }) =>
  (dispatch) => {
    const newData = {
      ...data,
      creation_date: moment(data?.creation_date).format('YYYY-MM-DD HH:mm:ss'),
      due_date: moment(data?.due_date).format('YYYY-MM-DD HH:mm:ss'),
      emitter_id: data?.emitter?.value,
      receiver_id: data?.receiver?.value,
    }
    dispatch({ type: PENDING(types.CREATE_PRODUCTION_ORDER) })
    return productionOrders
      .createProductionOrder({ data: newData })
      .then((res) => {
        toast.success(
          `${i18n.t('quality_control:orders:order')} ${i18n.t(
            'common:create_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.CREATE_PRODUCTION_ORDER), payload: res })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.CREATE_PRODUCTION_ORDER),
        })
        throw err
      })
  }

const editProductionOrder =
  ({ data, id }) =>
  (dispatch) => {
    const newData = {
      ...data,
      creation_date: moment(data?.creation_date).format('YYYY-MM-DD HH:mm:ss'),
      due_date: moment(data?.due_date).format('YYYY-MM-DD HH:mm:ss'),
      emitter_id: data?.emitter?.value,
      receiver_id: data?.receiver?.value,
    }
    dispatch({ type: PENDING(types.EDIT_PRODUCTION_ORDER) })
    return productionOrders
      .editProductionOrder({ data: newData, id })
      .then((res) => {
        toast.success(
          `${i18n.t('quality_control:orders:order')} ${i18n.t(
            'common:edit_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.EDIT_PRODUCTION_ORDER), payload: res })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.EDIT_PRODUCTION_ORDER),
        })
      )
  }

const deleteProductionOrder =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_PRODUCTION_ORDER) })
    return productionOrders
      .deleteProductionOrder({ id })
      .then((res) => {
        toast.success(
          `${i18n.t('quality_control:orders:order')} ${i18n.t(
            'common:delete_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.DELETE_PRODUCTION_ORDER), payload: id })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.DELETE_PRODUCTION_ORDER),
        })
        throw err
      })
  }

const fetchProductionOrderProducts =
  ({ params, id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_PRODUCTION_ORDER_PRODUCTS) })
    clearParams({ params })
    productionOrders
      .getProductionOrderProducts({ params, id })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_PRODUCTION_ORDER_PRODUCTS),
          payload: res,
        })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_PRODUCTION_ORDER_PRODUCTS),
        })
      )
  }

const createProductionOrderProduct =
  ({ data, orderId, productId, variantId, serials }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.CREATE_PRODUCTION_ORDER_PRODUCT) })
    return productionOrders
      .createProductionOrderProduct({ data, id: orderId })
      .then((res) => {
        toast.success(
          `${i18n.t('product:product')} ${i18n.t('common:create_success')}`
        )
        dispatch({
          type: SUCCESS(types.CREATE_PRODUCTION_ORDER_PRODUCT),
          payload: res,
        })
        return dispatch(
          createProductionOrderSerial({
            productId,
            variantId,
            serials,
            orderId,
            productionProductId: res.data.uuid,
          })
        )
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.CREATE_PRODUCTION_ORDER_PRODUCT),
        })
        throw err
      })
  }

const editProductionOrderProduct =
  ({ data, productId, orderId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.EDIT_PRODUCTION_ORDER_PRODUCT) })
    return productionOrders
      .editProductionOrderProduct({ data, productId, orderId })
      .then((res) => {
        toast.success(
          `${i18n.t('product:product')} ${i18n.t('common:edit_success')}`
        )
        dispatch({
          type: SUCCESS(types.EDIT_PRODUCTION_ORDER_PRODUCT),
          payload: res,
        })

        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.EDIT_PRODUCTION_ORDER_PRODUCT),
        })
        throw err
      })
  }

const deleteProductionOrderProduct =
  ({ orderId, productId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_PRODUCTION_ORDER_PRODUCT) })
    return productionOrders
      .deleteProductionOrderProduct({ orderId, productId })
      .then((res) => {
        toast.success(
          `${i18n.t('product:product')} ${i18n.t('common:delete_success')}`
        )
        dispatch({
          type: SUCCESS(types.DELETE_PRODUCTION_ORDER_PRODUCT),
          payload: productId,
        })

        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.DELETE_PRODUCTION_ORDER_PRODUCT),
        })
        throw err
      })
  }

const fetchProductionOrderCollections =
  ({ params, isPagination = false }) =>
  (dispatch) => {
    const activeType = isPagination
      ? types.GET_MORE_PRODUCTION_ORDER_COLLECTIONS
      : types.GET_PRODUCTION_ORDER_COLLECTIONS
    dispatch({ type: PENDING(activeType) })
    clearParams({ params })
    collections
      .getCollections({ params })
      .then((res) =>
        dispatch({
          type: SUCCESS(activeType),
          payload: res.data,
        })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(activeType),
        })
      )
  }

const fetchProductionOrderVariants =
  ({ params = null }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_PRODUCTION_ORDER_VARIANTS) })
    clearParams({ params })
    variants
      .getDefaultVariants({ params })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_PRODUCTION_ORDER_VARIANTS),
          payload: res,
        })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_PRODUCTION_ORDER_VARIANTS),
        })
      )
  }

const fetchProductionOrderSerials =
  ({ params = null, productId, orderId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_PRODUCTION_ORDER_SERIALS) })
    clearParams({ params })
    productionOrders
      .getProductionOrderSerials({ params, productId, orderId })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_PRODUCTION_ORDER_SERIALS),
          payload: { data: res },
        })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_PRODUCTION_ORDER_SERIALS),
        })
      )
  }

const createProductionOrderSerial =
  ({ productId, variantId, serials, orderId, productionProductId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.CREATE_PRODUCTION_ORDER_SERIAL) })
    return dispatch(
      productActions.createProductInstance({
        productId,
        variantId,
        data: { serial_numbers: serials },
      })
    )
      .then((productRes) => {
        const ids = productRes?.data?.data?.map((el) => el.uuid)
        return productionOrders
          .createProductionOrderSerial({
            data: {
              product_instance_ids: ids,
            },
            productId: productionProductId,
            orderId,
          })
          .then((res) => {
            dispatch({
              type: SUCCESS(types.CREATE_PRODUCTION_ORDER_SERIAL),
              payload: { data: res.data, productId: productionProductId },
            })
          })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.CREATE_PRODUCTION_ORDER_SERIAL),
        })
      )
  }

const deleteProductionOrderSerial =
  ({ params, productId, orderId, index }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_PRODUCTION_ORDER_SERIAL) })
    return productionOrders
      .deleteProductionOrderSerial({ params, productId, orderId })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.DELETE_PRODUCTION_ORDER_SERIAL),
          payload: { index: index, productId },
        })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.DELETE_PRODUCTION_ORDER_SERIAL),
        })
      )
  }

const sendProductionOrder =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.SEND_PRODUCTION_ORDER) })
    return productionOrders
      .sendProductionOrder({ id })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.SEND_PRODUCTION_ORDER),
          payload: res.data,
        })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.SEND_PRODUCTION_ORDER),
        })
      )
  }

const fetchProductionOrderImports =
  ({ params }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_PRODUCTION_ORDER_IMPORTS) })
    clearParams({ params })
    productionOrders
      .getProductionOrderImports({ params })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_PRODUCTION_ORDER_IMPORTS),
          payload: res,
        })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_PRODUCTION_ORDER_IMPORTS),
        })
      )
  }

const importProductionOrder =
  ({ data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.IMPORT_PRODUCTION_ORDER) })
    productionOrders
      .importProductionOrder({ data })
      .then((res) => {
        toast.success(
          `${i18n.t('common:file')} ${i18n.t('common:upload_success')}`
        )
        dispatch({
          type: SUCCESS(types.IMPORT_PRODUCTION_ORDER),
          payload: res.data,
        })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.IMPORT_PRODUCTION_ORDER),
        })
      )
  }

const clearProductionOrderSerials = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_PRODUCTION_ORDER_SERIALS) })

const clearProductionOrder = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_PRODUCTION_ORDER) })

const clearProductionOrders = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_PRODUCTION_ORDERS) })

const clearProductionOrderImports = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_PRODUCTION_ORDER_IMPORTS) })

export default {
  fetchProductionOrders,
  fetchProductionOrder,
  createProductionOrder,
  editProductionOrder,
  deleteProductionOrder,
  fetchProductionOrderProducts,
  createProductionOrderProduct,
  editProductionOrderProduct,
  deleteProductionOrderProduct,
  fetchProductionOrderCollections,
  fetchProductionOrderVariants,
  fetchProductionOrderSerials,
  createProductionOrderSerial,
  deleteProductionOrderSerial,
  clearProductionOrderSerials,
  sendProductionOrder,
  fetchProductionOrderImports,
  importProductionOrder,
  clearProductionOrder,
  clearProductionOrders,
  clearProductionOrderImports,
}
