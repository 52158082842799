const GET_DEFECT_CATEGORIES = 'GET_DEFECT_CATEGORIES'
const GET_MORE_DEFECT_CATEGORIES = 'GET_MORE_DEFECT_CATEGORIES'
const GET_DEFECT_CATEGORY = 'GET_DEFECT_CATEGORY'
const CREATE_DEFECT_CATEGORY = 'CREATE_DEFECT_CATEGORY'
const EDIT_DEFECT_CATEGORY = 'EDIT_DEFECT_CATEGORY'
const DELETE_DEFECT_CATEGORY = 'DELETE_DEFECT_CATEGORY'

const CLEAR_DEFECT_CATEGORIES = 'CLEAR_DEFECT_CATEGORIES'

export default {
  GET_DEFECT_CATEGORIES,
  GET_MORE_DEFECT_CATEGORIES,
  GET_DEFECT_CATEGORY,
  CREATE_DEFECT_CATEGORY,
  EDIT_DEFECT_CATEGORY,
  DELETE_DEFECT_CATEGORY,
  CLEAR_DEFECT_CATEGORIES,
}
