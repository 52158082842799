const GET_CERTIFICATES = 'GET_CERTIFICATES'
const CREATE_CERTIFICATE = 'CREATE_CERTIFICATE'
const EDIT_CERTIFICATE = 'EDIT_CERTIFICATE'
const DELETE_CERTIFICATE = 'DELETE_CERTIFICATE'

const UPLOAD_CERTIFICATE_LOGO = 'UPLOAD_CERTIFICATE_LOGO'

const CLEAR_CERTIFICATES = 'CLEAR_CERTIFICATES'

export default {
  GET_CERTIFICATES,
  CREATE_CERTIFICATE,
  EDIT_CERTIFICATE,
  DELETE_CERTIFICATE,
  UPLOAD_CERTIFICATE_LOGO,
  CLEAR_CERTIFICATES
}
