import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const initialState = {
  reports: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
  },
  activeReport: {
    item: null,
    loading: true,
    error: '',
  },
  reportRows: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
  },
  rowsInfo: {},
  defectCategories: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
    moreLoading: false,
  },
  defects: {
    items: [],
    loading: false,
    error: '',
    totalPages: 0,
    moreLoading: false,
  },
  reportOrders: {
    items: [],
    loading: false,
    error: '',
    totalPages: 0,
  },
}

const reportReducer = (state = initialState, { payload, type }) => {
  let index
  let newItems
  switch (type) {
    case PENDING(types.GET_REPORTS):
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
        },
      }
    case SUCCESS(types.GET_REPORTS):
      return {
        ...state,
        reports: {
          ...state.reports,
          items: payload.data,
          totalPages: payload.meta.last_page,
          loading: false,
        },
      }
    case FAILURE(types.GET_REPORTS):
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_REPORT):
      return {
        ...state,
        activeReport: {
          ...state.activeReport,
          loading: true,
        },
      }
    case SUCCESS(types.GET_REPORT):
      return {
        ...state,
        activeReport: {
          ...state.activeReport,
          item: payload.data,
          loading: false,
        },
      }
    case FAILURE(types.GET_REPORT):
      return {
        ...state,
        activeReport: {
          ...state.activeReport,
          loading: false,
          error: payload,
        },
      }
    case SUCCESS(types.EDIT_REPORT):
      return {
        ...state,
        activeReport: {
          ...state.activeReport,
          item: payload.data,
        },
      }
    case SUCCESS(types.DELETE_REPORT):
      index = state.reports.items.findIndex((el) => el.uuid === payload)
      return {
        ...state,
        reports: {
          ...state.reports,
          items: [
            ...state.reports.items.slice(0, index),
            ...state.reports.items.slice(index + 1),
          ],
        },
      }
    case PENDING(types.GET_REPORT_ROWS):
      return {
        ...state,
        reportRows: {
          ...state.reportRows,
          loading: true,
        },
      }
    case SUCCESS(types.GET_REPORT_ROWS):
      return {
        ...state,
        reportRows: {
          ...state.reportRows,
          items: payload.data,
          totalPages: payload.meta.last_page,
          loading: false,
        },
      }
    case FAILURE(types.GET_REPORT_ROWS):
      return {
        ...state,
        reportRows: {
          ...state.reportRows,
          loading: false,
          error: payload,
        },
      }
    case SUCCESS(types.DELETE_REPORT_ROW):
      index = state.reportRows.items.findIndex((el) => el?.uuid === payload)
      return {
        ...state,
        reportRows: {
          ...state.reportRows,
          items: [
            ...state.reportRows.items.slice(0, index),
            ...state.reportRows.items.slice(index + 1),
          ],
        },
      }
    case SUCCESS(types.EDIT_REPORT_ROW_STATUS):
      newItems = state.reportRows.items
      index = newItems.findIndex((el) => el.uuid === payload.data.uuid)
      newItems[index] = payload.data
      return {
        ...state,
        reportRows: {
          ...state.reportRows,
          items: [...newItems],
        },
        activeReport: {
          ...state.activeReport,
          item: {
            ...state.activeReport.item,
            statistics: {
              ...state.activeReport.item.statistics,
              statuses: {
                ...state.activeReport.item.statistics.statuses,
                [payload.oldStatus]:
                  state.activeReport.item.statistics.statuses[
                    payload.oldStatus
                  ] - 1,
                [payload.data.status]:
                  state.activeReport.item.statistics.statuses[
                    payload.data.status
                  ] + 1,
              },
            },
          },
        },
      }
    case PENDING(types.GET_REPORT_ROW_PROBLEMS):
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            problems: [],
            isProblemsLoading: true,
          },
        },
      }
    case SUCCESS(types.GET_REPORT_ROW_PROBLEMS):
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            problems: payload.data,
            isProblemsLoading: false,
          },
        },
      }
    case FAILURE(types.GET_REPORT_ROW_PROBLEMS):
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            problems: [],
            isProblemsLoading: false,
          },
        },
      }
    case PENDING(types.GET_REPORT_ROW_HISTORY):
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            history: {
              items: state.rowsInfo[payload.rowId]?.history?.items || [],
              totalPages:
                state.rowsInfo[payload.rowId]?.history?.totalPages || 0,
              isLoading: true,
            },
          },
        },
      }
    case SUCCESS(types.GET_REPORT_ROW_HISTORY):
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            history: {
              isLoading: false,
              items: payload.data.data,
              totalPages: payload.data.meta.last_page,
            },
          },
        },
      }
    case FAILURE(types.GET_REPORT_ROW_HISTORY):
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            fields: {
              isLoading: true,
              items: [],
            },
          },
        },
      }
    case PENDING(types.GET_REPORT_ROW_FIELDS):
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            fields: {
              items: state.rowsInfo[payload.rowId]?.fields?.items || [],
              isLoading: true,
            },
          },
        },
      }
    case SUCCESS(types.GET_REPORT_ROW_FIELDS):
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            fields: {
              isLoading: false,
              items: payload.data,
            },
          },
        },
      }
    case FAILURE(types.GET_REPORT_ROW_FIELDS):
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            fields: {
              isLoading: true,
              items: [],
            },
          },
        },
      }
    case SUCCESS(types.CREATE_REPORT_ROW_PROBLEM):
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            problems: [...state.rowsInfo[payload.rowId].problems, payload.data],
          },
        },
      }
    case SUCCESS(types.DELETE_REPORT_ROW_PROBLEM):
      index = state.rowsInfo[payload.rowId].problems.findIndex(
        (el) => el.uuid === payload.problemId
      )
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            problems: [
              ...state.rowsInfo[payload.rowId].problems.slice(0, index),
              ...state.rowsInfo[payload.rowId].problems.slice(index + 1),
            ],
          },
        },
      }
    case SUCCESS(types.EDIT_REPORT_ROW_PROBLEM):
      newItems = state.rowsInfo[payload.rowId].problems
      index = newItems.findIndex((el) => el.uuid === payload.data.uuid)
      newItems[index] = payload.data
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            problems: [...newItems],
          },
        },
      }
    case SUCCESS(types.UPLOAD_REPORT_ROW_PROBLEM_FILE):
      newItems = state.rowsInfo[payload.rowId].problems
      index = newItems.findIndex((el) => el.uuid === payload.data.uuid)
      newItems[index] = payload.data
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            problems: [...newItems],
          },
        },
      }
    case SUCCESS(types.DELETE_REPORT_ROW_PROBLEM_FILE):
      newItems = state.rowsInfo[payload.rowId].problems
      index = newItems.findIndex((el) => el.uuid === payload.problemId)
      const fileIndex = newItems[index].files.findIndex(
        (el) => el.uuid === payload.fileId
      )
      newItems[index] = {
        ...newItems[index],
        files: [
          ...newItems[index].files.slice(0, fileIndex),
          ...newItems[index].files.slice(fileIndex + 1),
        ],
      }
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            problems: [...newItems],
          },
        },
      }
    case PENDING(types.GET_REPORT_ROW_CERTIFICATES):
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            certificates: [],
            isCertificatesLoading: true,
          },
        },
      }
    case SUCCESS(types.GET_REPORT_ROW_CERTIFICATES):
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            certificates: payload.data,
            isCertificatesLoading: false,
          },
        },
      }
    case FAILURE(types.GET_REPORT_ROW_CERTIFICATES):
      return {
        ...state,
        rowsInfo: {
          ...state.rowsInfo,
          [payload.rowId]: {
            ...state.rowsInfo[payload.rowId],
            certificates: [],
            isCertificatesLoading: false,
          },
        },
      }
    case PENDING(types.GET_REPORT_DEFECT_CATEGORIES):
      return {
        ...state,
        defectCategories: {
          ...state.defectCategories,
          loading: true,
        },
      }
    case SUCCESS(types.GET_REPORT_DEFECT_CATEGORIES):
      return {
        ...state,
        defectCategories: {
          ...state.defectCategories,
          items: payload.data,
          totalPages: payload.meta.last_page,
          loading: false,
        },
      }
    case FAILURE(types.GET_REPORT_DEFECT_CATEGORIES):
      return {
        ...state,
        defectCategories: {
          ...state.defectCategories,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_MORE_REPORT_DEFECT_CATEGORIES):
      return {
        ...state,
        defectCategories: {
          ...state.defectCategories,
          moreLoading: true,
        },
      }
    case SUCCESS(types.GET_MORE_REPORT_DEFECT_CATEGORIES):
      return {
        ...state,
        defectCategories: {
          ...state.defectCategories,
          items: [...state.defectCategories.items, ...payload.data],
          moreLoading: false,
        },
      }
    case FAILURE(types.GET_MORE_REPORT_DEFECT_CATEGORIES):
      return {
        ...state,
        defectCategories: {
          ...state.defectCategories,
          moreLoading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_REPORT_DEFECTS):
      return {
        ...state,
        defects: {
          ...state.defects,
          loading: true,
        },
      }
    case SUCCESS(types.GET_REPORT_DEFECTS):
      return {
        ...state,
        defects: {
          ...state.defects,
          items: payload.data,
          totalPages: payload.meta.last_page,
          loading: false,
        },
      }
    case FAILURE(types.GET_REPORT_DEFECTS):
      return {
        ...state,
        defects: {
          ...state.defects,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_MORE_REPORT_DEFECTS):
      return {
        ...state,
        defects: {
          ...state.defects,
          moreLoading: true,
        },
      }
    case SUCCESS(types.GET_MORE_REPORT_DEFECTS):
      return {
        ...state,
        defects: {
          ...state.defects,
          items: [...state.defects.items, ...payload.data],
          moreLoading: false,
        },
      }
    case FAILURE(types.GET_MORE_REPORT_DEFECTS):
      return {
        ...state,
        defects: {
          ...state.defects,
          moreLoading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_REPORT_ORDERS):
      return {
        ...state,
        reportOrders: {
          ...state.reportOrders,
          loading: true,
        },
      }
    case SUCCESS(types.GET_REPORT_ORDERS):
      return {
        ...state,
        reportOrders: {
          ...state.reportOrders,
          items: payload.data,
          totalPages: payload.meta.last_page,
          loading: false,
        },
      }
    case FAILURE(types.GET_REPORT_ORDERS):
      return {
        ...state,
        reportOrders: {
          ...state.reportOrders,
          loading: payload,
        },
      }
    case SUCCESS(types.SEND_REPORT):
      return {
        ...state,
        activeReport: {
          ...state.activeReport,
          item: {
            ...state.activeReport.item,
            status: payload.data,
          },
        },
      }
    case SUCCESS(types.CLEAR_REPORTS):
      return {
        ...state,
        reports: {
          items: [],
          loading: true,
          error: '',
          totalPages: 0,
        },
      }
    case SUCCESS(types.CLEAR_REPORT):
      return {
        ...state,
        activeReport: {
          item: null,
          loading: true,
          error: '',
        },
        reportRows: {
          items: [],
          loading: true,
          error: '',
          totalPages: 0,
        },
        rowsInfo: {},
        defects: {
          items: [],
          loading: false,
          error: '',
          totalPages: 0,
          moreLoading: false,
        },
        reportOrders: {
          items: [],
          loading: false,
          error: '',
          totalPages: 0,
        },
      }
    default:
      return state
  }
}

export default reportReducer
