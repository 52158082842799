import i18n from 'translations'

export const DEFAULT_LANGUAGE = 'EN'

export const REQUEST_STATUS = {
  pending: 'pending',
  approved: 'approved',
}

export const THEMES = {
  dark: 'dark',
  light: 'light',
}

export const ORDER = {
  asc: 'asc',
  desc: 'desc',
}

export const PENDING = (action) => `${action}_PENDING`
export const SUCCESS = (action) => `${action}_SUCCESS`
export const FAILURE = (action) => `${action}_FAILURE`

export const MIN_SEARCH_LENGTH = 3
export const SKELETON_CARDS_NUMBER = 15
export const PRODUCTS_LIMIT = 24
export const MAX_FILE_SIZE = 5
export const VARIANT_LIMIT = 6
export const SERIAL_NUMBER_LIMIT = 5
export const COMPANIES_LIMIT = 12
export const USERS_LIMIT = 9

export const COMPANY_TYPES = {
  brand: 'brand',
  manufacturer: 'manufacturer',
  service_provider: 'service provider',
  retailer: 'retailer',
}

export const CERTIFICATE_STATUSES = {
  verified: 'verified',
  unverified: 'unverified',
}

export const GENDERS = {
  unspecified: 'Unspecified',
  male: 'male',
  female: 'female',
}

export const QUALITY_STATUSES = {
  open: 'open',
  sent: 'sent',
}

export const LIST_VIEWS = {
  grid: 'grid',
  list: 'list',
}

export const REPORT_STATUSES_NAMES = {
  derogated: 'derogated',
  fail: 'fail',
  pass: 'pass',
  repair: 'repair',
  unassigned: 'unassigned',
}

export const REPORT_STATUSES = [
  {
    name: REPORT_STATUSES_NAMES.derogated,
    color: 'var(--accentColor)',
    label: i18n.t('common:statuses:derogated'),
  },
  {
    name: REPORT_STATUSES_NAMES.fail,
    color: 'var(--systemRed)',
    label: i18n.t('common:statuses:fail'),
  },
  {
    name: REPORT_STATUSES_NAMES.pass,
    color: 'var(--primaryBright)',
    label: i18n.t('common:statuses:pass'),
  },
  {
    name: REPORT_STATUSES_NAMES.repair,
    color: 'var(--systemPurple)',
    label: i18n.t('common:statuses:repaired'),
  },
  {
    name: REPORT_STATUSES_NAMES.unassigned,
    color: 'var(--greyDivider)',
    label: i18n.t('common:statuses:unassigned'),
  },
]

export const DATE_FORMAT = 'YYYY-MM-DD'

export const IMAGE_EXTENSTIONS = ['jpeg', 'png', 'jpg']
export const VIDEO_EXTENSTIONS = ['mp4', 'video/mp4']

export const TABLE_CHECK_TYPE = {
  include: 'include',
  exclude: 'exclude',
}

export const FIELD_TYPES = {
  field: {
    label: i18n.t('common:fields:field'),
    value: 'text',
  },
  textArea: {
    label: i18n.t('common:fields:textarea'),
    value: 'textarea',
  },
  dropdown: {
    label: i18n.t('common:fields:dropdown'),
    value: 'select',
  },
  checkboxList: {
    label: i18n.t('common:fields:checkboxList'),
    value: 'checkbox-list',
  },
  datePicker: {
    label: i18n.t('common:fields:datePicker'),
    value: 'date',
  },
}
