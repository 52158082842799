import types from './actionTypes'
import { SUCCESS, PENDING, FAILURE } from 'constants/configs'
import { clearParams } from 'utils/helpers'

import common from 'services/common'

const setBreadcrumb =
  ({ data }) =>
  (dispatch) =>
    dispatch({ type: SUCCESS(types.SET_BREADCRUMB), payload: data })

const clearBreadcrumb = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_BREADCRUMB) })

const setLanguage =
  ({ lang }) =>
  (dispatch) =>
    dispatch({ type: SUCCESS(types.SET_LANGUAGE), payload: lang })

const setTheme = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.SET_THEME) })

const setActionLoading = (value) => (dispatch) =>
  dispatch({ type: SUCCESS(types.SET_ACTION_LOADING), payload: value })

const fetchGlobalSearch =
  ({ params }) =>
  (dispatch) => {
    clearParams({ params })
    dispatch({ type: PENDING(types.FETCH_GLOBAL_SEARCH) })
    common
      .getGlobalSearch({ params })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.FETCH_GLOBAL_SEARCH),
          payload: res,
        })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.FETCH_GLOBAL_SEARCH),
        })
      )
  }

export default {
  setBreadcrumb,
  clearBreadcrumb,
  setLanguage,
  setTheme,
  setActionLoading,
  fetchGlobalSearch,
}
