export default {
  emailReq: 'Email is required',
  passReq: 'Password is required',
  nameReq: 'Name is requeired',
  lastNameReq: 'Last name is requeired',
  typeReq: 'Type is requeired',
  idReq: 'Id is requeired',
  addressReq: 'Address is requeired',
  zipReq: 'Zip Code is requeired',
  cityReq: 'City is requeired',
  countryReq: 'Country is requeired',
  vatReq: 'Vat Number is requeired',
  registrationNumberReq: 'Registration Number is requeired',
  phoneReq: 'Phone Number is requeired',
  ownerReq: 'Owner is requeired',
  refReq: 'Reference is requeired',
  incFormat: 'Incorrect format',
  searchMin: 'Search must contain at least 3 characters',
  maxFiles: 'Maximum number of files is',
  fileToBig: 'Size of the file cannot be bigger than',
  browserSupport: 'Sorry, your browser does not support embedded videos.',
  numbersReq: 'You should have at least one number provided',
  server_error: 'Server error',
  item_not_found: 'Item not found',
  valid_email: 'Email must be valid',
  valid_phone: 'Phone number must be valid',
  isoReq: 'ISO Code is requeired',
  symbolReq: 'Symbol is requeired',
  flagReq: 'Flag is requeired',
  authority_nameReq: 'Authority Name is requeired',
  logoReq: 'Logo is requeired',
  numberReq: 'Number is requeired',
  clientReq: 'Client is requeired',
  creation_dateReq: 'Creation date is requeired',
  due_dateReq: 'Due date is requeired',
  receiverReq: 'Receiver is requeired',
  creation_due_min: 'Due date cannot be before creation date',
  quantityReq: 'Quantity is requeired',
  number: 'Must be a number',
  greater: 'Must be greater or equal than',
  labelReq: 'Label is requeired',
  pass_format:
    'Password must have at least one number, 8 symbols and one special character',
  passMatch: 'Confirmation does not match',
  confirmReq: 'Confirmation is requeired',
}
