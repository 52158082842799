import { requests } from './api'

const initialEndpoint = 'permission-groups'

const getTools = () =>
  requests.get({ url: `${initialEndpoint}/tools` }).then((res) => res.data)

const getToolsMatrix = ({ tool }) =>
  requests
    .get({ url: `${initialEndpoint}/tools/${tool}/matrix` })
    .then((res) => res.data)

export default {
  getTools,
  getToolsMatrix,
}
