import permissionsGroups from 'services/permissionsGroups'
import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const fetchPermissionGroupsTools = () => (dispatch) => {
  dispatch({ type: PENDING(types.GET_PERMISSIONS_GROUPS_TOOLS) })
  permissionsGroups
    .getTools()
    .then((res) =>
      dispatch({
        type: SUCCESS(types.GET_PERMISSIONS_GROUPS_TOOLS),
        payload: res,
      })
    )
    .catch(() =>
      dispatch({
        type: FAILURE(types.GET_PERMISSIONS_GROUPS_TOOLS),
      })
    )
}

const fetchPermissionGroupsToolsMatrix =
  ({ tool }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_PERMISSIONS_GROUPS_TOOLS_MATRIX) })
    permissionsGroups
      .getToolsMatrix({ tool })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_PERMISSIONS_GROUPS_TOOLS_MATRIX),
          payload: { data: res.data, activeTool: tool },
        })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_PERMISSIONS_GROUPS_TOOLS_MATRIX),
        })
      )
  }

const clearPermissionGroups = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_PERMISSIONS_GROUPS) })

export default {
  fetchPermissionGroupsTools,
  fetchPermissionGroupsToolsMatrix,
  clearPermissionGroups,
}
