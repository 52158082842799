import { toast } from 'react-toastify'

import templates from 'services/templates'
import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import { clearParams } from 'utils/helpers'
import i18n from 'translations'

const fetchTemplates =
  ({ params, isPaginate }) =>
  (dispatch) => {
    const activeType = isPaginate
      ? types.GET_MORE_TEMPLATES
      : types.GET_TEMPLATES
    dispatch({ type: PENDING(activeType) })
    clearParams({ params })
    templates
      .getTemplates({ params })
      .then((res) => dispatch({ type: SUCCESS(activeType), payload: res }))
      .catch(() =>
        dispatch({
          type: FAILURE(activeType),
        })
      )
  }

const fetchTemplate =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_TEMPLATE) })
    templates
      .getTemplate({ id })
      .then((res) =>
        dispatch({ type: SUCCESS(types.GET_TEMPLATE), payload: res.data })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_TEMPLATE),
        })
      )
  }

const createTemplate =
  ({ data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.CREATE_TEMPLATE) })
    const newData = {
      name: data?.name,
      company_id: data?.company?.value,
    }
    return templates
      .createTemplate({ data: newData })
      .then((res) => {
        toast.success(
          `${i18n.t('quality_control:templates:template')} ${i18n.t(
            'common:create_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.CREATE_TEMPLATE), payload: res.data })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.CREATE_TEMPLATE),
        })

        throw err
      })
  }

const deleteTemplate =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_TEMPLATE) })
    return templates
      .deleteTemplate({ id })
      .then((res) => {
        toast.success(
          `${i18n.t('quality_control:templates:template')} ${i18n.t(
            'common:delete_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.DELETE_TEMPLATE), payload: res.data })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.DELETE_TEMPLATE),
        })

        throw err
      })
  }

const editTemplate =
  ({ data, id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.EDIT_TEMPLATE) })
    const newData = {
      name: data?.name,
      company_id: data?.company?.value,
    }
    return templates
      .editTemplate({ data: newData, id })
      .then((res) => {
        toast.success(
          `${i18n.t('quality_control:templates:template')} ${i18n.t(
            'common:edit_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.EDIT_TEMPLATE), payload: res.data })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.EDIT_TEMPLATE),
        })

        throw err
      })
  }

const fetchTemplateFields =
  ({ templateId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_TEMPLATE_FIELDS) })
    templates
      .getTemplateFields({ templateId })
      .then((res) =>
        dispatch({ type: SUCCESS(types.GET_TEMPLATE_FIELDS), payload: res })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_TEMPLATE_FIELDS),
        })
      )
  }

const addTemplateField =
  ({ data, templateId, fieldId, payload }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.ADD_FIELD_TO_TEMPLATE) })
    return templates
      .createTemplateField({ data, templateId, fieldId })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.ADD_FIELD_TO_TEMPLATE),
          payload: { ...payload, ...data },
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.ADD_FIELD_TO_TEMPLATE),
        })

        throw err
      })
  }

const removeTemplateField =
  ({ templateId, fieldId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.REMOVE_FIELD_FROM_TEMPLATE) })
    return templates
      .deleteTemplateField({ templateId, fieldId })
      .then((res) => {
        toast.success(
          `${i18n.t('quality_control:templates:field')} ${i18n.t(
            'common:delete_success'
          )}`
        )
        dispatch({
          type: SUCCESS(types.REMOVE_FIELD_FROM_TEMPLATE),
          payload: fieldId,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.REMOVE_FIELD_FROM_TEMPLATE),
        })

        throw err
      })
  }

const changeFieldPosition =
  ({ templateId, fieldId, position, oldPosition }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.CHANGE_FIELD_POSITION) })
    templates
      .changeFieldPosition({ templateId, fieldId, position })
      .then(() =>
        dispatch({
          type: SUCCESS(types.CHANGE_FIELD_POSITION),
          payload: { position, oldPosition },
        })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.CHANGE_FIELD_POSITION),
        })
      )
  }

const editTemplateField =
  ({ templateId, fieldId, data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.EDIT_TEMPLATE_FIELD) })
    templates
      .editTemplateField({ templateId, fieldId, data })
      .then(() =>
        dispatch({
          type: SUCCESS(types.EDIT_TEMPLATE_FIELD),
          payload: { fieldId, data },
        })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.EDIT_TEMPLATE_FIELD),
        })
      )
  }

const clearTemplate = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_TEMPLATE) })

const clearTemplates = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_TEMPLATES) })

export default {
  fetchTemplates,
  fetchTemplate,
  createTemplate,
  deleteTemplate,
  editTemplate,
  fetchTemplateFields,
  addTemplateField,
  removeTemplateField,
  changeFieldPosition,
  editTemplateField,
  clearTemplate,
  clearTemplates,
}
