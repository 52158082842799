import { requests } from '../api'

const initialEndpoint = 'regions'

const getRegions = ({ params }) =>
  requests.get({ url: initialEndpoint, params }).then((res) => res.data)

const createRegion = ({ data }) =>
  requests.post({ url: initialEndpoint, data }).then((res) => res.data)

const editRegion = ({ data, id }) =>
  requests
    .put({ url: `${initialEndpoint}/${id}`, data })
    .then((res) => res.data)

const deleteRegion = ({ id }) =>
  requests.delete({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)

export default {
  getRegions,
  createRegion,
  editRegion,
  deleteRegion,
}
