import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const initialState = {
  user: {
    item: null,
    loading: false,
    error: '',
  },
  userPermissions: {
    item: null,
    loading: false,
    error: '',
  },
}

const userReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case PENDING(types.GET_USER):
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
        },
      }
    case SUCCESS(types.GET_USER):
      return {
        ...state,
        user: {
          ...state.user,
          item: payload,
          error: '',
          loading: false,
        },
      }
    case FAILURE(types.GET_USER):
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: payload.message,
        },
      }
    case SUCCESS(types.UPDATE_USER):
      return {
        ...state,
        user: {
          ...state.user,
          item: payload,
        },
      }
    case SUCCESS(types.UPLOAD_USER_FILES):
      return {
        ...state,
        user: {
          ...state.user,
          item: payload,
        },
      }
    case PENDING(types.GET_USER_PERMISSIONS):
      return {
        ...state,
        userPermissions: {
          ...state.userPermissions,
          loading: true,
        },
      }
    case SUCCESS(types.GET_USER_PERMISSIONS):
      if (state.userPermissions.item) {
        return {
          ...state,
          userPermissions: {
            ...state.userPermissions,
            item: { ...state.userPermissions.item, ...payload.data },
            error: '',
            loading: false,
          },
        }
      } else {
        return {
          ...state,
          userPermissions: {
            ...state.userPermissions,
            item: payload.data,
            error: '',
            loading: false,
          },
        }
      }
    case FAILURE(types.GET_USER_PERMISSIONS):
      return {
        ...state,
        userPermissions: {
          ...state.userPermissions,
          loading: false,
          error: payload.message,
        },
      }
    case SUCCESS(types.CLEAR_USER):
      return {
        ...state,
        user: {
          item: null,
          loading: false,
          error: '',
        },
        userPermissions: {
          item: null,
          loading: false,
          error: '',
        },
      }
    default:
      return state
  }
}

export default userReducer
