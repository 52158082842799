import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Routes from 'utils/Routes'
import { getTokenFromStorage } from 'utils/serializer'

import { authActions } from 'store/ducks/auth'
import { languageActions } from 'store/ducks/globalAttributes/language'
import { commonSelectors } from 'store/ducks/common'

import NotificationPopup from 'components/NotificationPopup'

const App = () => {
  const dispatch = useDispatch()
  const token = useMemo(() => getTokenFromStorage(), [])
  const activeTheme = useSelector(commonSelectors.getActiveTheme)

  useEffect(() => {
    if (token) {
      dispatch(authActions.getActiveUser())
      dispatch(languageActions.fetchLanguages({}))
      dispatch(authActions.fetchMyTools())
    }
  }, [dispatch, token])

  useEffect(() => {
    document.body.classList.add(activeTheme)

    return () => {
      document.body.classList.remove(activeTheme)
    }
  }, [activeTheme])

  return (
    <>
      <Routes />
      <NotificationPopup />
    </>
  )
}

export default App
