const getSingleCollection = (state) => state.collection.activeCollection.item
const getSingleCollectionLoading = (state) =>
  state.collection.activeCollection.loading
const getSingleCollectionError = (state) =>
  state.collection.activeCollection.error
const getSingleCollectionProducts = (state) =>
  state.collection.activeCollection.products_count

const getCollections = (state) => state.collection.collections.items
const getCollectionsLoading = (state) => state.collection.collections.loading
const getCollectionsError = (state) => state.collection.collections.error
const getTotalCollectionsPages = (state) =>
  state.collection.collections.totalPages
const getCollectionsMoreLoading = (state) =>
  state.collection.collections.moreLoading

const getSharedCollections = (state) => state.collection.sharedCollections.items
const getSharedCollectionsLoading = (state) =>
  state.collection.sharedCollections.loading
const getSharedCollectionsError = (state) =>
  state.collection.sharedCollections.error
const getTotalSharedCollections = (state) =>
  state.collection.sharedCollections.totalItems
const getTotalSharedCollectionsPages = (state) =>
  state.collection.sharedCollections.totalPages

const getCollectionContentLoading = (state) =>
  state.collection.activeCollection.isContentLoading

export default {
  getSingleCollection,
  getCollections,
  getSharedCollections,
  getCollectionsLoading,
  getCollectionsMoreLoading,
  getSharedCollectionsLoading,
  getCollectionsError,
  getSharedCollectionsError,
  getTotalSharedCollections,
  getSingleCollectionLoading,
  getSingleCollectionError,
  getSingleCollectionProducts,
  getTotalCollectionsPages,
  getTotalSharedCollectionsPages,
  getCollectionContentLoading,
}
