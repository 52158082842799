const GET_LANGUAGES = 'GET_LANGUAGES'
const CREATE_LANGUAGE = 'CREATE_LANGUAGE'
const DELETE_LANGUAGE = 'DELETE_LANGUAGE'
const EDIT_LANGUAGE = 'EDIT_LANGUAGE'

const CLEAR_LANGUAGES = 'CLEAR_LANGUAGES'

export default {
  GET_LANGUAGES,
  CREATE_LANGUAGE,
  DELETE_LANGUAGE,
  EDIT_LANGUAGE,
  CLEAR_LANGUAGES
}
