const CREATE_USER = 'CREATE_USER'
const GET_USER = 'GET_USER'
const DELETE_USER = 'DELETE_USER'
const UPDATE_USER = 'UPDATE_USER'

const UPLOAD_USER_FILES = 'UPLOAD_USER_FILES'

const GET_USER_PERMISSIONS = 'GET_USER_PERMISSIONS'
const ADD_USER_PERMISSIONS = 'ADD_USER_PERMISSIONS'
const REMOVE_USER_PERMISSIONS = 'REMOVE_USER_PERMISSIONS'

const CLEAR_USER = 'CLEAR_USER'

export default {
  CREATE_USER,
  GET_USER,
  DELETE_USER,
  CLEAR_USER,
  UPDATE_USER,
  GET_USER_PERMISSIONS,
  ADD_USER_PERMISSIONS,
  REMOVE_USER_PERMISSIONS,
  UPLOAD_USER_FILES,
}
