import { notBreadcrumbed } from 'constants/routes'

const defaultIdLength = 36

const getBreadcrumbName = ({ word, customBreadcrumbs = [], t }) => {
  const customIndex = customBreadcrumbs.findIndex((el) => el.uuid === word)
  if (customIndex >= 0) {
    return customBreadcrumbs[customIndex]?.name
  }
  if (word.length === defaultIdLength) {
    return `${t('common:loading')}...`
  }
  return word
    .replace(new RegExp('(?:\\b|_)([a-z])', 'g'), (firstLetter) => {
      return firstLetter.toUpperCase()
    })
    .replaceAll('-', ' ')
}

export const getBreadcrumbs = ({ url, custom, t }) => {
  if (url === '/') return []

  const splitedUrl = url.slice(1).split('/')

  let prevPath = ''

  const pathes = splitedUrl.map((el) => {
    el = prevPath + el
    prevPath = el + '/'
    return el
  })

  const newPathes = splitedUrl.map((word, index) => (
      {
        path: pathes[index],
        name: getBreadcrumbName({ word, customBreadcrumbs: custom, t }),
      }
  ))

  return newPathes.filter(
    (el) => !notBreadcrumbed.includes(el.name.toLowerCase())
  )
}
