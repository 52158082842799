import auth from './auth'
import common from './common'
import collection from './collection'
import company from './company'
import product from './product'
import variant from './variant'
import certificate from './certificate'
import user from './user'
import permissionGroup from './permissionGroup'
import language from './globalAttributes/language'
import country from './globalAttributes/country'
import currency from './globalAttributes/currency'
import region from './globalAttributes/region'
import productionOrder from './orders/productionOrder'
import report from './report'
import defectCategory from './defects/defectCategory'
import productDefect from './defects/productDefect'
import stoneCertificate from './stoneCertificate'
import template from './template'
import customField from './customField'

export default {
  auth,
  common,
  collection,
  company,
  product,
  variant,
  language,
  certificate,
  user,
  country,
  permissionGroup,
  currency,
  region,
  productionOrder,
  report,
  defectCategory,
  productDefect,
  stoneCertificate,
  template,
  customField,
}
