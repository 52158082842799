import moment from 'moment'

export const isObjectEmpty = (obj) => Object.keys(obj).length === 0

export const getUserInitials = ({ firstname, lastname }) =>
  firstname && lastname
    ? `${firstname?.slice(0, 1).toUpperCase()} ${lastname
        ?.slice(0, 1)
        .toUpperCase()}`
    : ''

export const clearParams = ({ params = null }) => {
  if (params) {
    if (!isObjectEmpty(params)) {
      Object.keys(params).forEach((key) => {
        if (params[key] === '') {
          delete params[key]
        }
      })
    }
    return params
  }
}

export const getFirstLetter = (str) => str[0]

export const getCreatedDate = (t, date, title = '') =>
  date
    ? `${title || t('common:created')}: ${moment(date).format(
        'MMMM D[th] yyyy [at] HH:mm'
      )}`
    : ''

export const getFormattedDate = (date = '') =>
  date ? moment(date).format('DD MMM yyyy')?.toUpperCase() : ''

export const isFieldsEqual = (data, item) =>
  Object.keys(item)
    .filter((key) => key in data)
    .every(
      (itemKey) =>
        (!data[itemKey] && !item[itemKey]) || item[itemKey] === data[itemKey]
    )

export const isCorrectUuid = (uuid) => {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
  return regexExp.test(uuid)
}

export const getRequestFiles = (formFiles = [], itemFiles = []) => {
  const newFiles = formFiles?.filter((el) => !el?.uuid) || []
  const removedFilesIds = (
    itemFiles.filter(
      (el) =>
        el?.uuid && !formFiles?.find((itemFile) => itemFile?.uuid === el?.uuid)
    ) || []
  ).map((el) => el.uuid)

  return {
    newFiles,
    removedFilesIds,
  }
}

export const handleBaseInfo = ({ data, item, requests }) => {
  const { newFiles, removedFilesIds } = getRequestFiles(
    data?.files,
    item?.files
  )

  if (newFiles.length) {
    requests.uploadFiles(newFiles)
  }

  if (removedFilesIds.length) {
    requests.deleteFiles(removedFilesIds)
  }

  const content = {
    name: data?.name || '',
    description: data?.description || '',
    language_id: data?.language_id,
  }

  if (
    content.name !== item?.content?.name ||
    content.description !== item?.content?.description
  ) {
    if (content?.language_id !== item?.content?.language?.uuid) {
      requests.addContent(content)
    } else {
      requests.updateContent(content)
    }
  }
}
