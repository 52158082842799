import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const initialState = {
  languages: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
  },
  defaultLang: null,
}

const languageReducer = (state = initialState, { payload, type }) => {
  let index
  switch (type) {
    case PENDING(types.GET_LANGUAGES):
      return {
        ...state,
        languages: {
          ...state.languages,
          loading: true,
        },
      }
    case SUCCESS(types.GET_LANGUAGES):
      return {
        ...state,
        languages: {
          ...state.languages,
          items: payload.reverse(),
          error: '',
          loading: false,
        },
        defaultLang: payload.find((el) => el?.is_default),
      }
    case FAILURE(types.GET_LANGUAGES):
      return {
        ...state,
        languages: {
          ...state.languages,
          loading: false,
          error: payload.message,
        },
      }
    case SUCCESS(types.CREATE_LANGUAGE):
      return {
        ...state,
        languages: {
          ...state.languages,
          items: [...state.languages.items, payload],
        },
      }
    case SUCCESS(types.EDIT_LANGUAGE):
      const newItems = state.languages.items
      index = newItems.findIndex((el) => el.uuid === payload.uuid)
      newItems[index] = payload
      return {
        ...state,
        languages: {
          ...state.languages,
          items: [...newItems],
        },
      }
    case SUCCESS(types.DELETE_LANGUAGE):
      index = state.languages.items.findIndex((el) => el.uuid === payload)
      return {
        ...state,
        languages: {
          ...state.languages,
          items: [
            ...state.languages.items.slice(0, index),
            ...state.languages.items.slice(index + 1),
          ],
        },
      }
    case SUCCESS(types.CLEAR_LANGUAGES):
      return {
        ...state,
        languages: {
          items: [],
          loading: true,
          error: '',
          totalPages: 0,
        },
      }
    default:
      return state
  }
}

export default languageReducer
