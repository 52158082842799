export const COLLECTION = 'collection'
export const PRODUCT = 'product'
export const COMPANY = 'company'
export const USER = 'user'
export const ORDER = 'order'

export const notBreadcrumbed = [COLLECTION, PRODUCT, COMPANY, USER, ORDER]

const routes = Object.freeze({
  home: '/',
  login: '/login',
  pageNotFound: '/page-not-found',
  dashboard: '/dashboard',
  setPassword: '/set-password',
  productLibrary: {
    root: '/product-library',
    collection: `${COLLECTION}/:collectionId`,
    createCollection: `create-new-collection`,
    product: `${PRODUCT}/:productId`,
    createProduct: `create-new-product`,
  },
  companyManagement: {
    root: '/company-management',
    company: `${COMPANY}/:companyId`,
    createCompany: 'create-company',
    user: `${USER}/:userId`,
    myCompany: '/my-company',
  },
  adminPanel: {
    root: '/admin-panel',
  },
  quality_control: {
    orders: {
      root: '/orders',
      createOrder: 'create-order',
      order: `:orderId`,
    },
    reports: {
      root: '/reports',
      createReport: 'create-report',
      report: ':reportId',
    },
    templates: {
      root: '/templates',
      createTemplage: 'create-template',
      template: ':templateId',
    },
  },
})

export default routes
