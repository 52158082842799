import React, { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { authActions, authSelectors } from 'store/ducks/auth'

import tools from 'constants/tools'
import routes from 'constants/routes'

import Loader from 'components/Loader'

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const token = useSelector(authSelectors.getUserToken)
  const { items: apiTools } = useSelector(authSelectors.getMyToolsInfo)
  const { permissions } = useSelector(authSelectors.getMyPermissions)
  const {
    loading: userLoading,
    user,
    isAdmin,
  } = useSelector(authSelectors.getAuthInfo)
  const { isLoading: isToolsLoading } = useSelector(
    authSelectors.getMyToolsInfo
  )

  const userTools = useMemo(
    () =>
      Object.values(tools).filter((tool) =>
        apiTools.find((el) => el === tool.name)
      ),
    [apiTools]
  )

  useEffect(() => {
    if (!token) {
      navigate(routes.login)
    }
  }, [navigate, token])

  useEffect(() => {
    if (isAdmin && user?.uuid) {
      dispatch(authActions.fetchMyPermissionsMock())
    }
  }, [isAdmin, dispatch, user?.uuid])

  useEffect(() => {
    if (isAdmin && user?.uuid) {
      if (location.pathname === '/') {
        navigate(routes.adminPanel.root)
      }
    } else if (userTools.length && user?.uuid) {
      const activeTool = userTools.find((el) =>
        location.pathname.includes(el.route)
      )
      if (activeTool) {
        if (!permissions || !permissions[activeTool.name]) {
          dispatch(
            authActions.fetchMyPermissions({
              params: { tools: [activeTool.name] },
              id: user?.uuid,
            })
          )
        }
      } else {
        navigate(userTools[0].route)
      }
    }
  }, [
    navigate,
    location,
    userTools,
    dispatch,
    user?.uuid,
    permissions,
    isAdmin,
  ])

  if (userLoading || isToolsLoading) {
    return <Loader />
  }

  return children
}

PrivateRoute.propTypes = {
  children: PropTypes.object.isRequired,
}

export default PrivateRoute
