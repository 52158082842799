import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { getUserInitials } from 'utils/helpers'

import styles from './Avatar.module.scss'

const Avatar = ({ image = '', firstname = '', lastname = '' }) => {
  const userInitials = useMemo(
    () =>
      getUserInitials({
        firstname: firstname,
        lastname: lastname,
      }),
    [firstname, lastname]
  )

  return (
    <div className={styles.avatar}>
      {image ? (
        <img src={image} alt={firstname} className={styles.avatar_image} />
      ) : (
        <div className={styles.avatar_empty}>
          <p className={styles.avatar_empty_name}>{userInitials}</p>
        </div>
      )}
    </div>
  )
}

Avatar.propTypes = {
  image: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  ref: PropTypes.object,
}

export default Avatar
