import types from './actionTypes'
import { SUCCESS, FAILURE, PENDING } from 'constants/configs'

const initialState = {
  certificates: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0
  },
}

const certificateReducer = (state = initialState, { payload, type }) => {
  let index
  let newItems
  switch (type) {
    case PENDING(types.GET_CERTIFICATES):
      return {
        ...state,
        certificates: {
          ...state.certificates,
          loading: true,
        },
      }
    case SUCCESS(types.GET_CERTIFICATES):
      return {
        ...state,
        certificates: {
          ...state.certificates,
          items: payload.data,
          error: '',
          loading: false,
          totalPages: payload.meta.last_page
        },
      }
    case FAILURE(types.GET_CERTIFICATES):
      return {
        ...state,
        certificates: {
          ...state.certificates,
          loading: false,
          error: payload.message,
        },
      }
    case SUCCESS(types.CREATE_CERTIFICATE):
      return {
        ...state,
        certificates: {
          ...state.certificates,
          items: [...state.certificates.items, payload.data],
        },
      }
    case SUCCESS(types.EDIT_CERTIFICATE):
      newItems = state.certificates.items
      index = newItems.findIndex((el) => el.uuid === payload.data.uuid)
      newItems[index] = payload.data
      return {
        ...state,
        certificates: {
          ...state.certificates,
          items: [...newItems],
        },
      }
    case SUCCESS(types.DELETE_CERTIFICATE):
      index = state.certificates.items.findIndex((el) => el.uuid === payload)
      return {
        ...state,
        certificates: {
          ...state.certificates,
          items: [
            ...state.certificates.items.slice(0, index),
            ...state.certificates.items.slice(index + 1),
          ],
        },
      }
    case SUCCESS(types.UPLOAD_CERTIFICATE_LOGO):
      newItems = state.certificates.items
      index = newItems.findIndex((el) => el.uuid === payload.data.uuid)
      newItems[index] = payload.data
      return {
        ...state,
        certificates: {
          ...state.certificates,
          items: [...newItems],
        },
      }
    case SUCCESS(types.CLEAR_CERTIFICATES):
      return {
        ...state,
        certificates: {
          items: [],
          loading: true,
          error: '',
          totalPages: 0
        },
      }
    default:
      return state
  }
}

export default certificateReducer
