import { requests } from '../api'

const initialEndpoint = 'currencies'

const getCurrencies = ({ params = null }) =>
  requests.get({ url: initialEndpoint, params }).then((res) => res.data)

const createCurrency = ({ data }) =>
  requests.post({ url: initialEndpoint, data }).then((res) => res.data)

const editCurrency = ({ data, id }) =>
  requests
    .put({ url: `${initialEndpoint}/${id}`, data })
    .then((res) => res.data)

const deleteCurrency = ({ id }) =>
  requests.delete({ url: `${initialEndpoint}/${id}` })

export default { getCurrencies, createCurrency, editCurrency, deleteCurrency }
