import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const initialState = {
  productDefects: {
    items: [],
    loading: true,
    moreLoading: false,
    error: '',
    totalPages: 0,
  },
}

const productDefectReducer = (state = initialState, { payload, type }) => {
  let index
  switch (type) {
    case PENDING(types.GET_PRODUCT_DEFECTS):
      return {
        ...state,
        productDefects: {
          ...state.productDefects,
          loading: true,
        },
      }
    case SUCCESS(types.GET_PRODUCT_DEFECTS):
      return {
        ...state,
        productDefects: {
          ...state.productDefects,
          items: payload.data,
          totalPages: payload.meta.last_page,
          error: '',
          loading: false,
        },
      }
    case FAILURE(types.GET_PRODUCT_DEFECTS):
      return {
        ...state,
        productDefects: {
          ...state.productDefects,
          loading: false,
          error: payload.message,
        },
      }
    case PENDING(types.GET_MORE_PRODUCT_DEFECTS):
      return {
        ...state,
        productDefects: {
          ...state.productDefects,
          moreLoading: true,
        },
      }
    case SUCCESS(types.GET_MORE_PRODUCT_DEFECTS):
      return {
        ...state,
        productDefects: {
          ...state.productDefects,
          items: [...state.productDefects.items, ...payload.data],
          error: '',
          moreLoading: false,
        },
      }
    case FAILURE(types.GET_MORE_PRODUCT_DEFECTS):
      return {
        ...state,
        productDefects: {
          ...state.productDefects,
          moreLoading: false,
          error: payload?.message,
        },
      }
    case SUCCESS(types.CREATE_PRODUCT_DEFECT):
      return {
        ...state,
        productDefects: {
          ...state.productDefects,
          items: [...state.productDefects.items, payload],
        },
      }
    case SUCCESS(types.EDIT_PRODUCT_DEFECT):
      const newItems = state.productDefects.items
      index = newItems.findIndex((el) => el.uuid === payload.uuid)
      newItems[index] = payload
      return {
        ...state,
        productDefects: {
          ...state.productDefects,
          items: [...newItems],
        },
      }
    case SUCCESS(types.DELETE_PRODUCT_DEFECT):
      index = state.productDefects.items.findIndex((el) => el.uuid === payload)
      return {
        ...state,
        productDefects: {
          ...state.productDefects,
          items: [
            ...state.productDefects.items.slice(0, index),
            ...state.productDefects.items.slice(index + 1),
          ],
        },
      }
    case SUCCESS(types.CLEAR_PRODUCT_DEFECT):
      return {
        ...state,
        productDefects: {
          items: [],
          loading: true,
          moreLoading: false,
          error: '',
          totalPages: 0,
        },
      }
    default:
      return state
  }
}

export default productDefectReducer
