import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import { authSelectors } from 'store/ducks/auth'

import routes from 'constants/routes'

const PublicRoute = ({ children }) => {
  const token = useSelector(authSelectors.getUserToken)
  return token ? <Navigate to={routes.home} /> : children
}

PublicRoute.propTypes = {
  children: PropTypes.object.isRequired,
}

export default PublicRoute
