import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MIN_SEARCH_LENGTH } from 'constants/configs'

import crossIcon from 'assets/icons/common/cross.svg'

import styles from './SearchField.module.scss'
import useDebounce from 'utils/hooks/useDebounce'

const SearchField = ({ onChange, placeholder, customStyle = '' }) => {
  const [search, setSearch] = useState('')

  const debouncedSearch = useDebounce(search)

  useEffect(() => {
    onChange(debouncedSearch)
  }, [debouncedSearch, onChange])

  const { t } = useTranslation()

  const error = search.length < MIN_SEARCH_LENGTH && search.length !== 0

  return (
    <div className={`${styles.search} ${customStyle}`}>
      <div className={styles.search_container}>
        <input
          placeholder={placeholder}
          onChange={(e) => setSearch(e.target.value)}
          className={`${styles.search_container_input} ${
            error ? styles.search_container_errorInput : ''
          }`}
          value={search}
        />
        {search.length ? (
          <img
            src={crossIcon}
            alt="cross-icon"
            className={styles.search_container_cross}
            onClick={() => setSearch('')}
          />
        ) : null}
      </div>
      {error ? (
        <span className={styles.search_error}>{t('errors:searchMin')}</span>
      ) : null}
    </div>
  )
}

SearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  customStyle: PropTypes.string,
}

export default SearchField
