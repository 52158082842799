import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const initialState = {
  collectionProducts: {
    items: [],
    loading: true,
    error: '',
    total: 0,
    totalPages: 0,
    moreLoading: false,
  },
  activeProduct: {
    item: null,
    loading: false,
    error: '',
    isContentLoading: false,
  },
  attributes: {
    items: [],
    loading: true,
    error: '',
  },
  activeAttributesOptions: {
    items: null,
    loading: false,
    error: '',
  },
  attributesOptions: {
    items: null,
    loading: true,
    error: '',
  },
  allAttributesOptions: {
    items: null,
    loading: true,
    error: '',
  },
  instances: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
  },
  options: {
    items: [],
    loading: true,
    error: '',
  },
}

const productReducer = (state = initialState, { payload, type }) => {
  let activeIndex = 0
  switch (type) {
    case PENDING(types.GET_PRODUCTS_BY_COLLECTION):
      return {
        ...state,
        collectionProducts: {
          ...state.collectionProducts,
          loading: true,
        },
      }
    case SUCCESS(types.GET_PRODUCTS_BY_COLLECTION):
      return {
        ...state,
        collectionProducts: {
          ...state.collectionProducts,
          items: payload.data,
          error: '',
          loading: false,
          total: payload.meta.total,
          totalPages: payload.meta.last_page,
        },
      }
    case FAILURE(types.GET_PRODUCTS_BY_COLLECTION):
      return {
        ...state,
        collectionProducts: {
          ...state.collectionProducts,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_MORE_PRODUCTS):
      return {
        ...state,
        collectionProducts: {
          ...state.collectionProducts,
          moreLoading: true,
        },
      }
    case SUCCESS(types.GET_MORE_PRODUCTS):
      return {
        ...state,
        collectionProducts: {
          ...state.collectionProducts,
          items: [...state.collectionProducts.items, ...payload.data],
          error: '',
          moreLoading: false,
        },
      }
    case FAILURE(types.GET_MORE_PRODUCTS):
      return {
        ...state,
        collectionProducts: {
          ...state.collectionProducts,
          moreLoading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_PRODUCT):
      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          loading: true,
        },
      }
    case SUCCESS(types.GET_PRODUCT):
      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          item: payload.data,
          error: '',
          loading: false,
        },
      }
    case FAILURE(types.GET_PRODUCT):
      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          loading: false,
          error: payload,
        },
      }
    case SUCCESS(types.UPLOAD_PRODUCT_FILES):
      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          item: {
            ...state.activeProduct.item,
            files: [...state.activeProduct.item.files, ...payload.data],
          },
        },
      }
    case SUCCESS(types.DELETE_PRODUCT_FILES):
      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          item: {
            ...state.activeProduct.item,
            files: state.activeProduct.item.files.filter(
              (el) => !payload.includes(el.uuid)
            ),
          },
        },
      }
    case PENDING(types.GET_ATTRIBUTES):
      return {
        ...state,
        attributes: {
          ...state.attributes,
          loading: true,
        },
      }
    case SUCCESS(types.GET_ATTRIBUTES):
      return {
        ...state,
        attributes: {
          ...state.attributes,
          items: payload.data,
          error: '',
          loading: false,
        },
      }
    case FAILURE(types.GET_ATTRIBUTES):
      return {
        ...state,
        attributes: {
          ...state.attributes,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_PRODUCT_ATTRIBUTES_MATRIX):
      return {
        ...state,
        attributesOptions: {
          ...state.attributesOptions,
          loading: true,
        },
      }
    case SUCCESS(types.GET_PRODUCT_ATTRIBUTES_MATRIX):
      return {
        ...state,
        attributesOptions: {
          ...state.attributesOptions,
          items: payload.data,
          error: '',
          loading: false,
        },
      }
    case FAILURE(types.GET_PRODUCT_ATTRIBUTES_MATRIX):
      return {
        ...state,
        attributesOptions: {
          ...state.attributesOptions,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_ACTIVE_ATTRIBUTES_MATRIX):
      return {
        ...state,
        activeAttributesOptions: {
          ...state.activeAttributesOptions,
          loading: true,
        },
      }
    case SUCCESS(types.GET_ACTIVE_ATTRIBUTES_MATRIX):
      return {
        ...state,
        activeAttributesOptions: {
          ...state.activeAttributesOptions,
          items: payload.data,
          error: '',
          loading: false,
        },
      }
    case FAILURE(types.GET_ACTIVE_ATTRIBUTES_MATRIX):
      return {
        ...state,
        activeAttributesOptions: {
          ...state.activeAttributesOptions,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_ATTRIBUTES_MATRIX):
      return {
        ...state,
        allAttributesOptions: {
          ...state.allAttributesOptions,
          loading: true,
        },
      }
    case SUCCESS(types.GET_ATTRIBUTES_MATRIX):
      return {
        ...state,
        allAttributesOptions: {
          ...state.allAttributesOptions,
          items: payload.data,
          error: '',
          loading: false,
        },
      }
    case FAILURE(types.GET_ATTRIBUTES_MATRIX):
      return {
        ...state,
        allAttributesOptions: {
          ...state.allAttributesOptions,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_ATTRIBUTES_OPTIONS):
      return {
        ...state,
        options: {
          ...state.options,
          loading: true,
        },
      }
    case SUCCESS(types.GET_ATTRIBUTES_OPTIONS):
      return {
        ...state,
        options: {
          ...state.options,
          items: payload.data,
          error: '',
          loading: false,
        },
      }
    case FAILURE(types.GET_ATTRIBUTES_OPTIONS):
      return {
        ...state,
        options: {
          ...state.options,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_PRODUCT_INSTANCES):
      return {
        ...state,
        instances: {
          ...state.instances,
          loading: true,
        },
      }
    case SUCCESS(types.GET_PRODUCT_INSTANCES):
      return {
        ...state,
        instances: {
          ...state.instances,
          items: payload.data,
          error: '',
          loading: false,
          totalPages: payload.meta.last_page,
        },
      }
    case FAILURE(types.GET_PRODUCT_INSTANCES):
      return {
        ...state,
        instances: {
          ...state.instances,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.CREATE_PRODUCT_INSTANCE):
      return {
        ...state,
        instances: {
          ...state.instances,
        },
      }
    case SUCCESS(types.CREATE_PRODUCT_INSTANCE):
      return {
        ...state,
        instances: {
          ...state.instances,
          items: [...payload.data, ...state.instances.items],
          error: '',
        },
      }
    case FAILURE(types.CREATE_PRODUCT_INSTANCE):
      return {
        ...state,
        instances: {
          ...state.instances,
          error: payload,
        },
      }
    case PENDING(types.DELETE_PRODUCT_INSTANCE):
      return {
        ...state,
        instances: {
          ...state.instances,
        },
      }
    case SUCCESS(types.DELETE_PRODUCT_INSTANCE):
      activeIndex = state.instances.items.findIndex(
        (el) => el.uuid === payload.instanceId
      )
      return {
        ...state,
        instances: {
          ...state.instances,
          items: [
            ...state.instances.items.slice(0, activeIndex),
            ...state.instances.items.slice(activeIndex + 1),
          ],
          error: '',
        },
      }
    case FAILURE(types.DELETE_PRODUCT_INSTANCE):
      return {
        ...state,
        instances: {
          ...state.instances,
          error: payload,
        },
      }
    case SUCCESS(types.UPDATE_PRODUCT_CONTENT):
      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          item: {
            ...state.activeProduct.item,
            content: payload.data,
          },
        },
      }
    case SUCCESS(types.ADD_PRODUCT_CONTENT):
      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          item: {
            ...state.activeProduct.item,
            content: payload.data,
            language_ids: [
              ...state.activeProduct.item.language_ids,
              payload.data.language.uuid,
            ],
          },
        },
      }
    case SUCCESS(types.DELETE_PRODUCT_CONTENT):
      activeIndex = state.activeProduct.item.language_ids.findIndex(
        (el) => el === payload
      )
      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          item: {
            ...state.activeProduct.item,
            language_ids: [
              ...state.activeProduct.item.language_ids.slice(0, activeIndex),
              ...state.activeProduct.item.language_ids.slice(activeIndex + 1),
            ],
          },
        },
      }
    case PENDING(types.GET_PRODUCT_CONTENT):
      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          isContentLoading: true,
        },
      }
    case SUCCESS(types.GET_PRODUCT_CONTENT):
      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          isContentLoading: false,
          item: {
            ...state.activeProduct.item,
            content: payload?.data[0],
          },
        },
      }
    case FAILURE(types.GET_PRODUCT_CONTENT):
      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          isContentLoading: false,
        },
      }
    case SUCCESS(types.CLEAR_PRODUCTS):
      return {
        ...state,
        collectionProducts: {
          items: [],
          loading: true,
          error: '',
          total: 0,
          totalPages: 0,
        },
      }
    case SUCCESS(types.CLEAR_PRODUCT):
      return {
        ...state,
        activeProduct: {
          item: null,
          loading: false,
          error: '',
          isContentLoading: false,
        },
        attributes: {
          items: [],
          loading: true,
          error: '',
        },
        activeAttributesOptions: {
          items: null,
          loading: true,
          error: '',
        },
        attributesOptions: {
          items: null,
          loading: true,
          error: '',
        },
        allAttributesOptions: {
          items: null,
          loading: true,
          error: '',
        },
        instances: {
          items: [],
          loading: true,
          error: '',
          totalPages: 0,
        },
        options: {
          items: [],
          loading: true,
          error: '',
        },
      }
    default:
      return state
  }
}

export default productReducer
