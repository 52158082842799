const GET_COLLECTION_BY_ID = 'GET_COLLECTION_BY_ID'
const GET_COLLECTIONS = 'GET_COLLECTIONS'
const GET_SHARED_COLLECTIONS = 'GET_SHARED_COLLECTIONS'
const CREATE_COLLECTION = 'CREATE_COLLECTION'
const DELETE_COLLECTION = 'DELETE_COLLECTION'
const CLEAR_COLLECTION = 'CLEAR_COLLECTION'
const CLEAR_COLLECTIONS = 'CLEAR_COLLECTIONS'
const GET_MORE_COLLECTIONS = 'GET_MORE_COLLECTIONS'
const SHARE_COLLECTION = 'SHARE_COLLECTION'
const UNSHARE_COLLECTION = 'UNSHARE_COLLECTION'

const ADD_COLLECTION_CONTENT = 'ADD_COLLECTION_CONTENT'
const UPDATE_COLLECTION_CONTENT = 'UPDATE_COLLECTION_CONTENT'
const DELETE_COLLECTION_CONTENT = 'DELETE_COLLECTION_CONTENT'
const GET_COLLECTION_CONTENT = 'GET_COLLECTION_CONTENT'

export default {
  GET_COLLECTION_BY_ID,
  GET_COLLECTIONS,
  GET_SHARED_COLLECTIONS,
  CLEAR_COLLECTION,
  SHARE_COLLECTION,
  UNSHARE_COLLECTION,
  ADD_COLLECTION_CONTENT,
  UPDATE_COLLECTION_CONTENT,
  DELETE_COLLECTION_CONTENT,
  GET_COLLECTION_CONTENT,
  CREATE_COLLECTION,
  CLEAR_COLLECTIONS,
  GET_MORE_COLLECTIONS,
  DELETE_COLLECTION,
}
