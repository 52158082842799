const GET_REPORTS = 'GET_REPORTS'
const GET_REPORT = 'GET_REPORT'
const CREATE_REPORT = 'CREATE_REPORT'
const EDIT_REPORT = 'EDIT_REPORT'
const DELETE_REPORT = 'DELETE_REPORT'

const GET_REPORT_ROWS = 'GET_REPORT_ROWS'
const CREATE_REPORT_ROW = 'CREATE_REPORT_ROW'
const DELETE_REPORT_ROW = 'DELETE_REPORT_ROW'
const EDIT_REPORT_ROW_STATUS = 'EDIT_REPORT_ROW_STATUS'
const EDIT_REPORT_ROW = 'EDIT_REPORT_ROW'

const GET_REPORT_ROW_PROBLEMS = 'GET_REPORT_ROW_PROBLEMS'
const CREATE_REPORT_ROW_PROBLEM = 'CREATE_REPORT_ROW_PROBLEM'
const DELETE_REPORT_ROW_PROBLEM = 'DELETE_REPORT_ROW_PROBLEM'
const EDIT_REPORT_ROW_PROBLEM = 'EDIT_REPORT_ROW_PROBLEM'
const UPLOAD_REPORT_ROW_PROBLEM_FILE = 'UPLOAD_REPORT_ROW_PROBLEM_FILE'
const DELETE_REPORT_ROW_PROBLEM_FILE = 'DELETE_REPORT_ROW_PROBLEM_FILE'

const GET_REPORT_DEFECT_CATEGORIES = 'GET_REPORT_DEFECT_CATEGORIES'
const GET_MORE_REPORT_DEFECT_CATEGORIES = 'GET_MORE_REPORT_DEFECT_CATEGORIES'

const GET_REPORT_DEFECTS = 'GET_REPORT_DEFECTS'
const GET_MORE_REPORT_DEFECTS = 'GET_MORE_REPORT_DEFECTS'

const GET_REPORT_ROW_CERTIFICATES = 'GET_REPORT_ROW_CERTIFICATES'

const GET_REPORT_ORDERS = 'GET_REPORT_ORDERS'

const SEND_REPORT = 'SEND_REPORT'
const SYNCHRONIZE_REPORT = 'SYNCHRONIZE_REPORT'

const GET_REPORT_ROW_HISTORY = 'GET_REPORT_ROW_HISTORY'

const GET_REPORT_ROW_FIELDS = 'GET_REPORT_ROW_FIELDS'

const CLEAR_REPORT = 'CLEAR_REPORT'
const CLEAR_REPORTS = 'CLEAR_REPORTS'

export default {
  GET_REPORTS,
  GET_REPORT,
  CREATE_REPORT,
  EDIT_REPORT,
  DELETE_REPORT,
  CLEAR_REPORT,
  GET_REPORT_ROWS,
  CREATE_REPORT_ROW,
  DELETE_REPORT_ROW,
  EDIT_REPORT_ROW_STATUS,
  EDIT_REPORT_ROW,
  GET_REPORT_ROW_PROBLEMS,
  CREATE_REPORT_ROW_PROBLEM,
  DELETE_REPORT_ROW_PROBLEM,
  GET_REPORT_DEFECT_CATEGORIES,
  GET_MORE_REPORT_DEFECT_CATEGORIES,
  EDIT_REPORT_ROW_PROBLEM,
  GET_REPORT_DEFECTS,
  GET_MORE_REPORT_DEFECTS,
  UPLOAD_REPORT_ROW_PROBLEM_FILE,
  DELETE_REPORT_ROW_PROBLEM_FILE,
  GET_REPORT_ROW_FIELDS,
  GET_REPORT_ROW_CERTIFICATES,
  GET_REPORT_ORDERS,
  GET_REPORT_ROW_HISTORY,
  SEND_REPORT,
  SYNCHRONIZE_REPORT,
  CLEAR_REPORTS,
}
