//

const getVariantsInfo = (state) => state.variant.variants

const getActiveVariant = (state) => state.variant.activeVariant.item
const getVariantContentLoading = (state) =>
  state.variant.activeVariant.isContentLoading

export default {
  getVariantsInfo,
  getActiveVariant,
  getVariantContentLoading,
}
