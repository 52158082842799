export default {
  collections: 'Collections',
  smallCollections: 'collections',
  accessSettings: 'Access Settings',
  sharedWithMe: 'Shared with me',
  newCollection: 'New Collection',
  emptyDescription: 'There are no collections under this category right now',
  collection: 'Collection',
  instances: 'Instances',
  references: 'References',
}
