import { toast } from 'react-toastify'

import regions from 'services/globalAttributes/regions'
import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import { clearParams } from 'utils/helpers'
import i18n from 'translations'

const fetchRegions =
  ({ params, isPaginate = false }) =>
  (dispatch) => {
    const activeType = isPaginate ? types.GET_MORE_REGIONS : types.GET_REGIONS
    dispatch({ type: PENDING(activeType) })
    clearParams({ params })
    regions
      .getRegions({ params })
      .then((res) => dispatch({ type: SUCCESS(activeType), payload: res }))
      .catch(() =>
        dispatch({
          type: FAILURE(activeType),
        })
      )
  }

const createRegion =
  ({ data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.CREATE_REGION) })
    return regions
      .createRegion({ data })
      .then((res) => {
        toast.success(
          `${i18n.t('attributes:regions:single_name')} ${i18n.t(
            'common:create_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.CREATE_REGION), payload: res })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.CREATE_REGION),
        })
      )
  }

const editRegion =
  ({ data, id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.EDIT_REGION) })
    return regions
      .editRegion({ data, id })
      .then((res) => {
        toast.success(
          `${i18n.t('attributes:regions:single_name')} ${i18n.t(
            'common:edit_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.EDIT_REGION), payload: res })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.EDIT_REGION),
        })
      )
  }

const deleteRegion =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_REGION) })
    regions
      .deleteRegion({ id })
      .then(() => {
        toast.success(
          `${i18n.t('attributes:regions:single_name')} ${i18n.t(
            'common:delete_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.DELETE_REGION), payload: id })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.DELETE_REGION),
        })
      )
  }

const clearRegions = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_REGIONS) })

export default {
  fetchRegions,
  createRegion,
  editRegion,
  deleteRegion,
  clearRegions,
}
