import { requests, formDataHeaders } from './api'
import { filesToFormData } from 'utils/serializer'

const initialEndpoint = 'reports'

const getReports = ({ params }) =>
  requests.get({ url: initialEndpoint, params }).then((res) => res.data)
const getReport = ({ id }) =>
  requests.get({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)
const createReport = ({ data }) =>
  requests.post({ url: initialEndpoint, data }).then((res) => res.data)
const deleteReport = ({ id }) =>
  requests.delete({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)
const editReport = ({ id, data }) =>
  requests
    .put({ url: `${initialEndpoint}/${id}`, data })
    .then((res) => res.data)

const getReportRows = ({ reportId, params }) =>
  requests
    .get({ url: `${initialEndpoint}/${reportId}/rows`, params })
    .then((res) => res.data)
const createReportRow = ({ reportId, data }) =>
  requests
    .post({ url: `${initialEndpoint}/${reportId}/rows`, data })
    .then((res) => res.data)
const deleteReportRow = ({ reportId, rowId }) =>
  requests
    .delete({ url: `${initialEndpoint}/${reportId}/rows/${rowId}` })
    .then((res) => res.data)
const editReportRowStatus = ({ reportId, rowId, status }) =>
  requests
    .patch({
      url: `${initialEndpoint}/${reportId}/rows/${rowId}/status/${status}`,
    })
    .then((res) => res.data)

const getReportRowProblems = ({ reportId, rowId }) =>
  requests
    .get({ url: `${initialEndpoint}/${reportId}/rows/${rowId}/problems` })
    .then((res) => res.data)
const createReportRowProblem = ({ reportId, rowId, data }) =>
  requests
    .post({
      url: `${initialEndpoint}/${reportId}/rows/${rowId}/problems`,
      data,
    })
    .then((res) => res.data)
const deleteReportRowProblem = ({ reportId, rowId, problemId }) =>
  requests
    .delete({
      url: `${initialEndpoint}/${reportId}/rows/${rowId}/problems/${problemId}`,
    })
    .then((res) => res.data)
const editReportRowProblem = ({ reportId, rowId, problemId, data }) =>
  requests
    .put({
      url: `${initialEndpoint}/${reportId}/rows/${rowId}/problems/${problemId}`,
      data,
    })
    .then((res) => res.data)
const uploadReportRowProblemFile = ({ reportId, rowId, problemId, data }) =>
  requests
    .post({
      url: `${initialEndpoint}/${reportId}/rows/${rowId}/problems/${problemId}/images`,
      data: filesToFormData(data),
      headers: formDataHeaders,
    })
    .then((res) => res.data)
const deleteReportRowProblemFile = ({ reportId, rowId, problemId, fileId }) =>
  requests
    .delete({
      url: `${initialEndpoint}/${reportId}/rows/${rowId}/problems/${problemId}/images/${fileId}`,
    })
    .then((res) => res.data)

const sendReport = ({ reportId }) =>
  requests
    .post({ url: `${initialEndpoint}/${reportId}/send` })
    .then((res) => res.data)

const synchronizeReport = ({ reportId, orderId, data }) =>
  requests
    .post({
      url: `${initialEndpoint}/${reportId}/production-orders/${orderId}/products/synchronize`,
      data,
    })
    .then((res) => res.data)

const getReportRowHistory = ({ reportId, rowId, params }) =>
  requests
    .get({
      url: `${initialEndpoint}/${reportId}/rows/${rowId}/history`,
      params,
    })
    .then((res) => res.data)

const getReportRowFields = ({ reportId, rowId }) =>
  requests
    .get({
      url: `${initialEndpoint}/${reportId}/rows/${rowId}/custom-fields`,
    })
    .then((res) => res.data)

const editReportRow = ({ reportId, rowId, data }) =>
  requests
    .put({
      url: `${initialEndpoint}/${reportId}/rows/${rowId}`,
      data,
    })
    .then((res) => res.data)

export default {
  getReport,
  getReports,
  createReport,
  deleteReport,
  editReport,
  getReportRows,
  createReportRow,
  deleteReportRow,
  editReportRowStatus,
  getReportRowProblems,
  createReportRowProblem,
  deleteReportRowProblem,
  editReportRowProblem,
  uploadReportRowProblemFile,
  deleteReportRowProblemFile,
  sendReport,
  getReportRowHistory,
  synchronizeReport,
  getReportRowFields,
  editReportRow,
}
