import { requests, formDataHeaders } from './api'
import { filesToFormData } from 'utils/serializer'

const initialEndpoint = 'companies'
const companyLink = 'company-link-requests'
const companyCertificates = 'company-certificates'

const getCompanies = ({ params = {} }) =>
  requests.get({ url: initialEndpoint, params })

const getCompany = ({ id }) => requests.get({ url: `${initialEndpoint}/${id}` })
const updateCompany = ({ id, data }) =>
  requests.put({ url: `${initialEndpoint}/${id}`, data })
const deleteCompany = ({ id }) =>
  requests.delete({ url: `${initialEndpoint}/${id}/archive` })
const createCompany = ({ data }) =>
  requests.post({ url: `${initialEndpoint}`, data }).then((res) => res.data)

const uploadCompanyLogo = ({ id, data }) =>
  requests.post({
    url: `${initialEndpoint}/${id}/logo`,
    data: filesToFormData(data, 'file'),
    headers: formDataHeaders,
  })
const deleteCompanyLogo = ({ id }) =>
  requests.delete({ url: `${initialEndpoint}/${id}/logo` })

const uploadCompanyFiles = ({ id, data }) =>
  requests.post({
    url: `${initialEndpoint}/${id}/files/upload`,
    data: filesToFormData(data),
    headers: formDataHeaders,
  })
const deleteCompanyFiles = ({ id, data }) =>
  requests.delete({
    url: `${initialEndpoint}/${id}/files/delete`,
    params: { file_ids: data },
  })

const getLettersOfCompanies = () =>
  requests.get({ url: `${initialEndpoint}/letters` })

const getLinkedCompanies = ({ id, params }) =>
  requests.get({ url: `${initialEndpoint}/${id}/linked-companies`, params })

const getCompaniesUsers = ({ id, params = null }) =>
  requests
    .get({ url: `${initialEndpoint}/${id}/users`, params })
    .then((res) => res.data)

const getCompanyCertificates = ({ params = null }) =>
  requests.get({ url: companyCertificates, params }).then((res) => res.data)
const createCompanyCertificates = ({ data }) =>
  requests.post({ url: companyCertificates, data }).then((res) => res.data)
const deleteCompanyCertificate = ({ id }) =>
  requests
    .delete({ url: `${companyCertificates}/${id}` })
    .then((res) => res.data)
const editCompanyCertificate = ({ data, id }) =>
  requests
    .put({ url: `${companyCertificates}/${id}`, data })
    .then((res) => res.data)

const uploadCompanyCertificateFiles = ({ data, id }) =>
  requests
    .post({
      url: `${companyCertificates}/${id}/files/upload`,
      data: filesToFormData(data),
      headers: formDataHeaders,
    })
    .then((res) => res.data)

const deleteCompanyCertificateFiles = ({ data, id }) =>
  requests
    .delete({
      url: `${companyCertificates}/${id}/files/remove`,
      params: { file_ids: data },
    })
    .then((res) => res.data)

const getCompanyLinks = ({ params = null }) =>
  requests.get({ url: companyLink, params }).then((res) => res.data)

const createCompanyLink = ({ data }) =>
  requests.post({ url: companyLink, data })

const approveCompanyLink = ({ id }) =>
  requests
    .patch({ url: `${companyLink}/${id}/approve` })
    .then((res) => res.data)
const declineCompanyLink = ({ id }) =>
  requests
    .patch({ url: `${companyLink}/${id}/decline` })
    .then((res) => res.data)

export default {
  getCompanies,
  getCompany,
  updateCompany,
  deleteCompany,
  createCompany,
  uploadCompanyLogo,
  deleteCompanyLogo,
  uploadCompanyFiles,
  deleteCompanyFiles,
  createCompanyLink,
  getLettersOfCompanies,
  getLinkedCompanies,
  getCompaniesUsers,
  getCompanyCertificates,
  createCompanyCertificates,
  deleteCompanyCertificate,
  editCompanyCertificate,
  uploadCompanyCertificateFiles,
  deleteCompanyCertificateFiles,
  getCompanyLinks,
  approveCompanyLink,
  declineCompanyLink,
}
