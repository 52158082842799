const GET_TEMPLATES = 'GET_TEMPLATES'
const GET_MORE_TEMPLATES = 'GET_MORE_TEMPLATES'
const EDIT_TEMPLATE = 'EDIT_TEMPLATE'
const DELETE_TEMPLATE = 'DELETE_TEMPLATE'
const GET_TEMPLATE = 'GET_TEMPLATE'
const CREATE_TEMPLATE = 'CREATE_TEMPLATE'

const GET_TEMPLATE_FIELDS = 'GET_TEMPLATE_FIELDS'
const ADD_FIELD_TO_TEMPLATE = 'ADD_FIELD_TO_TEMPLATE'
const REMOVE_FIELD_FROM_TEMPLATE = 'REMOVE_FIELD_FROM_TEMPLATE'
const CHANGE_FIELD_POSITION = 'CHANGE_FIELD_POSITION'
const EDIT_TEMPLATE_FIELD = 'EDIT_TEMPLATE_FIELD'

const CLEAR_TEMPLATE = 'CLEAR_TEMPLATE'
const CLEAR_TEMPLATES = 'CLEAR_TEMPLATES'

export default {
  GET_TEMPLATES,
  GET_MORE_TEMPLATES,
  EDIT_TEMPLATE,
  DELETE_TEMPLATE,
  GET_TEMPLATE,
  CREATE_TEMPLATE,
  GET_TEMPLATE_FIELDS,
  ADD_FIELD_TO_TEMPLATE,
  REMOVE_FIELD_FROM_TEMPLATE,
  CHANGE_FIELD_POSITION,
  CLEAR_TEMPLATE,
  CLEAR_TEMPLATES,
  EDIT_TEMPLATE_FIELD,
}
