const getProductsByCollection = (state) =>
  state.product.collectionProducts.items
const getProductsByCollectionLoading = (state) =>
  state.product.collectionProducts.loading
const getProductsByCollectionError = (state) =>
  state.product.collectionProducts.error
const getProductsByCollectionTotal = (state) =>
  state.product.collectionProducts.total
const getProductsByCollectionTotalPages = (state) =>
  state.product.collectionProducts.totalPages
const getProductsByCollectionMoreLoading = (state) =>
  state.product.collectionProducts.moreLoading

const getActiveProduct = (state) => state.product.activeProduct.item
const getActiveProductLoading = (state) => state.product.activeProduct.loading
const getActiveProductError = (state) => state.product.activeProduct.error

const getAttributes = (state) => state.product.attributes.items
const getAttributesLoading = (state) => state.product.attributes.loading
const getAttributesError = (state) => state.product.attributes.error

const getAttributesOptions = (state) => state.product.attributesOptions.items
const getAttributesOptionsLoading = (state) =>
  state.product.attributesOptions.loading
const getAttributesOptionsError = (state) =>
  state.product.attributesOptions.error

const getActiveAttributesOptions = (state) =>
  state.product.activeAttributesOptions.items
const getActiveAttributesOptionsLoading = (state) =>
  state.product.activeAttributesOptions.loading
const getActiveAttributesOptionsError = (state) =>
  state.product.activeAttributesOptions.error

const getAllAttributesOptions = (state) =>
  state.product.allAttributesOptions.items
const getAllAttributesOptionsLoading = (state) =>
  state.product.allAttributesOptions.loading
const getAllAttributesOptionsError = (state) =>
  state.product.allAttributesOptions.error

const getProductInstances = (state) => state.product.instances.items
const getProductInstancesLoading = (state) => state.product.instances.loading
const getProductInstancesError = (state) => state.product.instances.error
const getProductInstancesPages = (state) => state.product.instances.totalPages

const getProductContentLoading = (state) =>
  state.product.activeProduct.isContentLoading

export default {
  getProductsByCollection,
  getProductsByCollectionLoading,
  getProductsByCollectionError,
  getProductsByCollectionTotal,
  getProductsByCollectionTotalPages,
  getActiveProduct,
  getActiveProductLoading,
  getActiveProductError,
  getAttributes,
  getAttributesLoading,
  getAttributesError,
  getActiveAttributesOptions,
  getActiveAttributesOptionsLoading,
  getActiveAttributesOptionsError,
  getAllAttributesOptions,
  getAllAttributesOptionsLoading,
  getAllAttributesOptionsError,
  getAttributesOptions,
  getAttributesOptionsLoading,
  getAttributesOptionsError,
  getProductInstances,
  getProductInstancesLoading,
  getProductInstancesError,
  getProductInstancesPages,
  getProductContentLoading,
  getProductsByCollectionMoreLoading,
}
