import { requests, formDataHeaders } from './api'
import { filesToFormData } from 'utils/serializer'

const initialEndpoint = 'certifications'

const getCertificates = ({ params }) =>
  requests.get({ url: initialEndpoint, params }).then((res) => res.data)

const createCertificate = ({ data }) => requests.post({ url: initialEndpoint, data }).then((res) => res.data)

const editCertificate = ({ data, id }) => requests.put({ url: `${initialEndpoint}/${id}`, data }).then((res) => res.data)

const deleteCertificate = ({ id }) => requests.delete({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)

const uploadCertificateLogo = ({ id, data }) => requests.post({
  url: `${initialEndpoint}/${id}/logo/upload`, data: filesToFormData(data, 'logo'),
  headers: formDataHeaders,
}).then((res) => res.data)

export default {
  getCertificates,
  createCertificate,
  editCertificate,
  deleteCertificate,
  uploadCertificateLogo
}
