const GET_CURRENCIES = 'GET_CURRENCIES'
const CREATE_CURRENCY = 'CREATE_CURRENCY'
const EDIT_CURRENCY = 'EDIT_CURRENCY'
const DELETE_CURRENCY = 'DELETE_CURRENCY'

const CLEAR_CURRENCIES = 'CLEAR_CURRENCIES'

export default {
  GET_CURRENCIES,
  CREATE_CURRENCY,
  EDIT_CURRENCY,
  DELETE_CURRENCY,
  CLEAR_CURRENCIES
}
