import React from 'react'

export const DashboardIcon = (props) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 10.5H7C7.55 10.5 8 10.05 8 9.5V1.5C8 0.95 7.55 0.5 7 0.5H1C0.45 0.5 0 0.95 0 1.5V9.5C0 10.05 0.45 10.5 1 10.5ZM1 18.5H7C7.55 18.5 8 18.05 8 17.5V13.5C8 12.95 7.55 12.5 7 12.5H1C0.45 12.5 0 12.95 0 13.5V17.5C0 18.05 0.45 18.5 1 18.5ZM11 18.5H17C17.55 18.5 18 18.05 18 17.5V9.5C18 8.95 17.55 8.5 17 8.5H11C10.45 8.5 10 8.95 10 9.5V17.5C10 18.05 10.45 18.5 11 18.5ZM10 1.5V5.5C10 6.05 10.45 6.5 11 6.5H17C17.55 6.5 18 6.05 18 5.5V1.5C18 0.95 17.55 0.5 17 0.5H11C10.45 0.5 10 0.95 10 1.5Z"
      fill="#545F7C"
    />
  </svg>
)

export const ProductLibraryIcon = (props) => (
  <svg
    width="19"
    height="22"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 11V15C2 15.83 2.67 16.5 3.5 16.5C4.33 16.5 5 15.83 5 15V11C5 10.17 4.33 9.50001 3.5 9.50001C2.67 9.50001 2 10.17 2 11ZM8 11V15C8 15.83 8.67 16.5 9.5 16.5C10.33 16.5 11 15.83 11 15V11C11 10.17 10.33 9.50001 9.5 9.50001C8.67 9.50001 8 10.17 8 11ZM1.5 21.5H17.5C18.33 21.5 19 20.83 19 20C19 19.17 18.33 18.5 17.5 18.5H1.5C0.67 18.5 0 19.17 0 20C0 20.83 0.67 21.5 1.5 21.5ZM14 11V15C14 15.83 14.67 16.5 15.5 16.5C16.33 16.5 17 15.83 17 15V11C17 10.17 16.33 9.50001 15.5 9.50001C14.67 9.50001 14 10.17 14 11ZM8.57 0.990007L0.67 5.15001C0.26 5.36001 0 5.79001 0 6.25001C0 6.94001 0.56 7.50001 1.25 7.50001H17.76C18.44 7.50001 19 6.94001 19 6.25001C19 5.79001 18.74 5.36001 18.33 5.15001L10.43 0.990007C9.85 0.680007 9.15 0.680007 8.57 0.990007Z"
      fill="#545F7C"
    />
  </svg>
)

export const AdminPanelIcon = (props) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM13.61 6.84C14.68 6.84 15.54 7.7 15.54 8.77C15.54 9.84 14.68 10.7 13.61 10.7C12.54 10.7 11.68 9.84 11.68 8.77C11.67 7.7 12.54 6.84 13.61 6.84ZM7.61 5.26C8.91 5.26 9.97 6.32 9.97 7.62C9.97 8.92 8.91 9.98 7.61 9.98C6.31 9.98 5.25 8.92 5.25 7.62C5.25 6.31 6.3 5.26 7.61 5.26ZM7.61 14.39V18.14C5.21 17.39 3.31 15.54 2.47 13.18C3.52 12.06 6.14 11.49 7.61 11.49C8.14 11.49 8.81 11.57 9.51 11.71C7.87 12.58 7.61 13.73 7.61 14.39ZM10 18.5C9.73 18.5 9.47 18.49 9.21 18.46V14.39C9.21 12.97 12.15 12.26 13.61 12.26C14.68 12.26 16.53 12.65 17.45 13.41C16.28 16.38 13.39 18.5 10 18.5Z"
      fill="#000092"
    />
  </svg>
)

export const QualityControlIcon = (props) => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM10 16H5C4.45 16 4 15.55 4 15C4 14.45 4.45 14 5 14H10C10.55 14 11 14.45 11 15C11 15.55 10.55 16 10 16ZM13 12H5C4.45 12 4 11.55 4 11C4 10.45 4.45 10 5 10H13C13.55 10 14 10.45 14 11C14 11.55 13.55 12 13 12ZM13 8H5C4.45 8 4 7.55 4 7C4 6.45 4.45 6 5 6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
      fill="#545F7C"
    />
  </svg>
)
