import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'translations'

import styles from './EmptyBlock.module.scss'

const EmptyBlock = ({
  onAdd = null,
  description = i18n.t('common:notFoundDesc'),
  addText = '',
  title = i18n.t('common:notFoundTitle'),
}) => {
  return (
    <div className={styles.emptyBlock}>
      <h4 className={styles.emptyBlock_title}>{title}</h4>
      {description ? (
        <p className={styles.emptyBlock_description}>{description}</p>
      ) : null}
      {addText && onAdd ? (
        <button
          className={styles.emptyBlock_button}
          onClick={onAdd}
          type="button"
        >
          {addText}
        </button>
      ) : null}
    </div>
  )
}

EmptyBlock.propTypes = {
  onAdd: PropTypes.func,
  description: PropTypes.string,
  addText: PropTypes.string,
  title: PropTypes.string,
}

export default EmptyBlock
