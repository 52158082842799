import React, { lazy } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import { useSelector } from 'react-redux'

import PrivateRoute from 'utils/privateRoute'
import PublicRoute from 'utils/publicRoute'

import routes from 'constants/routes'

import { authSelectors } from 'store/ducks/auth'

import MainWrapper from 'components/wrappers/MainWrapper'

const Login = lazy(() => import('screens/Login'))
const SetPassword = lazy(() => import('screens/SetPassword'))

// const Dashboard = lazy(() => import('screens/Dashboard'))

const ProductLibrary = lazy(() => import('screens/ProductLibrary'))
const Collection = lazy(() => import('screens/ProductLibrary/Collection'))
const CreateCollection = lazy(() =>
  import('screens/ProductLibrary/CreateCollection')
)
const Product = lazy(() => import('screens/ProductLibrary/Product'))
const CreateProduct = lazy(() => import('screens/ProductLibrary/CreateProduct'))

const CompanyManagement = lazy(() => import('screens/CompanyManagement'))
const Company = lazy(() => import('screens/CompanyManagement/Company'))
const CreateCompany = lazy(() =>
  import('screens/CompanyManagement/CreateCompany')
)
const User = lazy(() => import('screens/CompanyManagement/User'))

const AdminPanel = lazy(() => import('screens/AdminPanel'))

const Reports = lazy(() => import('screens/QualityControl/Reports'))
const Report = lazy(() => import('screens/QualityControl/Reports/Report'))
const CreateReport = lazy(() =>
  import('screens/QualityControl/Reports/CreateReport')
)

const Orders = lazy(() => import('screens/QualityControl/Orders'))
const CreateOrder = lazy(() =>
  import('screens/QualityControl/Orders/CreateOrder')
)
const Order = lazy(() => import('screens/QualityControl/Orders/Order'))

const Templates = lazy(() => import('screens/QualityControl/Templates'))
const CreateTemplate = lazy(() =>
  import('screens/QualityControl/Templates/CreateTemplate')
)
const Template = lazy(() => import('screens/QualityControl/Templates/Template'))

const AppRoutes = () => {
  const { isAdmin } = useSelector(authSelectors.getAuthInfo)

  return (
    <Router>
      <Routes>
        <Route
          path={routes.home}
          element={
            <PrivateRoute>
              <MainWrapper />
            </PrivateRoute>
          }
        >
          {/* <Route path={routes.dashboard} element={<Dashboard />} /> */}
          {isAdmin ? (
            <Route path={routes.companyManagement.root}>
              <Route index element={<CompanyManagement />} />
              <Route
                path={routes.companyManagement.createCompany}
                element={<CreateCompany />}
              />
              <Route path={routes.companyManagement.company}>
                <Route index element={<Company />} />
                <Route
                  path={routes.companyManagement.user}
                  element={<User />}
                />
              </Route>
            </Route>
          ) : (
            <Route path={routes.companyManagement.myCompany}>
              <Route index element={<Company />} />
              <Route path={routes.companyManagement.user} element={<User />} />
            </Route>
          )}
          <Route path={routes.productLibrary.root}>
            <Route index element={<ProductLibrary />} />
            <Route
              path={routes.productLibrary.createCollection}
              element={<CreateCollection />}
            />
            <Route path={routes.productLibrary.collection}>
              <Route index element={<Collection />} />
              <Route
                path={routes.productLibrary.product}
                element={<Product />}
              />
              <Route
                path={routes.productLibrary.createProduct}
                element={<CreateProduct />}
              />
            </Route>
          </Route>
          <Route path={routes.quality_control.orders.root}>
            <Route index element={<Orders />} />
            <Route
              path={routes.quality_control.orders.createOrder}
              element={<CreateOrder />}
            />
            <Route path={routes.quality_control.orders.order}>
              <Route index element={<Order />} />
            </Route>
          </Route>
          <Route path={routes.quality_control.reports.root}>
            <Route index element={<Reports />} />
            <Route
              path={routes.quality_control.reports.createReport}
              element={<CreateReport />}
            />
            <Route path={routes.quality_control.reports.report}>
              <Route index element={<Report />} />
            </Route>
          </Route>
          <Route path={routes.quality_control.templates.root}>
            <Route index element={<Templates />} />
            <Route
              path={routes.quality_control.templates.createTemplage}
              element={<CreateTemplate />}
            />
            <Route path={routes.quality_control.templates.template}>
              <Route index element={<Template />} />
            </Route>
          </Route>
          <Route path={routes.adminPanel.root} element={<AdminPanel />} />
        </Route>
        <Route
          path={routes.setPassword}
          element={
            <PublicRoute>
              <SetPassword />
            </PublicRoute>
          }
        />
        <Route
          path={routes.login}
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route path={'*'} element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  )
}

export default AppRoutes
