import React from 'react'
import Switch from 'react-switch'
import PropTypes from 'prop-types'

const Toggle = ({ onChange, isChecked }) => {
  return (
    <Switch
      offColor="#CFD4D9"
      onColor="#000092"
      onChange={onChange}
      checked={isChecked}
      uncheckedIcon={false}
      checkedIcon={false}
      width={48}
      height={24}
      handleDiameter={18}
      activeBoxShadow="none"
    />
  )
}

Toggle.propTypes = {
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
}

export default Toggle
