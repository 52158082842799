import { toast } from 'react-toastify'

import languages from 'services/globalAttributes/languages'
import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import { clearParams } from 'utils/helpers'
import i18n from 'translations'

const fetchLanguages =
  ({ params }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_LANGUAGES) })
    clearParams({ params })
    languages
      .getLanguages({ params })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.GET_LANGUAGES),
          payload: res.data,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_LANGUAGES),
          payload: err.response.data.message,
        })
      )
  }

const createLanguage =
  ({ data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.CREATE_LANGUAGE) })
    return languages
      .createLanguage({ data })
      .then((res) => {
        toast.success(
          `${i18n.t('attributes:languages:single_name')} ${i18n.t(
            'common:create_success'
          )}`
        )
        dispatch({
          type: SUCCESS(types.CREATE_LANGUAGE),
          payload: res.data,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.CREATE_LANGUAGE),
          payload: err.response.message,
        })
      )
  }

const deleteLanguage =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_LANGUAGE) })
    return languages
      .deleteLanguage({ id })
      .then(() => {
        toast.success(
          `${i18n.t('attributes:languages:single_name')} ${i18n.t(
            'common:delete_success'
          )}`
        )
        dispatch({
          type: SUCCESS(types.DELETE_LANGUAGE),
          payload: id,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.DELETE_LANGUAGE),
          payload: err.response.message,
        })
      )
  }

const editLanguage =
  ({ data, id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.EDIT_LANGUAGE) })
    return languages
      .editLanguage({ data, id })
      .then((res) => {
        toast.success(
          `${i18n.t('attributes:languages:single_name')} ${i18n.t(
            'common:edit_success'
          )}`
        )
        dispatch({
          type: SUCCESS(types.EDIT_LANGUAGE),
          payload: res.data,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.EDIT_LANGUAGE),
          payload: err.response.message,
        })
      )
  }

const clearLanguages = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_LANGUAGES) })

export default {
  fetchLanguages,
  createLanguage,
  deleteLanguage,
  editLanguage,
  clearLanguages,
}
