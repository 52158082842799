import { requests } from './api'

const initialEndpoint = 'stone-certificates'

const getStoneCertificates = ({ params }) =>
  requests.get({ url: initialEndpoint, params }).then((res) => res.data)

export default {
  getStoneCertificates,
}
