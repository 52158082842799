import { useState, useEffect } from 'react'
import { MIN_SEARCH_LENGTH } from 'constants/configs'

const useDebounce = (value, delay = 500, minCharacters = MIN_SEARCH_LENGTH) => {
  const [debValue, setDebValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      if (value.length >= minCharacters || !value.length) {
        setDebValue(value)
      }
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay, minCharacters])

  return debValue
}

export default useDebounce
