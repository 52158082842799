import users from 'services/users'
import permissions from 'services/permissions'
import types from './actionTypes'
import { authTypes } from '../auth'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import { isFieldsEqual } from 'utils/helpers'

const createUser =
  ({ data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.CREATE_USER) })
    return users
      .createUser({ data })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.CREATE_USER),
          payload: res.data,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.CREATE_USER),
          payload: err.response.message,
        })
        throw err
      })
  }

const fetchUser =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_USER) })
    return users
      .getUser({ id })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.GET_USER),
          payload: res.data,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.GET_USER),
          payload: err.response.message,
        })
        throw err
      })
  }

const changeUserInfo =
  ({ data, user, isProfile }) =>
  (dispatch) => {
    const { profile, ...userData } = data

    const isEqual = isFieldsEqual(userData, user)

    if (profile && profile?.preview) {
      dispatch(
        uploadUserFiles({
          data: [{ file: profile, type: 'profile' }],
        })
      )
    }

    if (!isEqual) {
      dispatch(updateUser({ id: user.uuid, data: userData, isProfile }))
    }
  }

const updateUser =
  ({ data, id, isProfile = false }) =>
  (dispatch) => {
    const activeType = isProfile
      ? authTypes.UPDATE_ACTIVE_USER
      : types.UPDATE_USER
    dispatch({ type: PENDING(activeType) })
    return users
      .updateUser({ data, id })
      .then((res) => {
        dispatch({
          type: SUCCESS(activeType),
          payload: res.data,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(activeType),
          payload: err.response.message,
        })
        throw err
      })
  }

const uploadUserFiles =
  ({ data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.UPLOAD_USER_FILES) })
    return users
      .uploadUserFiles({ data })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.UPLOAD_USER_FILES),
          payload: res.data,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.UPLOAD_USER_FILES),
          payload: err.response.message,
        })
        throw err
      })
  }

const deleteUser =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_USER) })
    return users
      .deleteUser({ id })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.DELETE_USER),
          payload: res.data,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.DELETE_USER),
          payload: err.response.message,
        })
        throw err
      })
  }

const getUserPermissions =
  ({ id, activeTool }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_USER_PERMISSIONS) })

    return users
      .getUserPermissions({ id, params: { tools: [activeTool] } })
      .then((res) => {
        return dispatch({
          type: SUCCESS(types.GET_USER_PERMISSIONS),
          payload: { data: res.data, activeTool },
        })
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.GET_USER_PERMISSIONS),
          payload: err.response.message.data,
        })
      })
  }

const addUserPermissions =
  ({ userId, data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.ADD_USER_PERMISSIONS) })

    return permissions
      .addUserPermissions({ userId, data })
      .then((res) => {
        return dispatch({
          type: SUCCESS(types.ADD_USER_PERMISSIONS),
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.ADD_USER_PERMISSIONS),
          payload: err.response.message.data,
        })
      })
  }

const removeUserPermissions =
  ({ userId, data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.REMOVE_USER_PERMISSIONS) })

    return permissions
      .removeUserPermissions({ userId, data })
      .then((res) => {
        return dispatch({
          type: SUCCESS(types.REMOVE_USER_PERMISSIONS),
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.REMOVE_USER_PERMISSIONS),
          payload: err.response.message.data,
        })
      })
  }

const clearUser = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_USER) })

export default {
  createUser,
  fetchUser,
  deleteUser,
  updateUser,
  uploadUserFiles,
  changeUserInfo,
  getUserPermissions,
  addUserPermissions,
  removeUserPermissions,
  clearUser,
}
