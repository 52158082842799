const GET_REGIONS = 'GET_REGIONS'
const GET_MORE_REGIONS = 'GET_MORE_REGIONS'
const CREATE_REGION = 'CREATE_REGION'
const EDIT_REGION = 'EDIT_REGION'
const DELETE_REGION = 'DELETE_REGION'

const CLEAR_REGIONS = 'CLEAR_REGIONS'

export default {
  GET_REGIONS,
  CREATE_REGION,
  EDIT_REGION,
  DELETE_REGION,
  CLEAR_REGIONS,
  GET_MORE_REGIONS
}
