import { requests, formDataHeaders } from './api'
import { objectsToFormData } from 'utils/serializer'

const initialEndpoint = 'users'

const createUser = ({ data }) => requests.post({ url: initialEndpoint, data })

const getUser = ({ id }) =>
  requests.get({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)

const deleteUser = ({ id }) =>
  requests.delete({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)

const updateUser = ({ id, data }) =>
  requests
    .put({ url: `${initialEndpoint}/${id}`, data })
    .then((res) => res.data)

const uploadUserFiles = ({ data }) => {
  return requests
    .post({
      url: `${initialEndpoint}/files/upload`,
      data: objectsToFormData(data),
      headers: formDataHeaders,
    })
    .then((res) => res.data)
}

const getUserPermissions = ({ id, params = null }) =>
  requests
    .get({ url: `${initialEndpoint}/${id}/permissions`, params })
    .then((res) => res.data)

const setFirstPassword = ({ link, data }) =>
  requests
    .patch({ url: `${initialEndpoint}/set-password/${link}`, data })
    .then((res) => res.data)

export default {
  createUser,
  getUser,
  deleteUser,
  updateUser,
  uploadUserFiles,
  getUserPermissions,
  setFirstPassword,
}
