export default {
  new_user: 'New User',
  empty_description: 'There are no users under this category right now',
  add_new_user: 'Add New User',
  create_user: 'Create User',
  nameLabel: 'First Name',
  nameHolder: 'User First Name',
  lastnameLabel: 'Last Name',
  lastnameHolder: 'User Last Name',
  emailHolder: 'User Email',
  user_info: 'User Information',
  add_photo: 'Add Photo',
  birth_date_label: 'Date of Birth',
  birth_date_holder: 'Select Date of Birth',
  gender: 'Gender',
  delete_title: 'You are about to delete the user',
  delete_description: 'This will delete the user from our system',
  delete_profile_title: 'You are about to delete your account',
  delete_profile_description: 'This will delete your account from our system',
  permission_title: 'User Permissions Settings',
}
