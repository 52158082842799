import React from 'react'
import PropTypes from 'prop-types'

import EmptyBlock from '../EmptyBlock'
import Pagination from 'components/Pagination'

import styles from './CommonList.module.scss'

const CommonList = ({
  customStyle = '',
  search = '',
  data = [],
  isLoading = false,
  onAdd = null,
  addTitle = '',
  addDescription,
  disabled = false,
  activePage = 1,
  handlePage = () => null,
  totalPages = 0,
  children,
}) => {
  if (search && !data.length && !isLoading) {
    return <EmptyBlock />
  }

  if (!isLoading && !data.length) {
    return (
      <EmptyBlock
        onAdd={disabled || !onAdd ? null : onAdd}
        addText={addTitle}
        description={addDescription}
      />
    )
  }

  return (
    <>
      <div className={`${styles.commonList} ${customStyle}`}>{children}</div>
      <Pagination
        activePage={activePage}
        onClick={handlePage}
        totalPages={totalPages}
        isLoading={isLoading}
      />
    </>
  )
}

CommonList.propTypes = {
  customStyle: PropTypes.string,
  search: PropTypes.string,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  onAdd: PropTypes.func,
  addTitle: PropTypes.string,
  addDescription: PropTypes.string,
  handlePage: PropTypes.func,
  activePage: PropTypes.number,
  totalPages: PropTypes.number,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default CommonList
