export default {
  productLibrary: 'Product Library',
  dashboard: 'Dashboard',
  adminPanel: 'Admin Panel',
  companyManagement: 'Company Management',
  myCompany: 'My Company',
  quality_control: {
    name: 'Quality Control',
    orders: 'Orders',
    reports: 'Reports',
    templates: 'Templates',
  },
}
