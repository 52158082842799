import { toast } from 'react-toastify'
import i18n from 'translations'

const errorHandler = (error) => {
  const { status, data } = error.response || {}
  const { t } = i18n

  if (status === 500) {
    toast.error(t('errors:server_error'))
    return false
  }

  if (status === 404) {
    toast.error(t('errors:item_not_found'))
    return false
  }

  if (data?.message) {
    toast.error(data?.message)
    return false
  }

  return false
}

export default errorHandler
