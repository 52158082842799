import { toast } from 'react-toastify'

import variants from 'services/variants'
import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import { clearParams, handleBaseInfo } from 'utils/helpers'

import i18n from 'translations'

const uploadVariantFiles =
  ({ data, productId, variantId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.UPLOAD_VARIANT_FILES) })
    return variants
      .uploadVariantFiles({ data, productId, variantId })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.UPLOAD_VARIANT_FILES),
          payload: res.data,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.UPLOAD_VARIANT_FILES),
          payload: err.response.message,
        })
        throw new Error(err)
      })
  }

const createVariant =
  ({ data, productId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.CREATE_VARIANT) })
    return variants
      .createVariant({ data: data.creation, productId })
      .then((res) => {
        toast.success(
          `${i18n.t('product:variant')} ${i18n.t('common:create_success')}`
        )
        dispatch({
          type: SUCCESS(types.CREATE_VARIANT),
          payload: res.data,
        })
        if (data.files.length) {
          dispatch(
            uploadVariantFiles({
              data: data.files,
              productId,
              variantId: res.data.data.uuid,
            })
          )
        }
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.CREATE_VARIANT),
          payload: err.response.message,
        })
      )
  }

const deleteVariantFiles =
  ({ data, productId, variantId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_VARIANT_FILES) })
    return variants
      .deleteVariantFiles({ data, productId, variantId })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.DELETE_VARIANT_FILES),
          payload: data,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.DELETE_VARIANT_FILES),
          payload: err.response.message,
        })
        throw new Error(err)
      })
  }

const getVariantContent =
  ({ params, productId, variantId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_VARIANT_CONTENT) })
    variants
      .getVariantContent({ productId, params, variantId })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.GET_VARIANT_CONTENT),
          payload: res.data,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_VARIANT_CONTENT),
          payload: err.response.message,
        })
      )
  }

const deleteVariantContent =
  ({ productId, variantId, langId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_VARIANT_CONTENT) })
    return variants
      .deleteVariantContent({ productId, variantId, langId })
      .then(() => {
        toast.success(i18n.t('common:content_removed_successfully'))
        dispatch({
          type: SUCCESS(types.DELETE_VARIANT_CONTENT),
          payload: langId,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.DELETE_VARIANT_CONTENT),
          payload: err.response.message,
        })
      )
  }

const addVariantContent =
  ({ productId, data, variantId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.ADD_VARIANT_CONTENT) })
    variants
      .addVariantContent({ productId, data, variantId })
      .then((res) => {
        toast.success(i18n.t('common:content_added_successfully'))
        dispatch({
          type: SUCCESS(types.ADD_VARIANT_CONTENT),
          payload: res.data,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.ADD_VARIANT_CONTENT),
          payload: err.response.message,
        })
      )
  }

const updateVariantContent =
  ({ productId, data, langId, variantId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.UPDATE_VARIANT_CONTENT) })
    variants
      .updateVariantContent({ productId, data, langId, variantId })
      .then((res) => {
        toast.success(i18n.t('common:content_changed_successfully'))
        dispatch({
          type: SUCCESS(types.UPDATE_VARIANT_CONTENT),
          payload: res.data,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.UPDATE_VARIANT_CONTENT),
          payload: err.response.message,
        })
      )
  }

const changeBaseInfo =
  ({ data, productId, variant }) =>
  (dispatch) =>
    handleBaseInfo({
      data,
      item: variant,
      requests: {
        uploadFiles: (files) =>
          dispatch(
            uploadVariantFiles({
              data: files,
              productId,
              variantId: variant.uuid,
            })
          ),
        deleteFiles: (ids) =>
          dispatch(
            deleteVariantFiles({
              data: ids,
              productId,
              variantId: variant.uuid,
            })
          ),
        addContent: (content) =>
          dispatch(
            addVariantContent({
              productId,
              variantId: variant.uuid,
              data: content,
            })
          ),
        updateContent: (content) =>
          dispatch(
            updateVariantContent({
              productId,
              variantId: variant.uuid,
              data: content,
              langId: data?.language_id,
            })
          ),
      },
    })

const fetchVariants =
  ({ params, productId, isPagination = false }) =>
  (dispatch) => {
    const activeType = isPagination
      ? types.GET_MORE_VARIANTS
      : types.GET_VARIANTS
    dispatch({ type: PENDING(activeType) })
    clearParams({ params })
    variants
      .getVariants({ params, productId })
      .then((res) =>
        dispatch({
          type: SUCCESS(activeType),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(activeType),
          payload: err.response.message,
        })
      )
  }

const fetchVariant =
  ({ productId, variantId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_VARIANT) })
    return variants
      .getVariant({ variantId, productId })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.GET_VARIANT),
          payload: res.data,
        })
        return res.data
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_VARIANT),
          payload: err.response.message,
        })
      )
  }

const deleteVariant =
  ({ productId, variantId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_VARIANT) })
    return variants
      .deleteVariant({ variantId, productId })
      .then((res) => {
        toast.success(
          `${i18n.t('product:variant')} ${i18n.t('common:delete_success')}`
        )
        dispatch({
          type: SUCCESS(types.DELETE_VARIANT),
          payload: { data: res.data, variantId: variantId },
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.DELETE_VARIANT),
          payload: err.response.message,
        })
        throw err
      })
  }

const handleActiveVariant =
  ({ variant }) =>
  (dispatch) =>
    dispatch({ type: SUCCESS(types.CHANGE_ACTIVE_VARIANT), payload: variant })

const clearVariants = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_VARIANTS) })

export default {
  createVariant,
  fetchVariants,
  fetchVariant,
  deleteVariant,
  changeBaseInfo,
  getVariantContent,
  deleteVariantContent,
  updateVariantContent,
  addVariantContent,
  handleActiveVariant,
  clearVariants,
}
