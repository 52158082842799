import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const initialState = {
  countries: {
    items: [],
    loading: true,
    moreLoading: false,
    error: '',
    totalPages: 0,
  },
}

const countryReducer = (state = initialState, { payload, type }) => {
  let index
  switch (type) {
    case PENDING(types.GET_COUNTRIES):
      return {
        ...state,
        countries: {
          ...state.countries,
          loading: true,
        },
      }
    case SUCCESS(types.GET_COUNTRIES):
      return {
        ...state,
        countries: {
          ...state.countries,
          items: payload.data,
          totalPages: payload.meta.last_page,
          loading: false,
        },
      }
    case FAILURE(types.GET_COUNTRIES):
      return {
        ...state,
        countries: {
          ...state.countries,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_MORE_COUNTRIES):
      return {
        ...state,
        countries: {
          ...state.countries,
          moreLoading: true,
        },
      }
    case SUCCESS(types.GET_MORE_COUNTRIES):
      return {
        ...state,
        countries: {
          ...state.countries,
          items: [...state.countries.items, ...payload.data],
          moreLoading: false,
        },
      }
    case FAILURE(types.GET_MORE_COUNTRIES):
      return {
        ...state,
        countries: {
          ...state.countries,
          moreLoading: false,
          error: payload,
        },
      }
    case SUCCESS(types.CREATE_COUNTRY):
      return {
        ...state,
        countries: {
          ...state.countries,
          items: [...state.countries.items, payload.data],
        },
      }
    case SUCCESS(types.EDIT_COUNTRY):
      const newItems = state.countries.items
      index = newItems.findIndex((el) => el.uuid === payload.data.uuid)
      newItems[index] = payload.data
      return {
        ...state,
        countries: {
          ...state.countries,
          items: [...newItems],
        },
      }
    case SUCCESS(types.DELETE_COUNTRY):
      index = state.countries.items.findIndex((el) => el.uuid === payload)
      return {
        ...state,
        countries: {
          ...state.countries,
          items: [
            ...state.countries.items.slice(0, index),
            ...state.countries.items.slice(index + 1),
          ],
        },
      }
    case SUCCESS(types.CLEAR_COUNTRIES):
      return {
        ...state,
        countries: {
          items: [],
          loading: false,
          moreLoading: false,
          error: '',
          totalPages: 0,
        },
      }
    default:
      return state
  }
}

export default countryReducer
