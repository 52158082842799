import auth from 'services/auth'
import permissionsGroups from 'services/permissionsGroups'
import users from 'services/users'

import { authActions } from '.'
import { languageActions } from '../globalAttributes/language'

import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const getActiveUser = () => (dispatch) => {
  dispatch({ type: PENDING(types.GET_ACTIVE_USER) })
  auth
    .getUserInfo()
    .then((res) =>
      dispatch({ type: SUCCESS(types.GET_ACTIVE_USER), payload: res.data })
    )
    .catch(() => dispatch({ type: FAILURE(types.GET_ACTIVE_USER) }))
}

const login = (data) => (dispatch) => {
  dispatch({ type: PENDING(types.LOGIN) })

  auth
    .userLogin({ data })
    .then((res) => {
      dispatch({ type: SUCCESS(types.LOGIN), payload: res.data })
      dispatch(authActions.getActiveUser())
      dispatch(languageActions.fetchLanguages({}))
      dispatch(authActions.fetchMyTools())
    })
    .catch((err) => {
      dispatch({ type: FAILURE(types.LOGIN), payload: err.response.data })
    })
}

const logout = () => (dispatch) => {
  dispatch({ type: PENDING(types.LOGOUT) })

  auth
    .logout()
    .then(() => dispatch({ type: SUCCESS(types.LOGOUT) }))
    .catch(() => dispatch({ type: FAILURE(types.LOGOUT) }))
}

const forceLogout = () => (dispatch) => {
  dispatch({ type: SUCCESS(types.LOGOUT) })
}

const tokenRefresh =
  ({ data }) =>
  (dispatch) =>
    dispatch({ type: SUCCESS(types.REFRESH_TOKEN), payload: data })

const resetPassword = (data) => (dispatch) => {
  dispatch({ type: PENDING(types.RESET_PASSWORD) })

  return auth
    .resetPassword({ data })
    .then((res) => {
      return dispatch({
        type: SUCCESS(types.RESET_PASSWORD),
        payload: res.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: FAILURE(types.RESET_PASSWORD),
        payload: err.response.data,
      })
    })
}

const setPassword =
  ({ data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.SET_PASSWORD) })

    return auth
      .setPassword({ data })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.SET_PASSWORD),
          payload: res.data,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.SET_PASSWORD),
          payload: err,
        })
        throw err
      })
  }

const setFirstPassword =
  ({ data, link }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.SET_PASSWORD) })

    return users
      .setFirstPassword({ data, link })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.SET_PASSWORD),
          payload: res.data,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.SET_PASSWORD),
          payload: err,
        })
        throw err
      })
  }

const fetchMyTools = () => (dispatch) => {
  dispatch({ type: PENDING(types.GET_MY_TOOLS) })
  permissionsGroups
    .getTools()
    .then((res) =>
      dispatch({
        type: SUCCESS(types.GET_MY_TOOLS),
        payload: res.data,
      })
    )
    .catch(() =>
      dispatch({
        type: FAILURE(types.GET_MY_TOOLS),
      })
    )
}

const fetchMyPermissions =
  ({ params, id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_MY_PERMISSIONS) })
    users
      .getUserPermissions({ params, id })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_MY_PERMISSIONS),
          payload: res.data,
        })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_MY_PERMISSIONS),
        })
      )
  }

const fetchMyPermissionsMock = () => (dispatch) => {
  dispatch({
    type: SUCCESS(types.GET_MY_PERMISSIONS),
    payload: null,
  })
}

export default {
  login,
  getActiveUser,
  logout,
  forceLogout,
  tokenRefresh,
  resetPassword,
  setPassword,
  setFirstPassword,
  fetchMyTools,
  fetchMyPermissions,
  fetchMyPermissionsMock,
}
