import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router'

import { commonSelectors } from 'store/ducks/common'

import { apiTabs, getSearchCardProps } from '..'

import CommonButton from 'components/common/CommonButton'
import CommonList from 'components/common/CommonList'
import SearchCard from '../SearchCard'

import styles from './SearchPopup.module.scss'

const limit = 4

const SearchPopup = ({ handlePopup }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { items: results, loading } = useSelector(
    commonSelectors.getGlobalSearchInfo
  )

  const [activeTab, setActiveTab] = useState(apiTabs.collections)
  const [page, setPage] = useState(1)

  const tabs = useMemo(
    () => ({
      [apiTabs.collections]: t('productLibrary:collections'),
      [apiTabs.instances]: t('productLibrary:instances'),
      [apiTabs.references]: t('productLibrary:references'),
      [apiTabs.variants]: t('product:variants'),
    }),
    [t]
  )

  const handleTab = useCallback((newTab) => {
    setPage(1)
    setActiveTab(newTab)
  }, [])

  const handlePage = useCallback((index) => {
    setPage(index)
  }, [])

  const handleCard = useCallback(
    (link) => {
      handlePopup(false)
      navigate(link)
    },
    [navigate, handlePopup]
  )

  return (
    <div className={styles.results}>
      <div className={styles.results_tabs}>
        {loading
          ? Object.keys(tabs).map((el) => (
              <div
                key={el}
                className={`${styles.results_tabs_button} ${styles.results_tabs_skeleton}`}
              >
                <Skeleton height={40} />
              </div>
            ))
          : results
          ? Object.entries(results).map(([key, value]) =>
              tabs[key] ? (
                <CommonButton
                  name={`${tabs[key]} ${value?.length || 0}`}
                  key={key}
                  customStyle={styles.results_tabs_button}
                  onClick={() => handleTab(key)}
                  isActive={activeTab === key}
                />
              ) : null
            )
          : null}
      </div>
      <div className={styles.results_cards}>
        <CommonList
          data={results ? results[activeTab] : []}
          isLoading={loading}
          activePage={page}
          handlePage={handlePage}
          totalPages={
            results ? Math.ceil(results[activeTab]?.length / limit) : 1
          }
        >
          {loading
            ? [...Array(limit).keys()].map((el) => (
                <div key={el} className={styles.results_cards_item}>
                  <Skeleton height={80} />
                </div>
              ))
            : results
            ? results[activeTab]
                .slice((page - 1) * limit, page * limit)
                ?.map((item) => (
                  <div key={item.uuid} className={styles.results_cards_item}>
                    <SearchCard
                      {...getSearchCardProps(item, activeTab)}
                      handleClick={handleCard}
                    />
                  </div>
                ))
            : null}
        </CommonList>
      </div>
    </div>
  )
}

SearchPopup.propTypes = {
  handlePopup: PropTypes.func.isRequired,
}

export default SearchPopup
