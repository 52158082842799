const GET_PERMISSIONS_GROUPS_TOOLS = 'GET_PERMISSIONS_GROUPS_TOOLS'

const GET_PERMISSIONS_GROUPS_TOOLS_MATRIX =
  'GET_PERMISSIONS_GROUPS_TOOLS_MATRIX'

const CLEAR_PERMISSIONS_GROUPS = 'CLEAR_PERMISSIONS_GROUPS'

export default {
  GET_PERMISSIONS_GROUPS_TOOLS,
  GET_PERMISSIONS_GROUPS_TOOLS_MATRIX,
  CLEAR_PERMISSIONS_GROUPS,
}
