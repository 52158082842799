const getUserToken = (state) => state.auth.token

const getMyToolsInfo = (state) => state.auth.myTools

const getMyPermissions = (state) => state.auth.myPermissions

const getAuthInfo = (state) => state.auth.info

export default {
  getUserToken,
  getMyToolsInfo,
  getMyPermissions,
  getAuthInfo,
}
