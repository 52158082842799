import { toast } from 'react-toastify'

import collection from 'services/collections'
import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import { clearParams, handleBaseInfo } from 'utils/helpers'
import i18n from 'translations'

const fetchCollectionById =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_COLLECTION_BY_ID) })
    collection
      .getCollectionById({ id })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_COLLECTION_BY_ID),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_COLLECTION_BY_ID),
          payload: err.response.message,
        })
      )
  }

const addCollectionContent =
  ({ collectionId, data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.ADD_COLLECTION_CONTENT) })
    collection
      .addCollectionContent({ id: collectionId, data })
      .then((res) => {
        toast.success(i18n.t('common:content_added_successfully'))
        dispatch({
          type: SUCCESS(types.ADD_COLLECTION_CONTENT),
          payload: res.data,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.ADD_COLLECTION_CONTENT),
          payload: err.response.message,
        })
      )
  }

const updateCollectionContent =
  ({ collectionId, data, langId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.UPDATE_COLLECTION_CONTENT) })
    collection
      .updateCollectionContent({ id: collectionId, data, langId })
      .then((res) => {
        toast.success(
          `${i18n.t('collection:collection')} ${i18n.t('common:edit_success')}`
        )
        dispatch({
          type: SUCCESS(types.UPDATE_COLLECTION_CONTENT),
          payload: res.data,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.UPDATE_COLLECTION_CONTENT),
          payload: err.response.message,
        })
      )
  }

const getCollectionContent =
  ({ collectionId, params }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_COLLECTION_CONTENT) })
    collection
      .getCollectionContent({ id: collectionId, params })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.GET_COLLECTION_CONTENT),
          payload: res.data,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_COLLECTION_CONTENT),
          payload: err.response.message,
        })
      )
  }

const deleteCollectionContent =
  ({ collectionId, langId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_COLLECTION_CONTENT) })
    return collection
      .deleteCollectionContent({ id: collectionId, langId })
      .then((res) => {
        toast.success(i18n.t('common:content_removed_successfully'))
        dispatch({
          type: SUCCESS(types.DELETE_COLLECTION_CONTENT),
          payload: langId,
        })

        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.DELETE_COLLECTION_CONTENT),
          payload: err.response.message,
        })

        return err
      })
  }

const changeBaseInfo =
  ({ data, collection }) =>
  (dispatch) =>
    handleBaseInfo({
      data,
      item: collection,
      requests: {
        addContent: (content) =>
          dispatch(
            addCollectionContent({
              collectionId: collection.uuid,
              data: content,
            })
          ),
        updateContent: (content) =>
          dispatch(
            updateCollectionContent({
              collectionId: collection.uuid,
              data: content,
              langId: data?.language_id,
            })
          ),
      },
    })

const fetchCollections =
  ({ params, isPaginate = false }) =>
  (dispatch) => {
    const activeType = isPaginate
      ? types.GET_MORE_COLLECTIONS
      : types.GET_COLLECTIONS
    dispatch({ type: PENDING(activeType) })
    clearParams({ params })
    collection
      .getCollections({ params })
      .then((res) =>
        dispatch({
          type: SUCCESS(activeType),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(activeType),
          payload: err.response.message,
        })
      )
  }

const createCollection =
  ({ data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.CREATE_COLLECTION) })
    return collection
      .createCollection({ data })
      .then((res) => {
        toast.success(
          `${i18n.t('collection:collection')} ${i18n.t(
            'common:create_success'
          )}`
        )
        dispatch({
          type: SUCCESS(types.CREATE_COLLECTION),
          payload: res.data,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.CREATE_COLLECTION),
          payload: err.response.data.message,
        })
        throw err
      })
  }

const deleteCollection =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_COLLECTION) })
    return collection
      .deleteCollection({ id })
      .then((res) => {
        toast.success(
          `${i18n.t('collection:collection')} ${i18n.t(
            'common:delete_success'
          )}`
        )
        dispatch({
          type: SUCCESS(types.DELETE_COLLECTION),
          payload: id,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.DELETE_COLLECTION),
          payload: err,
        })
        throw err
      })
  }

const fetchSharedCollections =
  ({ params }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_SHARED_COLLECTIONS) })
    clearParams({ params })
    collection
      .getCollections({ params })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_SHARED_COLLECTIONS),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_SHARED_COLLECTIONS),
          payload: err.response.message,
        })
      )
  }

const shareCollection =
  ({ id, params }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.SHARE_COLLECTION) })
    clearParams({ params })
    collection
      .shareCollection({ params, id })
      .then((res) => {
        toast.success(
          `${i18n.t('collection:collection')} ${i18n.t(
            'common:shared_successfully'
          )}`
        )
        dispatch({
          type: SUCCESS(types.SHARE_COLLECTION),
          payload: res.data,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.SHARE_COLLECTION),
          payload: err.response.message,
        })
      )
  }

const unshareCollection =
  ({ id, params }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.UNSHARE_COLLECTION) })
    clearParams({ params })
    collection
      .unshareCollection({ params, id })
      .then((res) => {
        toast.success(
          `${i18n.t('collection:collection')} ${i18n.t(
            'common:unshared_successfully'
          )}`
        )
        dispatch({
          type: SUCCESS(types.UNSHARE_COLLECTION),
          payload: { data: res.data, companyIds: params.company_ids },
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.UNSHARE_COLLECTION),
          payload: err.response.message,
        })
      )
  }

const clearCollection = () => (dispatch) => {
  dispatch({ type: SUCCESS(types.CLEAR_COLLECTION) })
}

const clearCollections = () => (dispatch) => {
  dispatch({ type: SUCCESS(types.CLEAR_COLLECTIONS) })
}

export default {
  fetchCollectionById,
  fetchCollections,
  fetchSharedCollections,
  clearCollection,
  shareCollection,
  unshareCollection,
  getCollectionContent,
  deleteCollectionContent,
  deleteCollection,
  changeBaseInfo,
  createCollection,
  clearCollections,
}
