export default {
  login: 'Login',
  signIn: 'Sign in',
  password: 'Password',
  password_confirmation: 'Confirmation Password',
  email: 'Email',
  forgotPass: 'Forgot password',
  submit: 'Submit',
  search: 'Search',
  global_search: 'Global Search',
  products: 'Products',
  smallProducts: 'products',
  my: 'My',
  loading: 'loading',
  all: 'All',
  created: 'Created',
  accessSet: 'Access Settings',
  companyName: 'Company Name',
  date: 'Date',
  prev: 'Prev',
  next: 'Next',
  add: 'Add',
  close: 'Close',
  remove: 'Remove',
  notFoundTitle: 'No items found',
  notFoundDesc: 'We can’t find any item matching your search',
  profile: 'Profile',
  darkMode: 'Dark Mode',
  logout: 'Logout',
  cancel: 'Cancel',
  delete: 'Delete',
  type: 'Type',
  empty: 'It’s empty',
  resetPass: 'Reset Password',
  enterEmail: 'Enter your email',
  addFile: 'Add File',
  addFiles: 'Add Files',
  owner: 'Owner',
  edit: 'Edit',
  saveChanges: 'Save Changes',
  save: 'Save',
  changes: 'Changes',
  reference: 'Reference',
  select: 'Select',
  removeAccessTitle: 'You are about to remove the company an access',
  removeAccessDesc: 'This will remove an access the company to your',
  areYouSure: 'Are you sure?',
  files: 'Files',
  file: 'File',
  showMore: 'Show more',
  clearFilters: 'Clear Filters',
  description: 'Description',
  addNewLanguage: 'Add New Language',
  deleteLanguage: 'Delete Language',
  deleteLangTitle: 'You are about to delete a translation',
  deleteLangDesc: 'This will delete a translation for your item',
  content_added_successfully: 'Translation added successfully',
  content_removed_successfully: 'Translation removed successfully',
  content_changed_successfully: 'Translation changed successfully',
  users: 'Users',
  country: 'Country',
  email_holder: 'Enter Email',
  phone_holder: 'Enter Phone number',
  email_label: 'Email',
  phone_label: 'Phone Number',
  add_logo: 'Add logo',
  main_contact: 'Main contact',
  commercial_info: 'Commercial Info',
  pick_date: 'Select date',
  address: 'Address',
  address_holder: 'Enter Address',
  city: 'City',
  city_holder: 'Enter City Name',
  zip_code: 'Zip Code',
  zip_code_holder: 'Enter Zip Code',
  name: 'Name',
  new: 'New',
  active: 'Active',
  region: 'Region',
  filters: 'Filters',
  creation_date: 'Creation date',
  status: 'Status',
  success: 'Success',
  create_success: 'created successfully',
  delete_success: 'deleted successfully',
  edit_success: 'edited successfully',
  approve_success: 'approved successfully',
  decline_success: 'declined successfully',
  upload_success: 'uploaded successfully',
  link: 'Link',
  enter: 'Enter',
  from: 'From',
  to: 'To',
  sort: 'Sort',
  by: 'by',
  view: 'View',
  hide: 'Hide',
  filter: 'Filter',
  quantity: 'Quantity',
  back: 'Back',
  proceed: 'Proceed',
  amount: 'Amount',
  sent_date: 'Sent date',
  comments: 'Comments',
  open_view: 'Open Detailed View',
  close_view: 'Close Detailed View',
  table_settings: 'Table Settings',
  table_hold_shift: 'Hold “Shift” to scroll table horizontally',
  show_all: 'Show all',
  edit_file: 'Edit file',
  draw: 'Draw',
  crop: 'Crop',
  delete_file_title: 'You are about to delete the file',
  delete_file_description: 'This will delete the file from the system',
  products_count: 'Products Count',
  create_new: 'Create new',
  create: 'Create',
  empty_data: 'Empty data',
  shared_successfully: 'shared successfully',
  unshared_successfully: 'unshared successfully',
  empty_files: 'No files',
  import_file: 'Import file',
  id: 'ID',
  yes: 'Yes',
  no: 'No',
  pending: 'Pending',
  errors: 'Errors',
  statuses: {
    derogated: 'Derogated',
    fail: 'Failed',
    pass: 'Passed',
    repaired: 'Repaired',
    unassigned: 'Unassigned',
  },
  fields: {
    field: 'Text Field',
    textarea: 'Text Area',
    dropdown: 'Dropdown',
    checkboxList: 'Checkbox List',
    datePicker: 'Date Picker',
  },
  sort_alphabet: 'Sort by alphabet',
}
