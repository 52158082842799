import React from 'react'
import PropTypes from 'prop-types'

import styles from './CommonButton.module.scss'

const CommonButton = ({
  name = '',
  type = 'button',
  Icon = null,
  isActive = false,
  customStyle = '',
  customActive = '',
  onClick = () => null,
  disabled = false,
}) => {
  return (
    <button
      type={type}
      className={`${styles.commonButton} ${
        isActive ? `${styles.activeButton} ${customActive}` : ''
      } ${customStyle ? customStyle : ''}`}
      onClick={(e) => onClick(e)}
      disabled={disabled}
    >
      {Icon
        ? Icon({
            className: `${styles.icon} ${isActive ? styles.activeIcon : ''}`,
          })
        : null}
      {name}
    </button>
  )
}

CommonButton.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  Icon: PropTypes.func,
  isActive: PropTypes.bool,
  customStyle: PropTypes.string,
  customActive: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default CommonButton
