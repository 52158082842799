import routes, { COLLECTION, PRODUCT } from 'constants/routes'

export const apiTabs = {
  collections: 'collections',
  instances: 'instances',
  references: 'references',
  variants: 'variants',
}

export const getSearchCardProps = (item, activeTab) => {
  switch (activeTab) {
    case apiTabs.collections:
      return {
        name: item?.content?.name,
        description: item?.content?.description,
        link: `${routes.productLibrary.root}/${COLLECTION}/${item?.uuid}`,
      }
    case apiTabs.instances:
      return {
        name: item?.product_variant?.content?.name,
        logo: item?.files?.length ? item?.files[0] : null,
        description: `${
          item?.product_variant?.collection?.content?.name
        } \n${item?.product_variant?.attributes.map(
          (el, index) =>
            `${index !== 0 ? ' ' : ''}${el.product_attribute.name}: ${el.value}`
        )}`,
        link: `${routes.productLibrary.root}/${COLLECTION}/${item?.product_variant?.collection?.uuid}/${PRODUCT}/${item?.product_variant?.product_id}?variant=${item?.product_variant?.uuid}&instance=${item?.uuid}`,
      }
    case apiTabs.references:
      return {
        name: item?.product_variant?.content?.name,
        logo: item?.files?.length ? item?.files[0] : null,
        description: `${
          item?.product_variant?.collection?.content?.name
        } \n${item?.product_variant?.attributes.map(
          (el, index) =>
            `${index !== 0 ? ' ' : ''}${el.product_attribute.name}: ${el.value}`
        )}`,
        link: `${routes.productLibrary.root}/${COLLECTION}/${item?.product_variant?.collection?.uuid}/${PRODUCT}/${item?.product_variant?.product_id}?variant=${item?.product_variant?.uuid}`,
      }
    default:
      return {
        name: item?.content?.name,
        logo: item?.files?.length ? item?.files[0] : null,
        description: `${
          item?.collection?.content?.name
        } \n${item?.attributes.map(
          (el, index) =>
            `${index !== 0 ? ' ' : ''}${el.product_attribute.name}: ${el.value}`
        )}`,
        link: `${routes.productLibrary.root}/${COLLECTION}/${item?.collection?.uuid}/${PRODUCT}/${item?.product_id}?variant=${item?.uuid}`,
      }
  }
}
