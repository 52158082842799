import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const initialState = {
  variants: {
    items: [],
    loading: true,
    moreLoading: false,
    error: '',
    totalPages: 0,
  },
  activeVariant: {
    item: null,
    isContentLoading: false,
    error: '',
  },
}

const productReducer = (state = initialState, { payload, type }) => {
  let activeIndex = 0
  switch (type) {
    case PENDING(types.CREATE_VARIANT):
      return {
        ...state,
        variants: {
          ...state.variants,
        },
      }
    case SUCCESS(types.CREATE_VARIANT):
      return {
        ...state,
        variants: {
          ...state.variants,
          items: [payload.data, ...state.variants.items],
          error: '',
        },
        activeVariant: {
          ...state.activeVariant,
          item: payload.data,
        },
      }
    case FAILURE(types.CREATE_VARIANT):
      return {
        ...state,
        variants: {
          ...state.variants,
          error: payload,
        },
      }
    case PENDING(types.DELETE_VARIANT):
      return {
        ...state,
        variants: {
          ...state.variants,
        },
      }
    case SUCCESS(types.DELETE_VARIANT):
      activeIndex = state.variants.items.findIndex(
        (el) => el.uuid === payload.variantId
      )
      const sortedArray = [
        ...state.variants.items.slice(0, activeIndex),
        ...state.variants.items.slice(activeIndex + 1),
      ]
      return {
        ...state,
        variants: {
          ...state.variants,
          items: sortedArray,
          error: '',
        },
        activeVariant: {
          ...state.activeVariant,
          item: sortedArray.length > 0 ? sortedArray[0] : null,
        },
      }
    case FAILURE(types.DELETE_VARIANT):
      return {
        ...state,
        variants: {
          ...state.variants,
          error: payload,
        },
      }
    case PENDING(types.GET_VARIANTS):
      return {
        ...state,
        variants: {
          ...state.variants,
          loading: true,
        },
      }
    case SUCCESS(types.GET_VARIANTS):
      return {
        ...state,
        variants: {
          ...state.variants,
          items: payload.data,
          loading: false,
          error: '',
          totalPages: payload.meta.last_page,
        },
        activeVariant: {
          ...state.activeVariant,
          item: payload?.data?.length ? payload.data[0] : null,
        },
      }
    case FAILURE(types.GET_VARIANTS):
      return {
        ...state,
        variants: {
          ...state.variants,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_VARIANT):
      return {
        ...state,
        variants: {
          ...state.variants,
          loading: true,
        },
      }
    case SUCCESS(types.GET_VARIANT):
      return {
        ...state,
        variants: {
          ...state.variants,
          items: [payload.data],
          loading: false,
          error: '',
        },
        activeVariant: {
          ...state.activeVariant,
          item: payload.data,
        },
      }
    case FAILURE(types.GET_VARIANT):
      return {
        ...state,
        variants: {
          ...state.variants,
          loading: false,
          error: payload,
        },
      }
    case SUCCESS(types.UPDATE_VARIANT_CONTENT):
      return {
        ...state,
        activeVariant: {
          ...state.activeVariant,
          item: {
            ...state.activeVariant.item,
            content: payload.data,
          },
        },
      }
    case SUCCESS(types.ADD_VARIANT_CONTENT):
      return {
        ...state,
        activeVariant: {
          ...state.activeVariant,
          item: {
            ...state.activeVariant.item,
            content: payload.data,
            language_ids: [
              ...state.activeVariant.item.language_ids,
              payload.data.language.uuid,
            ],
          },
        },
      }
    case SUCCESS(types.DELETE_VARIANT_CONTENT):
      activeIndex = state.activeVariant.item.language_ids.findIndex(
        (el) => el === payload
      )
      return {
        ...state,
        activeVariant: {
          ...state.activeVariant,
          item: {
            ...state.activeVariant.item,
            content: {
              ...state.activeVariant.item.content,
              name: '',
              description: '',
              language: null,
            },
            language_ids: [
              ...state.activeVariant.item.language_ids.slice(0, activeIndex),
              ...state.activeVariant.item.language_ids.slice(activeIndex + 1),
            ],
          },
        },
      }
    case PENDING(types.GET_VARIANT_CONTENT):
      return {
        ...state,
        activeVariant: {
          ...state.activeVariant,
          isContentLoading: true,
        },
      }
    case SUCCESS(types.GET_VARIANT_CONTENT):
      return {
        ...state,
        activeVariant: {
          ...state.activeVariant,
          isContentLoading: false,
          item: {
            ...state.activeVariant.item,
            content: payload?.data[0],
          },
        },
      }
    case FAILURE(types.GET_VARIANT_CONTENT):
      return {
        ...state,
        activeVariant: {
          ...state.activeVariant,
          isContentLoading: false,
        },
      }
    case SUCCESS(types.UPLOAD_VARIANT_FILES):
      return {
        ...state,
        activeVariant: {
          ...state.activeVariant,
          item: {
            ...state.activeVariant.item,
            files: [...state.activeVariant.item.files, ...payload.data],
          },
        },
      }
    case SUCCESS(types.DELETE_VARIANT_FILES):
      return {
        ...state,
        activeVariant: {
          ...state.activeVariant,
          item: {
            ...state.activeVariant.item,
            files: state.activeVariant.item.files.filter(
              (el) => !payload.includes(el.uuid)
            ),
          },
        },
      }
    case PENDING(types.GET_MORE_VARIANTS):
      return {
        ...state,
        variants: {
          ...state.variants,
          moreLoading: true,
        },
      }
    case SUCCESS(types.GET_MORE_VARIANTS):
      return {
        ...state,
        variants: {
          ...state.variants,
          items: [...state.variants.items, ...payload.data],
          moreLoading: false,
          error: '',
        },
      }
    case FAILURE(types.GET_MORE_VARIANTS):
      return {
        ...state,
        variants: {
          ...state.variants,
          moreLoading: false,
          error: payload,
        },
      }
    case SUCCESS(types.CHANGE_ACTIVE_VARIANT):
      return {
        ...state,
        activeVariant: {
          ...state.activeVariant,
          item: payload,
        },
      }
    case SUCCESS(types.CLEAR_VARIANTS):
      return {
        ...state,
        variants: {
          items: [],
          loading: true,
          moreLoading: false,
          error: '',
          totalPages: 0,
        },
        activeVariant: {
          item: null,
          isContentLoading: false,
          error: '',
        },
      }
    default:
      return state
  }
}

export default productReducer
