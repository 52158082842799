export default {
  companies: 'Companies',
  company: 'Company',
  addNewCompany: 'Add a new company',
  selectCompany: 'Select Company',
  emptyCompanies: 'There are no companies yet',
  related_companies: 'Related companies',
  newCompany: 'New Company',
  emptyDescription: 'There are no companies under this category right now',
  createCompany: 'Create New Company',
  deleteTitle: 'You are about delete a company',
  deleteDescription: 'This will remove a company from our service',
  baseInfo: 'Base Company Information',
  companyNameHolder: 'Enter Company Name',
  companyName: 'Company Name',
  companyAddressHolder: 'Enter Company Address',
  companyAddress: 'Company Address',
  companyAddressZipHolder: 'Enter Company Zip Code',
  companyAddressZip: 'Company Zip Code',
  companyAddressCityHolder: 'Enter Company City',
  companyAddressCity: 'Company City',
  companyAddressCountryHolder: 'Enter Company Country',
  companyAddressCountry: 'Company Country',
  companyVatHolder: 'Enter Company Vat Number',
  companyVat: 'Company Vat Number',
  companyRegisterHolder: 'Enter Company Register Number',
  companyRegister: 'Company Register Number',
  companyType: 'Company Type',
  company_viewers: 'Company users and guest viewers',
  company_id_holder: 'Enter company id',
  related_company: 'Related Company',
  add_new_related: 'Add new related company',
  requests: 'Requests',
  incoming_requests: 'Incoming Requests',
  outcoming_requests: 'Outcoming Requests',
  empty_requests: 'There is no requests yet',
  delete_request_title: 'You are about delete the request',
  delete_request_description: 'This will delete the request from the system',
  sort_users_count: 'Sort by users count',
  sort_related_companies_count: 'Sort by related companies count',
  certificate: {
    certificate: 'Certificate',
    create: 'Create Certificate',
    add: 'Add Certificate',
    nameLabel: 'Name of Certificate',
    nameHolder: 'Select Certificate',
    dateLabel: 'Expiration Date',
  },
}
