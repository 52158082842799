import React from 'react'
import { ToastContainer } from 'react-toastify'
import { useSelector } from 'react-redux'

import { commonSelectors } from 'store/ducks/common'

import Loader from 'components/Loader'

import styles from './NotificationPopup.module.scss'
import 'react-toastify/dist/ReactToastify.css'

const NotificationPopup = () => {
  const isActionLoading = useSelector(commonSelectors.getActionLoading)
  return (
    <>
      <ToastContainer
        autoClose={3000}
        closeOnClick
        pauseOnHover
        pauseOnVisibilityChange
        position="top-left"
        rtl={false}
        toastClassName={styles.notification}
        theme="none"
      />
      {isActionLoading ? <Loader /> : null}
    </>
  )
}

export default NotificationPopup
