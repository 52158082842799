import moment from 'moment'
import { toast } from 'react-toastify'

import companies from 'services/companies'
import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import { clearParams, isFieldsEqual, getRequestFiles } from 'utils/helpers'
import i18n from 'translations'

const fetchCompanies =
  ({ params, isPagination = false }) =>
  (dispatch) => {
    const activeType = isPagination
      ? types.GET_MORE_COMPANIES
      : types.GET_COMPANIES
    dispatch({ type: PENDING(activeType) })
    clearParams({ params })
    companies
      .getCompanies({ params })
      .then((res) =>
        dispatch({
          type: SUCCESS(activeType),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(activeType),
          payload: err.response.message,
        })
      )
  }

const fetchSharedCompanies =
  ({ params, isPagination = false }) =>
  (dispatch) => {
    const activeType = isPagination
      ? types.GET_MORE_SHARED_COMPANIES
      : types.GET_SHARED_COMPANIES
    dispatch({ type: PENDING(activeType) })
    clearParams({ params })
    companies
      .getCompanies({ params })
      .then((res) =>
        dispatch({
          type: SUCCESS(activeType),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(activeType),
          payload: err,
        })
      )
  }

const getCompany =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_COMPANY) })
    companies
      .getCompany({ id })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_COMPANY),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_COMPANY),
          payload: err.response.message,
        })
      )
  }

const updateCompany =
  ({ id, data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.UPDATE_COMPANY) })
    companies
      .updateCompany({ id, data })
      .then((res) => {
        toast.success(
          `${i18n.t('companies:company')} ${i18n.t('common:edit_success')}`
        )
        dispatch({
          type: SUCCESS(types.UPDATE_COMPANY),
          payload: res.data,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.UPDATE_COMPANY),
          payload: err.response.data.message,
        })
      )
  }

const createCompany =
  ({ data }) =>
  (dispatch) => {
    const { logo, documents, ...companyData } = data
    dispatch({ type: PENDING(types.CREATE_COMPANY) })
    return companies
      .createCompany({ data: companyData })
      .then((res) => {
        toast.success(
          `${i18n.t('companies:company')} ${i18n.t('common:create_success')}`
        )
        dispatch({
          type: SUCCESS(types.CREATE_COMPANY),
          payload: res.data,
        })
        if (documents?.length) {
          dispatch(uploadCompanyFiles({ data: documents, id: res?.data?.uuid }))
        }
        if (logo) {
          dispatch(uploadCompanyLogo({ data: logo, id: res?.data?.uuid }))
        }
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.CREATE_COMPANY),
          payload: err.response.data.message,
        })
        throw err
      })
  }

const deleteCompany =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_COMPANY) })
    return companies
      .deleteCompany({ id })
      .then((res) => {
        toast.success(
          `${i18n.t('companies:company')} ${i18n.t('common:delete_success')}`
        )
        dispatch({
          type: SUCCESS(types.DELETE_COMPANY),
          payload: res.data,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.DELETE_COMPANY),
          payload: err.response.data.message,
        })
        throw err
      })
  }

const uploadCompanyLogo =
  ({ id, data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.UPLOAD_COMPANY_LOGO) })
    companies
      .uploadCompanyLogo({ id, data })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.UPLOAD_COMPANY_LOGO),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.UPLOAD_COMPANY_LOGO),
          payload: err.response.data.message,
        })
      )
  }

const deleteCompanyLogo =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.REMOVE_COMPANY_LOGO) })
    return companies
      .deleteCompanyLogo({ id })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.REMOVE_COMPANY_LOGO),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.REMOVE_COMPANY_LOGO),
          payload: err.response.data.message,
        })
      )
  }

const uploadCompanyFiles =
  ({ id, data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.UPLOAD_COMPANY_FILES) })
    companies
      .uploadCompanyFiles({ id, data })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.UPLOAD_COMPANY_FILES),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.UPLOAD_COMPANY_FILES),
          payload: err.response.data.message,
        })
      )
  }

const deleteCompanyFiles =
  ({ id, data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_COMPANY_FILES) })
    companies
      .deleteCompanyFiles({ id, data })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.DELETE_COMPANY_FILES),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.DELETE_COMPANY_FILES),
          payload: err.response.data.message,
        })
      )
  }

const fetchLinkedCompanies =
  ({ id, params, isPagination = false }) =>
  (dispatch) => {
    const activeType = isPagination
      ? types.GET_MORE_LINKED_COMPANIES
      : types.GET_LINKED_COMPANIES
    dispatch({ type: PENDING(activeType) })
    clearParams({ params })
    companies
      .getLinkedCompanies({ id, params })
      .then((res) =>
        dispatch({
          type: SUCCESS(activeType),
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(activeType),
          payload: err.response.message,
        })
      )
  }

const fetchCompaniesUsers =
  ({ id, params = null }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_COMPANY_USERS) })
    clearParams({ params })
    companies
      .getCompaniesUsers({ id, params })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_COMPANY_USERS),
          payload: res,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_COMPANY_USERS),
          payload: err.response.data.message,
        })
      )
  }

const fetchCompanyCertificates =
  ({ params = null }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_COMPANY_CERTIFICATES) })
    clearParams({ params })
    companies
      .getCompanyCertificates({ params })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_COMPANY_CERTIFICATES),
          payload: res,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_COMPANY_CERTIFICATES),
          payload: err.response.data.message,
        })
      )
  }

const createCompanyCertificates =
  ({ data }) =>
  (dispatch) => {
    const { files, ...certData } = data
    certData.expiration_date = moment(data.expiration_date).format(
      'YYYY-MM-DD 00:00:00'
    )
    dispatch({ type: PENDING(types.CREATE_COMPANY_CERTIFICATE) })
    return companies
      .createCompanyCertificates({ data: certData })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.CREATE_COMPANY_CERTIFICATE),
          payload: res,
        })
        if (files?.length) {
          return dispatch(
            uploadCompanyCertificateFiles({ id: res.data.uuid, data: files })
          )
        }
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.CREATE_COMPANY_CERTIFICATE),
          payload: err.response.data.message,
        })
        throw err
      })
  }

const editCompanyCertificate =
  ({ id, data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.EDIT_COMPANY_CERTIFICATE) })
    return companies
      .editCompanyCertificate({ data, id })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.EDIT_COMPANY_CERTIFICATE),
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.EDIT_COMPANY_CERTIFICATE),
          payload: err.response.data.message,
        })
        throw err
      })
  }

const changeCompanyCertificateInfo =
  ({ certificate, data }) =>
  (dispatch) => {
    const { files, ...certData } = data
    certData.expiration_date = moment(data.expiration_date).format(
      'YYYY-MM-DD 00:00:00'
    )
    const { newFiles, removedFilesIds } = getRequestFiles(
      files,
      certificate?.files
    )

    const isEqual = isFieldsEqual(certData, certificate)

    if (newFiles.length) {
      dispatch(
        uploadCompanyCertificateFiles({ id: certificate.uuid, data: newFiles })
      )
    }

    if (removedFilesIds.length) {
      dispatch(
        deleteCompanyCertificateFiles({
          id: certificate.uuid,
          data: removedFilesIds,
        })
      )
    }

    if (!isEqual) {
      dispatch(editCompanyCertificate({ id: certificate.uuid, data: certData }))
    }
  }

const deleteCompanyCertificate =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_COMPANY_CERTIFICATE) })
    companies
      .deleteCompanyCertificate({ id })
      .then(() => {
        dispatch({
          type: SUCCESS(types.DELETE_COMPANY_CERTIFICATE),
          payload: id,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.DELETE_COMPANY_CERTIFICATE),
          payload: err.response.data.message,
        })
      )
  }

const uploadCompanyCertificateFiles =
  ({ data, id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.UPLOAD_COMPANY_CERTIFICATE_FILES) })
    return companies
      .uploadCompanyCertificateFiles({ id, data })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.UPLOAD_COMPANY_CERTIFICATE_FILES),
          payload: res,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.UPLOAD_COMPANY_CERTIFICATE_FILES),
          payload: err.response.data.message,
        })
      )
  }

const deleteCompanyCertificateFiles =
  ({ data, id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_COMPANY_CERTIFICATE_FILES) })
    companies
      .deleteCompanyCertificateFiles({ id, data })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.DELETE_COMPANY_CERTIFICATE_FILES),
          payload: res,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.DELETE_COMPANY_CERTIFICATE_FILES),
          payload: err.response.data.message,
        })
      )
  }

const fetchLettersOfCompanies = () => (dispatch) => {
  dispatch({ type: PENDING(types.GET_COMPANIES_LETTERS) })
  companies
    .getLettersOfCompanies()
    .then((res) =>
      dispatch({
        type: SUCCESS(types.GET_COMPANIES_LETTERS),
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: FAILURE(types.GET_COMPANIES_LETTERS),
        payload: err.response.message,
      })
    )
}

const createCompanyLink =
  ({ data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.CREATE_LINK_REQUEST) })
    return companies
      .createCompanyLink({ data })
      .then((res) => {
        toast.success(
          `${i18n.t('common:link')} ${i18n.t('common:create_success')}`
        )
        dispatch({
          type: SUCCESS(types.CREATE_LINK_REQUEST),
          payload: res.data,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.CREATE_LINK_REQUEST),
          payload: err.response.message,
        })
        throw err
      })
  }

const approveCompanyLink =
  ({ id, company, type }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.APPROVE_COMPANY_LINK) })
    companies
      .approveCompanyLink({ id })
      .then(() => {
        toast.success(
          `${i18n.t('common:link')} ${i18n.t('common:approve_success')}`
        )
        dispatch({
          type: SUCCESS(types.APPROVE_COMPANY_LINK),
          payload: { id, company, type },
        })
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.APPROVE_COMPANY_LINK),
          payload: err,
        })
      })
  }

const declineCompanyLink =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DECLINE_COMPANY_LINK) })
    companies
      .declineCompanyLink({ id })
      .then(() => {
        toast.success(
          `${i18n.t('common:link')} ${i18n.t('common:decline_success')}`
        )
        dispatch({
          type: SUCCESS(types.DECLINE_COMPANY_LINK),
          payload: id,
        })
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.DECLINE_COMPANY_LINK),
          payload: err,
        })
      })
  }

const fetchCompanyLinks =
  ({ params }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_COMPANY_LINKS) })
    clearParams({ params })
    companies
      .getCompanyLinks({ params })
      .then((res) =>
        dispatch({
          type: SUCCESS(types.GET_COMPANY_LINKS),
          payload: res,
        })
      )
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_COMPANY_LINKS),
          payload: err,
        })
      )
  }

const changeCompanyInfo =
  ({ company, data, companyCertificates = [] }) =>
  (dispatch) => {
    const { logo, documents, certificates, ...values } = data

    const removedCertificates = companyCertificates?.filter(
      (cert) => !certificates?.find((el) => el.uuid === cert.uuid)
    )

    const isEqual = isFieldsEqual(values, company)

    const { newFiles, removedFilesIds } = getRequestFiles(
      documents,
      company?.documents
    )

    if (newFiles.length) {
      dispatch(uploadCompanyFiles({ id: company.uuid, data: newFiles }))
    }

    if (removedFilesIds.length) {
      dispatch(deleteCompanyFiles({ id: company.uuid, data: removedFilesIds }))
    }

    if (logo && logo?.preview && !company?.logo) {
      dispatch(uploadCompanyLogo({ id: company.uuid, data: logo }))
    }

    if (company?.logo && (!logo || logo?.preview)) {
      dispatch(deleteCompanyLogo({ id: company.uuid })).then(() => {
        if (logo?.preview) {
          dispatch(uploadCompanyLogo({ id: company.uuid, data: logo }))
        }
      })
    }

    if (removedCertificates?.length) {
      removedCertificates.forEach((cert) => {
        dispatch(deleteCompanyCertificate({ id: cert.uuid }))
      })
    }

    if (!isEqual) {
      dispatch(updateCompany({ id: company.uuid, data: values }))
    }
  }

const clearCompanies = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_COMPANIES) })

const clearLinkedCompanies = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_LINKED_COMPANIES) })

const clearCompany = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_COMPANY) })

export default {
  fetchCompanies,
  fetchSharedCompanies,
  getCompany,
  updateCompany,
  createCompany,
  deleteCompany,
  fetchLettersOfCompanies,
  fetchLinkedCompanies,
  fetchCompaniesUsers,
  uploadCompanyFiles,
  deleteCompanyFiles,
  clearCompanies,
  uploadCompanyLogo,
  deleteCompanyLogo,
  changeCompanyInfo,
  fetchCompanyCertificates,
  createCompanyCertificates,
  deleteCompanyCertificate,
  editCompanyCertificate,
  uploadCompanyCertificateFiles,
  deleteCompanyCertificateFiles,
  changeCompanyCertificateInfo,
  createCompanyLink,
  clearLinkedCompanies,
  fetchCompanyLinks,
  declineCompanyLink,
  approveCompanyLink,
  clearCompany,
}
