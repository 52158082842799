import { requests, formDataHeaders } from './api'
import { filesToFormData } from 'utils/serializer'

const getCollectionProducts = ({ params = {} }) =>
  requests.get({ url: 'products', params })

const createProduct = ({ data }) => requests.post({ url: '/products', data })

const uploadProductFiles = ({ data, id }) =>
  requests.post({
    url: `/products/${id}/files`,
    data: filesToFormData(data),
    headers: formDataHeaders,
  })

const deleteProductFiles = ({ data, id }) =>
  requests.delete({
    url: `/products/${id}/files`,
    params: { file_ids: data },
    headers: formDataHeaders,
  })

const getProduct = ({ id }) => requests.get({ url: `products/${id}` })

const updateProduct = ({ id, data }) =>
  requests.put({ url: `products/${id}`, data })

const deleteProduct = ({ productId }) =>
  requests.delete({ url: `products/${productId}/archive` })

const shareProduct = ({ id, params }) =>
  requests.post({ url: `products/${id}/share`, params })

const unshareProduct = ({ id, params }) =>
  requests.post({ url: `products/${id}/unshare`, params })

const addProductContent = ({ id, data }) =>
  requests.post({ url: `products/${id}/content`, data })

const getProductContent = ({ id, params }) =>
  requests.get({ url: `products/${id}/content`, params })

const updateProductContent = ({ id, data, langId }) =>
  requests.put({ url: `products/${id}/content/${langId}`, data })

const deleteProductContent = ({ id, langId }) =>
  requests.delete({ url: `products/${id}/content/${langId}` })

const getProductInstance = ({ id, params }) =>
  requests.get({
    url: `products/${id}/instances`,
    params,
  })

const createProductInstance = ({ productId, variantId, data }) =>
  requests.post({
    url: `products/${productId}/variants/${variantId}/instances`,
    data,
  })

const deleteProductInstance = ({ productId, variantId, instanceId }) =>
  requests.delete({
    url: `products/${productId}/variants/${variantId}/instances/${instanceId}`,
  })

const editProductInstance = ({ productId, variantId, instanceId, data }) =>
  requests
    .put({
      url: `products/${productId}/variants/${variantId}/instances/${instanceId}`,
      data,
    })
    .then((res) => res.data)

const getProductAttributesMatrix = ({ id, params = {} }) =>
  requests.get({ url: `products/${id}/attributes-matrix`, params })

const getAttributes = () => requests.get({ url: `products/attributes` })

const getAttributesMatrix = ({ params }) =>
  requests.get({ url: `products/attributes/matrix`, params })

const getAttributesOptions = ({ params }) =>
  requests.get({ url: `products/attributes/options`, params })

export default {
  getCollectionProducts,
  createProduct,
  uploadProductFiles,
  deleteProductFiles,
  getProduct,
  updateProduct,
  deleteProduct,
  shareProduct,
  unshareProduct,
  getProductInstance,
  createProductInstance,
  deleteProductInstance,
  editProductInstance,
  getProductAttributesMatrix,
  getAttributes,
  getAttributesMatrix,
  getAttributesOptions,
  addProductContent,
  getProductContent,
  updateProductContent,
  deleteProductContent,
}
