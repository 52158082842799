import { toast } from 'react-toastify'

import currencies from 'services/globalAttributes/currencies'
import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import { clearParams } from 'utils/helpers'
import i18n from 'translations'

const fetchCurrencies =
  ({ params }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_CURRENCIES) })
    clearParams({ params })
    currencies
      .getCurrencies({ params })
      .then((res) =>
        dispatch({ type: SUCCESS(types.GET_CURRENCIES), payload: res })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_CURRENCIES),
        })
      )
  }

const createCurrency =
  ({ data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.CREATE_CURRENCY) })
    return currencies
      .createCurrency({ data })
      .then((res) => {
        toast.success(
          `${i18n.t('attributes:currencies:single_name')} ${i18n.t(
            'common:create_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.CREATE_CURRENCY), payload: res })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.CREATE_CURRENCY),
        })
      )
  }

const editCurrency =
  ({ data, id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.EDIT_CURRENCY) })
    return currencies
      .editCurrency({ data, id })
      .then((res) => {
        toast.success(
          `${i18n.t('attributes:currencies:single_name')} ${i18n.t(
            'common:edit_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.EDIT_CURRENCY), payload: res })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.EDIT_CURRENCY),
        })
      )
  }

const deleteCurrency =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_CURRENCY) })
    currencies
      .deleteCurrency({ id })
      .then(() => {
        toast.success(
          `${i18n.t('attributes:currencies:single_name')} ${i18n.t(
            'common:delete_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.DELETE_CURRENCY), payload: id })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.DELETE_CURRENCY),
        })
      )
  }

const clearCurrencies = () => (dispatch) =>
  dispatch({
    type: SUCCESS(types.CLEAR_CURRENCIES),
  })

export default {
  fetchCurrencies,
  createCurrency,
  editCurrency,
  deleteCurrency,
  clearCurrencies,
}
