const getDefectCategories = (state) =>
  state.defectCategory.defectCategories.items
const getDefectCategoriesLoading = (state) =>
  state.defectCategory.defectCategories.loading
const getDefectCategoriesPages = (state) =>
  state.defectCategory.defectCategories.totalPages
const getDefectCategoriesMoreLoading = (state) =>
  state.defectCategory.defectCategories.moreLoading

export default {
  getDefectCategories,
  getDefectCategoriesLoading,
  getDefectCategoriesPages,
  getDefectCategoriesMoreLoading,
}
