import { requests } from './api'

const initialEndpoint = 'report-templates'

const getTemplates = ({ params }) =>
  requests.get({ url: initialEndpoint, params }).then((res) => res.data)

const getTemplate = ({ id }) =>
  requests.get({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)

const createTemplate = ({ data }) =>
  requests.post({ url: initialEndpoint, data }).then((res) => res.data)

const deleteTemplate = ({ id }) =>
  requests.delete({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)

const editTemplate = ({ id, data }) =>
  requests
    .put({ url: `${initialEndpoint}/${id}`, data })
    .then((res) => res.data)

const getTemplateFields = ({ templateId }) =>
  requests
    .get({ url: `${initialEndpoint}/${templateId}/custom-fields` })
    .then((res) => res.data)

const createTemplateField = ({ templateId, fieldId, data }) =>
  requests
    .post({
      url: `${initialEndpoint}/${templateId}/custom-fields/${fieldId}`,
      data,
    })
    .then((res) => res.data)

const deleteTemplateField = ({ templateId, fieldId }) =>
  requests
    .delete({
      url: `${initialEndpoint}/${templateId}/custom-fields/${fieldId}`,
    })
    .then((res) => res.data)

const changeFieldPosition = ({ templateId, fieldId, position }) =>
  requests
    .patch({
      url: `${initialEndpoint}/${templateId}/custom-fields/${fieldId}/${position}`,
    })
    .then((res) => res.data)

const editTemplateField = ({ templateId, fieldId, data }) =>
  requests
    .put({
      url: `${initialEndpoint}/${templateId}/custom-fields/${fieldId}`,
      data,
    })
    .then((res) => res.data)

export default {
  getTemplates,
  getTemplate,
  createTemplate,
  deleteTemplate,
  editTemplate,
  getTemplateFields,
  createTemplateField,
  deleteTemplateField,
  changeFieldPosition,
  editTemplateField,
}
