import { requests } from './api'

const initialEndpoint = 'custom-fields'

const getCustomFields = ({ params }) =>
  requests.get({ url: initialEndpoint, params }).then((res) => res.data)

const getCustomField = ({ id }) =>
  requests.get({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)

const createCustomField = ({ data }) =>
  requests.post({ url: initialEndpoint, data }).then((res) => res.data)

const deleteCustomField = ({ id }) =>
  requests.delete({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)

const editCustomField = ({ id, data }) =>
  requests
    .put({ url: `${initialEndpoint}/${id}`, data })
    .then((res) => res.data)

export default {
  getCustomFields,
  getCustomField,
  createCustomField,
  deleteCustomField,
  editCustomField,
}
