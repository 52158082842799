import { requests } from '../api'

const initialEndpoint = 'products/problem-categories'

const getDefectCatergories = ({ params }) =>
  requests.get({ url: initialEndpoint, params }).then((res) => res.data)

const getDefectCatergory = ({ id }) =>
  requests.get({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)

const createDefectCategory = ({ data }) =>
  requests.post({ url: initialEndpoint, data }).then((res) => res.data)

const editDefectCategory = ({ data, id }) =>
  requests
    .put({ url: `${initialEndpoint}/${id}`, data })
    .then((res) => res.data)

const deleteDefectCategory = ({ id }) =>
  requests.delete({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)

export default {
  getDefectCatergories,
  getDefectCatergory,
  createDefectCategory,
  editDefectCategory,
  deleteDefectCategory,
}
