import React, { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { HomeIcon, ArrowIcon } from 'assets/icons/common'

import { getBreadcrumbs } from 'utils/breadcrumbs'

import styles from './Breadcrumbs.module.scss'

const Breadcrumbs = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const customBreadcrumbs = useSelector(
    (state) => state?.common?.customBreadcrumbs
  )

  const breadcrumbs = getBreadcrumbs({
    url: location.pathname,
    custom: customBreadcrumbs,
    t,
  })
  const getActiveLink = useCallback(
    () => breadcrumbs[breadcrumbs.length - 1]?.path || '',
    [breadcrumbs]
  )

  const handleNavigate = useCallback(
    ({ link }) => (link === getActiveLink() ? null : navigate(link)),
    [getActiveLink, navigate]
  )

  return (
    <div className={styles.breadcrumbs}>
      <HomeIcon className={styles.breadcrumbs_home} />
      {breadcrumbs.length
        ? breadcrumbs.map((link, index) => (
            <div key={link.path} className={styles.breadcrumbs_link}>
              <ArrowIcon className={styles.breadcrumbs_link_arrow} />
              <p
                onClick={() => handleNavigate({ link: link.path, index })}
                className={`${styles.breadcrumbs_link_description} ${
                  link.path === getActiveLink()
                    ? styles.breadcrumbs_link_description_active
                    : ''
                }`}
              >
                {link.name}
              </p>
            </div>
          ))
        : null}
    </div>
  )
}

export default Breadcrumbs
