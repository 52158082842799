const getLanguages = (state) => state.language.languages.items
const getLanguagesLoading = (state) => state.language.languages.loading

const getDefaultLanguage = (state) => state.language.defaultLang

export default {
  getLanguages,
  getLanguagesLoading,
  getDefaultLanguage,
}
