const GET_PRODUCTS_BY_COLLECTION = 'GET_PRODUCTS_BY_COLLECTION'
const GET_MORE_PRODUCTS = 'GET_MORE_PRODUCTS'
const CREATE_PRODUCT = 'CREATE_PRODUCT'
const GET_PRODUCT = 'GET_PRODUCT'
const DELETE_PRODUCT = 'DELETE_PRODUCT'
const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
const SHARE_PRODUCT = 'SHARE_PRODUCT'
const UNSHARE_PRODUCT = 'UNSHARE_PRODUCT'
const GET_PRODUCT_INSTANCES = 'GET_PRODUCT_INSTANCES'
const CREATE_PRODUCT_INSTANCE = 'CREATE_PRODUCT_INSTANCE'
const DELETE_PRODUCT_INSTANCE = 'DELETE_PRODUCT_INSTANCE'
const EDIT_PRODUCT_INSTANCE = 'EDIT_PRODUCT_INSTANCE'

const UPLOAD_PRODUCT_FILES = 'UPLOAD_PRODUCT_FILES'
const DELETE_PRODUCT_FILES = 'DELETE_PRODUCT_FILES'

const GET_ATTRIBUTES = 'GET_ATTRIBUTES'
const GET_ATTRIBUTES_OPTIONS = 'GET_ATTRIBUTES_OPTIONS'
const GET_ATTRIBUTES_MATRIX = 'GET_ATTRIBUTES_MATRIX'
const GET_PRODUCT_ATTRIBUTES_MATRIX = 'GET_PRODUCT_ATTRIBUTES_MATRIX'
const GET_ACTIVE_ATTRIBUTES_MATRIX = 'GET_ACTIVE_ATTRIBUTES_MATRIX'

const ADD_PRODUCT_CONTENT = 'ADD_PRODUCT_CONTENT'
const GET_PRODUCT_CONTENT = 'GET_PRODUCT_CONTENT'
const DELETE_PRODUCT_CONTENT = 'DELETE_PRODUCT_CONTENT'
const UPDATE_PRODUCT_CONTENT = 'UPDATE_PRODUCT_CONTENT'

const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'
const CLEAR_PRODUCT = 'CLEAR_PRODUCT'

export default {
  GET_PRODUCTS_BY_COLLECTION,
  CREATE_PRODUCT,
  GET_PRODUCT,
  GET_ATTRIBUTES,
  GET_ATTRIBUTES_OPTIONS,
  GET_ATTRIBUTES_MATRIX,
  GET_PRODUCT_ATTRIBUTES_MATRIX,
  GET_ACTIVE_ATTRIBUTES_MATRIX,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  UNSHARE_PRODUCT,
  SHARE_PRODUCT,
  GET_PRODUCT_INSTANCES,
  CREATE_PRODUCT_INSTANCE,
  DELETE_PRODUCT_INSTANCE,
  EDIT_PRODUCT_INSTANCE,
  UPLOAD_PRODUCT_FILES,
  DELETE_PRODUCT_FILES,
  ADD_PRODUCT_CONTENT,
  GET_PRODUCT_CONTENT,
  DELETE_PRODUCT_CONTENT,
  UPDATE_PRODUCT_CONTENT,
  CLEAR_PRODUCTS,
  CLEAR_PRODUCT,
  GET_MORE_PRODUCTS,
}
