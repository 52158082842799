const getCountries = (state) => state.country.countries.items
const getCountriesLoading = (state) => state.country.countries.loading
const getCountriesPages = (state) => state.country.countries.totalPages
const getCountriesMoreLoading = (state) => state.country.countries.moreLoading

export default {
  getCountries,
  getCountriesLoading,
  getCountriesPages,
  getCountriesMoreLoading,
}
