import { requests } from './api'

const {
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_GRANT_TYPE_PASSWORD,
  REACT_APP_GRANT_TYPE_REFRESH_TOKEN,
} = process.env

const getUserInfo = () => requests.get({ url: '/users/me' })

const userLogin = ({ data, isRefresh = false }) =>
  requests.post({
    url: '/auth/token',
    data: {
      ...data,
      grant_type: isRefresh
        ? REACT_APP_GRANT_TYPE_REFRESH_TOKEN
        : REACT_APP_GRANT_TYPE_PASSWORD,
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET,
    },
  })

const logout = () => requests.post({ url: '/auth/logout' })

const resetPassword = ({ data }) =>
  requests.post({ url: '/auth/reset-password', data })

const setPassword = ({ data }) =>
  requests.post({ url: '/auth/set-password', data }).then((res) => res.data)

export default {
  getUserInfo,
  userLogin,
  logout,
  resetPassword,
  setPassword,
}
