const getBreadcrumbs = (state) => state.common.customBreadcrumbs
const getActiveLang = (state) => state.common.activeLang
const getActiveTheme = (state) => state.common.theme
const getActionLoading = (state) => state.common.isActionLoading

const getGlobalSearchInfo = (state) => state.common.globalSearch

export default {
  getBreadcrumbs,
  getActiveLang,
  getActiveTheme,
  getActionLoading,
  getGlobalSearchInfo,
}
