import { requests } from '../api'

const initialEndpoint = 'products/problems'

const getProductDefects = ({ params }) =>
  requests.get({ url: initialEndpoint, params }).then((res) => res.data)

const getProductDefect = ({ id }) =>
  requests.get({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)

const createProductDefect = ({ data }) =>
  requests.post({ url: initialEndpoint, data }).then((res) => res.data)

const editProductDefect = ({ data, id }) =>
  requests
    .put({ url: `${initialEndpoint}/${id}`, data })
    .then((res) => res.data)

const deleteProductDefect = ({ id }) =>
  requests.delete({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)

export default {
  getProductDefects,
  getProductDefect,
  createProductDefect,
  editProductDefect,
  deleteProductDefect,
}
