import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE, COMPANY_TYPES } from 'constants/configs'

import {
  getTokenFromStorage,
  removeTokens,
  getIsAdminFromStorage,
} from 'utils/serializer'

const initialState = {
  info: {
    user: null,
    loading: false,
    error: '',
    isAdmin: getIsAdminFromStorage(),
  },
  token: getTokenFromStorage(),
  myTools: {
    isLoading: true,
    items: [],
    error: '',
  },
  myPermissions: {
    isLoading: true,
    permissions: null,
  },
}

const authReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case PENDING(types.LOGIN):
      return {
        ...state,
        info: {
          ...state.info,
          loading: true,
        },
      }
    case SUCCESS(types.LOGIN):
      localStorage.setItem('token', JSON.stringify(payload))
      return {
        ...state,
        info: {
          ...state.info,
          user: payload.user,
          error: '',
          loading: false,
        },
        token: payload.access_token,
      }
    case FAILURE(types.LOGIN):
      return {
        ...state,
        info: {
          ...state.info,
          loading: false,
          error: payload.message,
        },
      }
    case PENDING(types.RESET_PASSWORD):
      return {
        ...state,
        info: {
          ...state.info,
          loading: true,
        },
      }
    case SUCCESS(types.RESET_PASSWORD):
      return {
        ...state,
        info: {
          ...state.info,
          loading: false,
        },
      }
    case FAILURE(types.RESET_PASSWORD):
      return {
        ...state,
        info: {
          ...state.info,
          loading: false,
          error: payload.message,
        },
      }
    case PENDING(types.GET_ACTIVE_USER):
      return {
        ...state,
        info: {
          ...state.info,
          loading: true,
        },
      }
    case SUCCESS(types.GET_ACTIVE_USER):
      localStorage.setItem(
        'isAdmin',
        JSON.stringify(
          payload.data.company.type === COMPANY_TYPES.service_provider
        )
      )
      return {
        ...state,
        info: {
          ...state.info,
          loading: false,
          user: payload.data,
          isAdmin: payload.data.company.type === COMPANY_TYPES.service_provider,
        },
      }
    case FAILURE(types.GET_ACTIVE_USER):
      return {
        ...state,
        info: {
          ...state.info,
          loading: false,
        },
      }
    case SUCCESS(types.UPDATE_ACTIVE_USER):
      return {
        ...state,
        info: {
          ...state.info,
          user: payload,
        },
      }
    case SUCCESS(types.LOGOUT):
      removeTokens()
      return {
        ...initialState,
        token: '',
      }
    case SUCCESS(types.REFRESH_TOKEN):
      return {
        ...state,
        token: payload,
      }

    case PENDING(types.GET_MY_TOOLS):
      return {
        ...state,
        myTools: {
          ...state.myTools,
          isLoading: true,
        },
      }
    case SUCCESS(types.GET_MY_TOOLS):
      return {
        ...state,
        myTools: {
          ...state.myTools,
          isLoading: false,
          items: payload,
        },
      }
    case FAILURE(types.GET_MY_TOOLS):
      return {
        ...state,
        myTools: {
          ...state.myTools,
          isLoading: false,
        },
      }
    case PENDING(types.GET_MY_PERMISSIONS):
      return {
        ...state,
        myPermissions: {
          ...state.myPermissions,
          isLoading: true,
        },
      }
    case SUCCESS(types.GET_MY_PERMISSIONS):
      return {
        ...state,
        myPermissions: {
          ...state.myPermissions,
          isLoading: false,
          permissions: { ...state.myPermissions.permissions, ...payload },
        },
      }
    case FAILURE(types.GET_MY_PERMISSIONS):
      return {
        ...state,
        myPermissions: {
          ...state.myPermissions,
          isLoading: false,
        },
      }
    default:
      return state
  }
}

export default authReducer
