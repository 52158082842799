export default {
  defect_category: 'Defect Category',
  defect: 'Defect',
  remove_defect: 'Remove Defect',
  add_defect: 'Add Defect',
  new_defect: 'New Defect',
  defects: 'Defects',
  remove_title: 'You are about to remove the defect',
  remove_description: 'This will remove the defect from the report',
  empty_description: 'There are no defects under this category',
}
