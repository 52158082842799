import { requests, formDataHeaders } from '../api'
import { objectToFormData } from 'utils/serializer'

const initialEndpoint = 'production-orders'

const getProductionOrders = ({ params }) =>
  requests.get({ url: initialEndpoint, params }).then((res) => res.data)

const getProductionOrder = ({ id }) =>
  requests.get({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)

const createProductionOrder = ({ data }) =>
  requests.post({ url: initialEndpoint, data }).then((res) => res.data)

const editProductionOrder = ({ data, id }) =>
  requests
    .put({ url: `${initialEndpoint}/${id}`, data })
    .then((res) => res.data)

const deleteProductionOrder = ({ id }) =>
  requests.delete({ url: `${initialEndpoint}/${id}` })

const getProductionOrderProducts = ({ id, params }) =>
  requests
    .get({ url: `${initialEndpoint}/${id}/products`, params })
    .then((res) => res.data)

const createProductionOrderProduct = ({ id, data }) =>
  requests
    .post({ url: `${initialEndpoint}/${id}/products`, data })
    .then((res) => res.data)

const deleteProductionOrderProduct = ({ orderId, productId }) =>
  requests
    .delete({ url: `${initialEndpoint}/${orderId}/products/${productId}` })
    .then((res) => res.data)

const editProductionOrderProduct = ({ orderId, productId, data }) =>
  requests
    .put({ url: `${initialEndpoint}/${orderId}/products/${productId}`, data })
    .then((res) => res.data)

const getProductionOrderSerials = ({ orderId, productId, params }) =>
  requests
    .get({
      url: `${initialEndpoint}/${orderId}/products/${productId}/instances`,
      params,
    })
    .then((res) => res.data)

const createProductionOrderSerial = ({ orderId, productId, data }) =>
  requests
    .post({
      url: `${initialEndpoint}/${orderId}/products/${productId}/instances`,
      data,
    })
    .then((res) => res.data)

const deleteProductionOrderSerial = ({ orderId, productId, params }) =>
  requests
    .delete({
      url: `${initialEndpoint}/${orderId}/products/${productId}/instances`,
      params,
    })
    .then((res) => res.data)

const sendProductionOrder = ({ id }) =>
  requests
    .patch({ url: `${initialEndpoint}/${id}/send` })
    .then((res) => res.data)

const getProductionOrderImports = ({ params }) =>
  requests.get({ url: 'imports', params }).then((res) => res.data)
const importProductionOrder = ({ data }) =>
  requests
    .post({
      url: 'imports',
      data: objectToFormData(data),
      headers: formDataHeaders,
    })
    .then((res) => res.data)

export default {
  getProductionOrders,
  getProductionOrder,
  createProductionOrder,
  editProductionOrder,
  deleteProductionOrder,
  getProductionOrderProducts,
  createProductionOrderProduct,
  deleteProductionOrderProduct,
  editProductionOrderProduct,
  getProductionOrderSerials,
  createProductionOrderSerial,
  deleteProductionOrderSerial,
  sendProductionOrder,
  getProductionOrderImports,
  importProductionOrder,
}
