import common from './common'
import login from './login'
import errors from './errors'
import productLibrary from './productLibrary'
import sidebar from './sidebar'
import companies from './companies'
import collection from './collection'
import product from './product'
import attributes from './attributes'
import user from './user'
import quality_control from './quality_control'
import defects from './defects'

export default {
  common,
  login,
  errors,
  productLibrary,
  sidebar,
  companies,
  collection,
  product,
  attributes,
  user,
  quality_control,
  defects,
}
