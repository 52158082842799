import { requests } from './api'

const initialEndpoint = 'collections'

const getCollectionById = ({ id }) =>
  requests.get({ url: `${initialEndpoint}/${id}` })

const getCollections = ({ params = {} }) =>
  requests.get({ url: initialEndpoint, params })

const shareCollection = ({ id, params }) =>
  requests.post({ url: `${initialEndpoint}/${id}/share`, params })

const unshareCollection = ({ id, params }) =>
  requests.post({ url: `${initialEndpoint}/${id}/unshare`, params })

const getCollectionContent = ({ id, params }) =>
  requests.get({ url: `${initialEndpoint}/${id}/content`, params })

const deleteCollectionContent = ({ id, langId }) =>
  requests.delete({ url: `${initialEndpoint}/${id}/content/${langId}` })

const updateCollectionContent = ({ id, langId, data }) =>
  requests.put({ url: `${initialEndpoint}/${id}/content/${langId}`, data })

const addCollectionContent = ({ id, data }) =>
  requests.post({ url: `${initialEndpoint}/${id}/content`, data })

const createCollection = ({ data }) =>
  requests.post({ url: initialEndpoint, data }).then((res) => res.data)

const deleteCollection = ({ id }) =>
  requests.delete({ url: `${initialEndpoint}/${id}/archive` })

export default {
  getCollectionById,
  getCollections,
  shareCollection,
  unshareCollection,
  getCollectionContent,
  deleteCollectionContent,
  updateCollectionContent,
  addCollectionContent,
  createCollection,
  deleteCollection,
}
