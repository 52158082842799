const GET_STONE_CERTIFICATES = 'GET_STONE_CERTIFICATES'
const GET_STONE_CERTIFICATE = 'GET_STONE_CERTIFICATE'
const CREATE_STONE_CERTIFICATE = 'CREATE_STONE_CERTIFICATE'
const EDIT_STONE_CERTIFICATE = 'EDIT_STONE_CERTIFICATE'
const DELETE_STONE_CERTIFICATE = 'DELETE_STONE_CERTIFICATE'

export default {
  GET_STONE_CERTIFICATES,
  GET_STONE_CERTIFICATE,
  CREATE_STONE_CERTIFICATE,
  EDIT_STONE_CERTIFICATE,
  DELETE_STONE_CERTIFICATE,
}
