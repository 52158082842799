const CREATE_VARIANT = 'CREATE_VARIANT'
const GET_VARIANTS = 'GET_VARIANTS'
const GET_VARIANT = 'GET_VARIANT'
const DELETE_VARIANT = 'DELETE_VARIANT'
const GET_MORE_VARIANTS = 'GET_MORE_VARIANTS'
const UPDATE_VARIANT = 'UPDATE_VARIANT'

const DELETE_VARIANT_FILES = 'DELETE_VARIANT_FILES'
const UPLOAD_VARIANT_FILES = 'UPLOAD_VARIANT_FILES'

const GET_VARIANT_CONTENT = 'GET_VARIANT_CONTENT'
const DELETE_VARIANT_CONTENT = 'DELETE_VARIANT_CONTENT'
const ADD_VARIANT_CONTENT = 'ADD_VARIANT_CONTENT'
const UPDATE_VARIANT_CONTENT = 'UPDATE_VARIANT_CONTENT'

const CHANGE_ACTIVE_VARIANT = 'CHANGE_ACTIVE_VARIANT'

const CLEAR_VARIANTS = 'CLEAR_VARIANTS'

export default {
  CREATE_VARIANT,
  GET_VARIANTS,
  GET_VARIANT,
  DELETE_VARIANT,
  UPDATE_VARIANT,
  GET_MORE_VARIANTS,
  DELETE_VARIANT_FILES,
  UPLOAD_VARIANT_FILES,
  GET_VARIANT_CONTENT,
  DELETE_VARIANT_CONTENT,
  ADD_VARIANT_CONTENT,
  UPDATE_VARIANT_CONTENT,
  CHANGE_ACTIVE_VARIANT,
  CLEAR_VARIANTS,
}
