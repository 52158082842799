import React from 'react'
import { Outlet } from 'react-router-dom'

import Sidebar from 'components/Sidebar'
import Navbar from 'components/Navbar'

import styles from './MainWrapper.module.scss'

const MainWrapper = () => {
  return (
    <div className={styles.wrapper}>
      <Sidebar />
      <div className={styles.wrapper_main}>
        <Navbar />
        <Outlet />
      </div>
    </div>
  )
}

export default MainWrapper
