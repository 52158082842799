import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const initialState = {
  certificates: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
  },
}

const reportReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case PENDING(types.GET_STONE_CERTIFICATES):
      return {
        ...state,
        certificates: {
          ...state.certificates,
          loading: true,
        },
      }
    case SUCCESS(types.GET_STONE_CERTIFICATES):
      return {
        ...state,
        certificates: {
          ...state.certificates,
          items: payload.data,
          totalPages: payload.meta.last_page,
          loading: false,
        },
      }
    case FAILURE(types.GET_STONE_CERTIFICATES):
      return {
        ...state,
        certificates: {
          ...state.certificates,
          loading: false,
          error: payload,
        },
      }

    default:
      return state
  }
}

export default reportReducer
