const getRegions = (state) => state.region.regions.items
const getRegionsLoading = (state) => state.region.regions.loading
const getRegionsPages = (state) => state.region.regions.totalPages
const getRegionsMoreLoading = (state) => state.region.regions.moreLoading

export default {
  getRegions,
  getRegionsLoading,
  getRegionsPages,
  getRegionsMoreLoading
}
