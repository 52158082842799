const GET_CUSTOM_FIELDS = 'GET_CUSTOM_FIELDS'
const GET_CUSTOM_FIELD = 'GET_CUSTOM_FIELD'
const CREATE_CUSTOM_FIELD = 'CREATE_CUSTOM_FIELD'
const EDIT_CUSTOM_FIELD = 'EDIT_CUSTOM_FIELD'
const DELETE_CUSTOM_FIELD = 'DELETE_CUSTOM_FIELD'

const CLEAR_CUSTOM_FIELDS = 'CLEAR_CUSTOM_FIELDS'

export default {
  GET_CUSTOM_FIELDS,
  GET_CUSTOM_FIELD,
  CREATE_CUSTOM_FIELD,
  EDIT_CUSTOM_FIELD,
  DELETE_CUSTOM_FIELD,
  CLEAR_CUSTOM_FIELDS,
}
