import { toast } from 'react-toastify'
import moment from 'moment'

import reports from 'services/reports'
import defectCategory from 'services/defects/defectCategory'
import productDefect from 'services/defects/productDefect'
import stoneCertificates from 'services/stoneCertificates'
import productionOrders from 'services/orders/productionOrders'

import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import { clearParams } from 'utils/helpers'
import i18n from 'translations'

const fetchReports =
  ({ params }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_REPORTS) })
    clearParams({ params })
    reports
      .getReports({ params })
      .then((res) =>
        dispatch({ type: SUCCESS(types.GET_REPORTS), payload: res })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_REPORTS),
        })
      )
  }

const fetchReport =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_REPORT) })
    reports
      .getReport({ id })
      .then((res) =>
        dispatch({ type: SUCCESS(types.GET_REPORT), payload: res })
      )
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_REPORT),
        })
      )
  }

const createReport =
  ({ data }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.CREATE_REPORT) })
    const { brand, manufacturer, ...values } = data
    const newData = {
      ...values,
      report_date: moment(data?.report_date).format('YYYY-MM-DD HH:mm:ss'),
      brand_id: brand?.value,
      manufacturer_id: manufacturer?.value,
    }
    return reports
      .createReport({ data: newData })
      .then((res) => {
        toast.success(
          `${i18n.t('quality_control:reports:report')} ${i18n.t(
            'common:create_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.CREATE_REPORT), payload: res })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.CREATE_REPORT),
        })

        throw err
      })
  }

const editReport =
  ({ data, id }) =>
  (dispatch) => {
    const { brand, manufacturer, ...values } = data
    const newData = {
      ...values,
      report_date: moment(data?.report_date).format('YYYY-MM-DD HH:mm:ss'),
      brand_id: brand?.value,
      manufacturer_id: manufacturer?.value,
    }
    dispatch({ type: PENDING(types.EDIT_REPORT) })
    return reports
      .editReport({ data: newData, id })
      .then((res) => {
        toast.success(
          `${i18n.t('quality_control:reports:report')} ${i18n.t(
            'common:edit_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.EDIT_REPORT), payload: res })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.EDIT_REPORT),
        })
      )
  }

const deleteReport =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_REPORT) })
    reports
      .deleteReport({ id })
      .then(() => {
        toast.success(
          `${i18n.t('quality_control:reports:report')} ${i18n.t(
            'common:delete_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.DELETE_REPORT), payload: id })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.DELETE_REPORT),
        })
      )
  }

const fetchReportRows =
  ({ reportId, params }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.GET_REPORT_ROWS) })
    clearParams({ params })
    reports
      .getReportRows({ reportId, params })
      .then((res) => {
        dispatch({ type: SUCCESS(types.GET_REPORT_ROWS), payload: res })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_REPORT_ROWS),
        })
      )
  }

const deleteReportRow =
  ({ reportId, rowId }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.DELETE_REPORT_ROW) })
    reports
      .deleteReportRow({ reportId, rowId })
      .then(() => {
        toast.success(
          `${i18n.t('quality_control:reports:report_product')} ${i18n.t(
            'common:delete_success'
          )}`
        )
        dispatch({ type: SUCCESS(types.DELETE_REPORT_ROW), payload: rowId })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.DELETE_REPORT_ROW),
        })
      )
  }

const editReportRowStatus =
  ({ reportId, rowId, status, oldStatus }) =>
  (dispatch) => {
    dispatch({ type: PENDING(types.EDIT_REPORT_ROW_STATUS) })
    reports
      .editReportRowStatus({ reportId, rowId, status })
      .then((res) => {
        toast.success(
          `${i18n.t('quality_control:reports:report_product')} ${i18n.t(
            'common:edit_success'
          )}`
        )
        dispatch({
          type: SUCCESS(types.EDIT_REPORT_ROW_STATUS),
          payload: { data: res.data, oldStatus },
        })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.EDIT_REPORT_ROW_STATUS),
        })
      )
  }

const fetchReportRowProblems =
  ({ reportId, rowId }) =>
  (dispatch) => {
    dispatch({
      type: PENDING(types.GET_REPORT_ROW_PROBLEMS),
      payload: { rowId },
    })
    reports
      .getReportRowProblems({ reportId, rowId })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.GET_REPORT_ROW_PROBLEMS),
          payload: { data: res.data, rowId },
        })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_REPORT_ROW_PROBLEMS),
          payload: { rowId },
        })
      )
  }

const createReportRowProblem =
  ({ reportId, rowId, data, files }) =>
  (dispatch) => {
    dispatch({
      type: PENDING(types.CREATE_REPORT_ROW_PROBLEM),
    })
    reports
      .createReportRowProblem({ reportId, rowId, data })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.CREATE_REPORT_ROW_PROBLEM),
          payload: { data: res.data, rowId },
        })
        if (files?.length) {
          dispatch(
            uploadReportRowProblemFile({
              reportId,
              rowId,
              problemId: res.data.uuid,
              data: files,
            })
          )
        }
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.CREATE_REPORT_ROW_PROBLEM),
        })
      )
  }

const editReportRowProblem =
  ({ reportId, rowId, problemId, data }) =>
  (dispatch) => {
    dispatch({
      type: PENDING(types.EDIT_REPORT_ROW_PROBLEM),
    })
    reports
      .editReportRowProblem({ reportId, rowId, problemId, data })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.EDIT_REPORT_ROW_PROBLEM),
          payload: { data: res.data, rowId },
        })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.EDIT_REPORT_ROW_PROBLEM),
        })
      )
  }

const uploadReportRowProblemFile =
  ({ reportId, rowId, problemId, data }) =>
  (dispatch) => {
    dispatch({
      type: PENDING(types.UPLOAD_REPORT_ROW_PROBLEM_FILE),
    })
    return reports
      .uploadReportRowProblemFile({ reportId, rowId, problemId, data })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.UPLOAD_REPORT_ROW_PROBLEM_FILE),
          payload: { data: res.data, rowId },
        })
        if (data?.uuid) {
          dispatch(
            deleteReportRowProblemFile({
              reportId,
              rowId,
              problemId,
              fileId: data.uuid,
            })
          )
        }
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.UPLOAD_REPORT_ROW_PROBLEM_FILE),
        })

        throw err
      })
  }

const deleteReportRowProblemFile =
  ({ reportId, rowId, problemId, fileId }) =>
  (dispatch) => {
    dispatch({
      type: PENDING(types.DELETE_REPORT_ROW_PROBLEM_FILE),
    })
    return reports
      .deleteReportRowProblemFile({ reportId, rowId, problemId, fileId })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.DELETE_REPORT_ROW_PROBLEM_FILE),
          payload: { problemId, rowId, fileId },
        })

        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.DELETE_REPORT_ROW_PROBLEM_FILE),
        })

        throw err
      })
  }

const deleteReportRowProblem =
  ({ reportId, rowId, problemId }) =>
  (dispatch) => {
    dispatch({
      type: PENDING(types.DELETE_REPORT_ROW_PROBLEM),
    })
    return reports
      .deleteReportRowProblem({ reportId, rowId, problemId })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.DELETE_REPORT_ROW_PROBLEM),
          payload: { problemId, rowId },
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.DELETE_REPORT_ROW_PROBLEM),
        })
        throw err
      })
  }

const fetchReportRowDefectCategories =
  ({ params, isPaginate = false }) =>
  (dispatch) => {
    const activeType = isPaginate
      ? types.GET_MORE_REPORT_DEFECT_CATEGORIES
      : types.GET_REPORT_DEFECT_CATEGORIES
    clearParams({ params })
    dispatch({
      type: PENDING(activeType),
    })
    defectCategory
      .getDefectCatergories({ params })
      .then((res) => {
        dispatch({
          type: SUCCESS(activeType),
          payload: res,
        })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(activeType),
        })
      )
  }

const fetchReportRowDefects =
  ({ params, isPaginate = false }) =>
  (dispatch) => {
    const activeType = isPaginate
      ? types.GET_MORE_REPORT_DEFECTS
      : types.GET_REPORT_DEFECTS
    clearParams({ params })
    dispatch({
      type: PENDING(activeType),
    })
    productDefect
      .getProductDefects({ params })
      .then((res) => {
        dispatch({
          type: SUCCESS(activeType),
          payload: res,
        })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(activeType),
        })
      )
  }

const fetchReportRowCertificates =
  ({ params, rowId }) =>
  (dispatch) => {
    clearParams({ params })
    dispatch({
      type: PENDING(types.GET_REPORT_ROW_CERTIFICATES),
      payload: { rowId },
    })
    stoneCertificates
      .getStoneCertificates({ params })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.GET_REPORT_ROW_CERTIFICATES),
          payload: { data: res.data, rowId },
        })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_REPORT_ROW_CERTIFICATES),
          payload: { rowId },
        })
      )
  }

const fetchReportOrders =
  ({ params }) =>
  (dispatch) => {
    clearParams({ params })
    dispatch({
      type: PENDING(types.GET_REPORT_ORDERS),
    })
    productionOrders
      .getProductionOrders({ params })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.GET_REPORT_ORDERS),
          payload: res,
        })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_REPORT_ORDERS),
        })
      )
  }

const sendReport =
  ({ reportId }) =>
  (dispatch) => {
    dispatch({
      type: PENDING(types.SEND_REPORT),
    })
    return reports
      .sendReport({ reportId })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.SEND_REPORT),
          payload: res,
        })
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.SEND_REPORT),
        })
        throw err
      })
  }

const synchronizeReport =
  ({ reportId, orderId, data }) =>
  (dispatch) => {
    dispatch({
      type: PENDING(types.SYNCHRONIZE_REPORT),
    })
    return reports
      .synchronizeReport({ reportId, orderId, data })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.SYNCHRONIZE_REPORT),
          payload: res,
        })
        window.location.reload(false)
        return res
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(types.SYNCHRONIZE_REPORT),
        })
        throw err
      })
  }

const fetchReportRowHistory =
  ({ reportId, rowId, params }) =>
  (dispatch) => {
    dispatch({
      type: PENDING(types.GET_REPORT_ROW_HISTORY),
      payload: { rowId },
    })
    clearParams({ params })
    reports
      .getReportRowHistory({ reportId, rowId, params })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.GET_REPORT_ROW_HISTORY),
          payload: { data: res, rowId },
        })
      })
      .catch(() =>
        dispatch({
          type: FAILURE(types.GET_REPORT_ROW_HISTORY),
          payload: { rowId },
        })
      )
  }

const editReportRow =
  ({ reportId, rowId, data }) =>
  (dispatch) => {
    dispatch({
      type: PENDING(types.EDIT_REPORT_ROW),
      payload: { rowId },
    })
    reports
      .editReportRow({ reportId, rowId, data })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.EDIT_REPORT_ROW),
          payload: res.data,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.EDIT_REPORT_ROW),
          payload: err,
        })
      )
  }

const fetchReportRowFields =
  ({ reportId, rowId }) =>
  (dispatch) => {
    dispatch({
      type: PENDING(types.GET_REPORT_ROW_FIELDS),
      payload: { rowId },
    })
    reports
      .getReportRowFields({ reportId, rowId })
      .then((res) => {
        dispatch({
          type: SUCCESS(types.GET_REPORT_ROW_FIELDS),
          payload: { data: res.data, rowId },
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(types.GET_REPORT_ROW_FIELDS),
          payload: { rowId },
        })
      )
  }

const clearReport = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_REPORT) })

const clearReports = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_REPORTS) })

export default {
  fetchReports,
  fetchReport,
  createReport,
  editReport,
  deleteReport,
  fetchReportRows,
  deleteReportRow,
  editReportRowStatus,
  fetchReportRowProblems,
  fetchReportRowDefectCategories,
  fetchReportRowDefects,
  createReportRowProblem,
  deleteReportRowProblem,
  editReportRowProblem,
  uploadReportRowProblemFile,
  deleteReportRowProblemFile,
  fetchReportRowCertificates,
  fetchReportOrders,
  sendReport,
  synchronizeReport,
  fetchReportRowHistory,
  editReportRow,
  fetchReportRowFields,
  clearReport,
  clearReports,
}
