const GET_PRODUCTION_ORDERS = 'GET_PRODUCTION_ORDERS'
const GET_MORE_PRODUCTION_ORDERS = 'GET_MORE_PRODUCTION_ORDERS'
const GET_PRODUCTION_ORDER = 'GET_PRODUCTION_ORDER'
const CREATE_PRODUCTION_ORDER = 'CREATE_PRODUCTION_ORDER'
const EDIT_PRODUCTION_ORDER = 'EDIT_PRODUCTION_ORDER'
const DELETE_PRODUCTION_ORDER = 'DELETE_PRODUCTION_ORDER'
const CLEAR_PRODUCTION_ORDER = 'CLEAR_PRODUCTION_ORDER'

const GET_PRODUCTION_ORDER_PRODUCTS = 'GET_PRODUCTION_ORDER_PRODUCTS'
const CREATE_PRODUCTION_ORDER_PRODUCT = 'CREATE_PRODUCTION_ORDER_PRODUCT'
const DELETE_PRODUCTION_ORDER_PRODUCT = 'DELETE_PRODUCTION_ORDER_PRODUCT'
const EDIT_PRODUCTION_ORDER_PRODUCT = 'EDIT_PRODUCTION_ORDER_PRODUCT'

const GET_PRODUCTION_ORDER_COLLECTIONS = 'GET_PRODUCTION_ORDER_COLLECTIONS'
const GET_MORE_PRODUCTION_ORDER_COLLECTIONS =
  'GET_MORE_PRODUCTION_ORDER_COLLECTIONS'

const GET_PRODUCTION_ORDER_VARIANTS = 'GET_PRODUCTION_ORDER_VARIANTS'

const GET_PRODUCTION_ORDER_SERIALS = 'GET_PRODUCTION_ORDER_SERIALS'
const CREATE_PRODUCTION_ORDER_SERIAL = 'CREATE_PRODUCTION_ORDER_SERIAL'
const DELETE_PRODUCTION_ORDER_SERIAL = 'DELETE_PRODUCTION_ORDER_SERIAL'
const CLEAR_PRODUCTION_ORDER_SERIALS = 'CLEAR_PRODUCTION_ORDER_SERIALS'
const SEND_PRODUCTION_ORDER = 'SEND_PRODUCTION_ORDER'

const GET_PRODUCTION_ORDER_IMPORTS = 'GET_PRODUCTION_ORDER_IMPORTS'
const IMPORT_PRODUCTION_ORDER = 'IMPORT_PRODUCTION_ORDER'
const CLEAR_PRODUCTION_ORDER_IMPORTS = 'CLEAR_PRODUCTION_ORDER_IMPORTS'

const CLEAR_PRODUCTION_ORDERS = 'CLEAR_PRODUCTION_ORDERS'

export default {
  GET_PRODUCTION_ORDERS,
  GET_MORE_PRODUCTION_ORDERS,
  GET_PRODUCTION_ORDER,
  CREATE_PRODUCTION_ORDER,
  EDIT_PRODUCTION_ORDER,
  DELETE_PRODUCTION_ORDER,
  GET_PRODUCTION_ORDER_PRODUCTS,
  CREATE_PRODUCTION_ORDER_PRODUCT,
  DELETE_PRODUCTION_ORDER_PRODUCT,
  EDIT_PRODUCTION_ORDER_PRODUCT,
  GET_PRODUCTION_ORDER_COLLECTIONS,
  GET_MORE_PRODUCTION_ORDER_COLLECTIONS,
  GET_PRODUCTION_ORDER_VARIANTS,
  GET_PRODUCTION_ORDER_SERIALS,
  CREATE_PRODUCTION_ORDER_SERIAL,
  DELETE_PRODUCTION_ORDER_SERIAL,
  CLEAR_PRODUCTION_ORDER_SERIALS,
  SEND_PRODUCTION_ORDER,
  CLEAR_PRODUCTION_ORDER,
  CLEAR_PRODUCTION_ORDERS,
  GET_PRODUCTION_ORDER_IMPORTS,
  IMPORT_PRODUCTION_ORDER,
  CLEAR_PRODUCTION_ORDER_IMPORTS,
}
