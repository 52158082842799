import { requests } from '../api'

const initialEndpoint = 'countries'

const getCountries = ({ params = null }) =>
  requests.get({ url: initialEndpoint, params }).then((res) => res.data)

const createCountry = ({ data }) =>
  requests.post({ url: initialEndpoint, data }).then((res) => res.data)

const editCountry = ({ data, id }) =>
  requests
    .put({ url: `${initialEndpoint}/${id}`, data })
    .then((res) => res.data)

const deleteCountry = ({ id }) =>
  requests.delete({ url: `${initialEndpoint}/${id}` }).then((res) => res.data)

export default {
  getCountries,
  createCountry,
  editCountry,
  deleteCountry,
}
