import defectCatergory from 'services/defects/defectCategory'
import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import { clearParams } from 'utils/helpers'

const fetchDefectCategories =
  ({ params, isPaginate = false }) =>
  (dispatch) => {
    const activeType = isPaginate
      ? types.GET_MORE_DEFECT_CATEGORIES
      : types.GET_DEFECT_CATEGORIES
    dispatch({ type: PENDING(activeType) })
    clearParams({ params })
    defectCatergory
      .getDefectCatergories({ params })
      .then((res) => {
        dispatch({
          type: SUCCESS(activeType),
          payload: res,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(activeType),
          payload: err.response.data.message,
        })
      )
  }

const clearDefectCategories = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_DEFECT_CATEGORIES) })

export default {
  fetchDefectCategories,
  clearDefectCategories,
}
