const getPermissionGroupTools = (state) =>
  state.permissionGroup.permissionGroupTools.items
const getPermissionGroupToolsLoading = (state) =>
  state.permissionGroup.permissionGroupTools.loading
const getPermissionGroupToolsError = (state) =>
  state.permissionGroup.permissionGroupTools.error

const getPermissionGroupToolsMatrix = (state) =>
  state.permissionGroup.permissionGroupToolsMatrix.item
const getPermissionGroupToolsMatrixLoading = (state) =>
  state.permissionGroup.permissionGroupToolsMatrix.loading
const getPermissionGroupToolsMatrixError = (state) =>
  state.permissionGroup.permissionGroupToolsMatrix.error

export default {
  getPermissionGroupTools,
  getPermissionGroupToolsLoading,
  getPermissionGroupToolsError,
  getPermissionGroupToolsMatrix,
  getPermissionGroupToolsMatrixLoading,
  getPermissionGroupToolsMatrixError,
}
