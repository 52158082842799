import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const initialState = {
  defectCategories: {
    items: [],
    loading: true,
    moreLoading: false,
    error: '',
    totalPages: 0,
  },
}

const defectCategoryReducer = (state = initialState, { payload, type }) => {
  let index
  switch (type) {
    case PENDING(types.GET_DEFECT_CATEGORIES):
      return {
        ...state,
        defectCategories: {
          ...state.defectCategories,
          loading: true,
        },
      }
    case SUCCESS(types.GET_DEFECT_CATEGORIES):
      return {
        ...state,
        defectCategories: {
          ...state.defectCategories,
          items: payload.data,
          totalPages: payload.meta.last_page,
          error: '',
          loading: false,
        },
      }
    case FAILURE(types.GET_DEFECT_CATEGORIES):
      return {
        ...state,
        defectCategories: {
          ...state.defectCategories,
          loading: false,
          error: payload.message,
        },
      }
    case PENDING(types.GET_MORE_DEFECT_CATEGORIES):
      return {
        ...state,
        defectCategories: {
          ...state.defectCategories,
          moreLoading: true,
        },
      }
    case SUCCESS(types.GET_MORE_DEFECT_CATEGORIES):
      return {
        ...state,
        defectCategories: {
          ...state.defectCategories,
          items: [...state.defectCategories.items, ...payload.data],
          error: '',
          moreLoading: false,
        },
      }
    case FAILURE(types.GET_MORE_DEFECT_CATEGORIES):
      return {
        ...state,
        defectCategories: {
          ...state.defectCategories,
          moreLoading: false,
          error: payload?.message,
        },
      }
    case SUCCESS(types.CREATE_DEFECT_CATEGORY):
      return {
        ...state,
        defectCategories: {
          ...state.defectCategories,
          items: [...state.defectCategories.items, payload],
        },
      }
    case SUCCESS(types.EDIT_DEFECT_CATEGORY):
      const newItems = state.defectCategories.items
      index = newItems.findIndex((el) => el.uuid === payload.uuid)
      newItems[index] = payload
      return {
        ...state,
        defectCategories: {
          ...state.defectCategories,
          items: [...newItems],
        },
      }
    case SUCCESS(types.DELETE_DEFECT_CATEGORY):
      index = state.defectCategories.items.findIndex(
        (el) => el.uuid === payload
      )
      return {
        ...state,
        defectCategories: {
          ...state.defectCategories,
          items: [
            ...state.defectCategories.items.slice(0, index),
            ...state.defectCategories.items.slice(index + 1),
          ],
        },
      }
    case SUCCESS(types.CLEAR_DEFECT_CATEGORIES):
      return {
        ...state,
        defectCategories: {
          items: [],
          loading: true,
          moreLoading: false,
          error: '',
          totalPages: 0,
        },
      }
    default:
      return state
  }
}

export default defectCategoryReducer
