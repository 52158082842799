import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore, combineReducers } from 'redux'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import thunk from 'redux-thunk'

import { addInterceptors } from 'services/api'

import allReducers from './ducks'

const history = createBrowserHistory()

const configureStore = (initialState) => {
  const { NODE_ENV } = process.env
  const developMode = NODE_ENV === 'development'
  const reduxDevTools =
    (developMode && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

  const middleware = [thunk, routerMiddleware(history)]

  const appReducer = combineReducers({
    ...allReducers,
    router: connectRouter(history),
  })

  const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT_SUCCESS') {
      const newState = {
        common: {
          ...state.common,
          theme: state.common.theme,
        },
      }
      return appReducer(newState, action)
    }

    return appReducer(state, action)
  }

  const store = createStore(
    rootReducer,
    initialState,
    reduxDevTools(applyMiddleware(...middleware))
  )

  addInterceptors(store)

  return store
}

export default configureStore
