import routes from './routes'

export default {
  // dashboard: {
  //   name: 'Dashboard',
  //   route: routes.dashboard,
  // },
  productLibrary: {
    name: 'Product librairy',
    route: routes.productLibrary.root,
    permissions: {
      Collections: {
        name: 'Collections',
        get_collections: 'Permission: GET api/v1/collections',
        create_collection: 'Permission: POST api/v1/collections',
        get_collection: 'Permission: GET api/v1/collections/{collection}',
        edit_collection: 'Permission: PUT api/v1/collections/{collection}',
        delete_collection: 'Permission: DELETE api/v1/collections/{collection}',
        merge_collection: 'Permission: POST api/v1/collections/merge',
      },
      product_remove: {
        name: 'Deleted products',
        archive_product: 'Permission: DELETE api/v1/products/{product}/archive',
      },
      colletion_remove: {
        name: 'Deleted collections',
        archive_collection:
          'Permission: DELETE api/v1/collections/{collection}/archive',
        unarchive_collection:
          'Permission: POST api/v1/collections/{trashedCollection}/unarchive',
      },
      collection_content: {
        name: 'Collection content',
        edit_collection_content:
          'Permission: PUT api/v1/collections/{collection}/content/{languageId}',
        create_collection_content:
          'Permission: POST api/v1/collections/{collection}/content',
        delete_collection_content:
          'Permission: DELETE api/v1/collections/{collection}/content/{languageId}',
      },
      collection_share: {
        name: 'Share collection',
        share: 'Permission: POST api/v1/collections/{collection}/share',
        unshare: 'Permission: POST api/v1/collections/{collection}/unshare',
        get_shared: 'Permission: GET api/v1/collections/{anyCollection}/shares',
      },
      product_content: {
        name: 'Product contents',
        edit_product_content:
          'Permission: PUT api/v1/products/{product}/content/{language}',
        create_product_content:
          'Permission: POST api/v1/products/{product}/content',
        delete_product_content:
          'Permission: DELETE api/v1/products/{product}/content/{language}',
      },
      product_share: {
        name: 'Shared products',
        share: 'Permission: POST api/v1/products/{product}/share',
        unshare: 'Permission: POST api/v1/products/{product}/unshare',
        get_shared: 'Permission: GET api/v1/products/{product}/shares',
      },
      Products: {
        name: 'Products',
        create_product: 'Permission: POST api/v1/products',
        edit_product: 'Permission: PUT api/v1/products/{product}',
        delete_product: 'Permission: DELETE api/v1/products/{product}',
      },
      variants: {
        name: 'Product variants',
        create_variant: 'Permission: POST api/v1/products/{product}/variants',
        edit_variant:
          'Permission: PUT api/v1/products/{product}/variants/{variant}',
        delete_variant:
          'Permission: DELETE api/v1/products/{product}/variants/{variant}',
      },
      variant_content: {
        name: 'Product variant content',
        edit_variant_content:
          'Permission: POST api/v1/products/{product}/variants/{variant}/content',
        create_variant_content:
          'Permission: PUT api/v1/products/{product}/variants/{variant}/content/{language}',
        delete_variant_content:
          'Permission: DELETE api/v1/products/{product}/variants/{variant}/content/{language}',
      },
    },
  },
  adminPanel: {
    name: 'Admin panel',
    route: routes.adminPanel.root,
    permissions: {
      Currencies: {
        name: 'Currencies',
        get_currencies: 'Permission: GET api/v1/currencies',
        create_currency: 'Permission: POST api/v1/currencies',
        delete_currency: 'Permission: DELETE api/v1/currencies/{currency}',
        edit_currency: 'Permission: PUT api/v1/currencies/{currency}',
      },
      Countries: {
        name: 'Countries',
        get_countries: 'Permission: GET api/v1/countries',
        create_countries: 'Permission: POST api/v1/countries',
        delete_countries: 'Permission: DELETE api/v1/countries/{country}',
        edit_countries: 'Permission: PUT api/v1/countries/{country}',
      },
      Regions: {
        name: 'Regions',
        get_regions: 'Permission: GET api/v1/regions',
        create_region: 'Permission: POST api/v1/regions',
        delete_region: 'Permission: DELETE api/v1/regions/{region}',
        edit_region: 'Permission: PUT api/v1/regions/{region}',
      },
      Languages: {
        name: 'Languages',
        get_languages: 'Permission: GET api/v1/languages',
        create_language: 'Permission: POST api/v1/languages',
        delete_language: 'Permission: DELETE api/v1/languages/{language}',
        edit_language: 'Permission: PUT api/v1/languages/{language}',
      },
      Certificates: {
        name: 'Company certifications',
        get_certificates: 'Permission: GET api/v1/certifications',
        create_certificate: 'Permission: POST api/v1/certifications',
        delete_certificate:
          'Permission: DELETE api/v1/certifications/{certification}',
        edit_certificate:
          'Permission: PUT api/v1/certifications/{certification}',
      },
    },
  },
  companyManagement: {
    name: 'Company managment',
    route: routes.companyManagement.root,
    permissions: {
      Companies: {
        name: 'Companies',
        create_company: 'Permission: POST api/v1/companies',
        edit_company: 'Permission: PUT api/v1/companies/{company}',
        delete_company: 'Permission: DELETE api/v1/companies/{company}',
      },
      company_remove: {
        name: 'Deleted companies',
        archive_company:
          'Permission: DELETE api/v1/companies/{company}/archive',
        unarchive_company:
          'Permission: POST api/v1/companies/{trashedCompany}/unarchive',
      },
      company_certificates: {
        name: 'Company certificates',
        create_certificate: 'Permission: POST api/v1/company-certificates',
        edit_certificate:
          'Permission: PUT api/v1/company-certificates/{company_certificate}',
        delete_certificate:
          'Permission: DELETE api/v1/company-certificates/{company_certificate}',
        validate_certificate:
          'Permission: PUT api/v1/company-certificates/{companyCertificate}/validate',
      },
      company_logo: {
        name: 'Company logo',
        create_logo: 'Permission: POST api/v1/companies/{company}/logo',
        delete_logo: 'Permission: DELETE api/v1/companies/{company}/logo',
      },
      related_companies: {
        name: 'Related companies',
        get_linked_companies:
          'Permission: GET api/v1/companies/{company}/linked-companies',
        get_requests: 'Permission: GET api/v1/company-link-requests',
        create_request: 'Permission: POST api/v1/company-link-requests',
        approve_request:
          'Permission: PATCH api/v1/company-link-requests/{linkRequest}/approve',
        decline_request:
          'Permission: PATCH api/v1/company-link-requests/{linkRequest}/decline',
      },
      Users: {
        name: 'Users',
        get_users: 'Permission: GET api/v1/users',
        create_user: 'Permission: POST api/v1/users',
        edit_user: 'Permission: PUT api/v1/users/{user}',
        delete_user: 'Permission: DELETE api/v1/users/{user}',
        get_company_users: 'Permission: GET api/v1/companies/{company}/users',
      },
      user_remove: {
        name: 'Deleted users',
        archive: 'Permission: DELETE api/v1/users/{user}/archive',
        unarchive: 'Permission: POST api/v1/users/{trashedUser}/unarchive',
      },
    },
  },
  orders: {
    name: 'Orders',
    route: routes.quality_control.orders.root,
    permissions: {
      products: {
        name: 'Production order products',
        create_product:
          'Permission: POST api/v1/production-orders/{productionOrder}/products',
        edit_product:
          'Permission: PUT api/v1/production-orders/{productionOrder}/products/{productionOrderProduct}',
        delete_product:
          'Permission: DELETE api/v1/production-orders/{productionOrder}/products/{productionOrderProduct}',
        get_products:
          'Permission: GET api/v1/production-orders/{productionOrder}/products',
        get_instances:
          'Permission: GET api/v1/production-orders/{productionOrder}/products/{productionOrderProduct}/instances',
        add_instaces:
          'Permission: POST api/v1/production-orders/{productionOrder}/products/{productionOrderProduct}/instances',
        delete_instance:
          'Permission: DELETE api/v1/production-orders/{productionOrder}/products/{productionOrderProduct}/instances',
      },
      orders: {
        name: 'Production orders',
        create_order: 'Permission: POST api/v1/production-orders',
        edit_order:
          'Permission: PUT api/v1/production-orders/{production_order}',
        delete_order:
          'Permission: DELETE api/v1/production-orders/{production_order}',
        send_order:
          'Permission: PATCH api/v1/production-orders/{productionOrder}/send',
      },
      import: {
        name: 'Data import',
        get_imports: 'Permission: GET api/v1/imports',
        create_import: 'Permission: POST api/v1/imports',
      },
    },
  },
  reports: {
    name: 'QC Reports',
    route: routes.quality_control.reports.root,
    permissions: {
      reports: {
        name: 'QC Reports',
        create_report: 'Permission: POST api/v1/reports',
        edit_report: 'Permission: PUT api/v1/reports/{report}',
        delete_report: 'Permission: DELETE api/v1/reports/{report}',
        send_report: 'Permission: POST api/v1/reports/{report}/send',
      },
      report_rows: {
        name: 'QC Report row',
        get_rows: 'Permission: GET api/v1/reports/{report}/rows',
        delete_row: 'Permission: DELETE api/v1/reports/{report}/rows/{row}',
        create_row: 'Permission: POST api/v1/reports/{report}/rows',
        get_history:
          'Permission: GET api/v1/reports/{report}/rows/{row}/history',
        change_status:
          'Permission: PATCH api/v1/reports/{report}/rows/{row}/status/{status}',
        edit_row: 'Permission: PUT api/v1/reports/{report}/rows/{row}',
      },
      product: {
        name: 'QC Reports products',
        sync_products:
          'Permission: POST api/v1/reports/{report}/production-orders/{productionOrder}/products/synchronize',
        sync: 'Permission: POST api/v1/reports/{report}/production-orders/{productionOrder}/synchronize',
      },
      problems: {
        name: 'QC Reports row problems',
        get_problems:
          'Permission: GET api/v1/reports/{report}/rows/{row}/problems',
        create_problem:
          'Permission: POST api/v1/reports/{report}/rows/{row}/problems',
        edit_problem:
          'Permission: PUT api/v1/reports/{report}/rows/{row}/problems/{problem}',
        delete_problem:
          'Permission: DELETE api/v1/reports/{report}/rows/{row}/problems/{problem}',
      },
    },
  },
  templates: {
    name: 'QC Reports Templates',
    route: routes.quality_control.templates.root,
    permissions: {
      templates: {
        name: 'QC Report templates',
        get_templates: 'Permission: GET api/v1/report-templates',
        create_template: 'Permission: POST api/v1/report-templates',
        edit_template:
          'Permission: PUT api/v1/report-templates/{report_template}',
        delete_template:
          'Permission: DELETE api/v1/report-templates/{report_template}',
      },
      fields: {
        name: 'Template custom field',
        get_fields: 'Permission: GET api/v1/custom-fields',
        create_field: 'Permission: POST api/v1/custom-fields',
        edit_field: 'Permission: PUT api/v1/custom-fields/{customField}',
        delete_field: 'Permission: DELETE api/v1/custom-fields/{customField}',
      },
      template_fields: {
        name: 'QC Report templates fields',
        change_position:
          'Permission: PATCH api/v1/report-templates/{reportTemplate}/custom-fields/{customField}/{position}',
        add_field_to_template:
          'Permission: POST api/v1/report-templates/{reportTemplate}/custom-fields/{customField}',
        edit_template_field:
          'Permission: PUT api/v1/report-templates/{reportTemplate}/custom-fields/{customField}',
        delete_template_field:
          'Permission: DELETE api/v1/report-templates/{reportTemplate}/custom-fields/{customField}',
      },
    },
  },
}
