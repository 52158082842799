import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import CommonButton from 'components/common/CommonButton'

import styles from './Pagination.module.scss'

const activePages = 7
const defaultPages = 9
const activeLength = 5
const pageDifference = 3

const Pagination = ({
  onClick,
  activePage = 1,
  totalPages = 1,
  isLoading = false,
  customStyle = '',
}) => {
  const { t } = useTranslation()

  const handlePrev = useCallback(() => {
    if (activePage !== 1) {
      onClick(activePage - 1)
    }
  }, [activePage, onClick])

  const handleNext = useCallback(() => {
    if (activePage !== totalPages) {
      onClick(activePage + 1)
    }
  }, [onClick, activePage, totalPages])

  const renderPages = useCallback(() => {
    switch (true) {
      case totalPages <= defaultPages:
        return [...Array(totalPages).keys()].map((el) => (
          <CommonButton
            key={el}
            name={el + 1}
            onClick={() => onClick(el + 1)}
            isActive={el + 1 === activePage}
            customStyle={styles.pagination_pageButton}
            disabled={isLoading}
          />
        ))
      case activePage < activePages:
        return (
          <>
            {[...Array(totalPages).keys()].slice(0, activePages).map((el) => (
              <CommonButton
                key={el}
                name={el + 1}
                onClick={() => onClick(el + 1)}
                isActive={el + 1 === activePage}
                customStyle={styles.pagination_pageButton}
                disabled={isLoading}
              />
            ))}
            <CommonButton
              name={'...'}
              onClick={() => null}
              customStyle={`${styles.pagination_pageButton} ${styles.pagination_dotsButton}`}
              disabled={isLoading}
            />
            <CommonButton
              name={totalPages}
              onClick={() => onClick(totalPages)}
              isActive={totalPages === activePage}
              customStyle={styles.pagination_pageButton}
              disabled={isLoading}
            />
          </>
        )
      case activePage + activePages - 1 > totalPages:
        return (
          <>
            <CommonButton
              name={1}
              onClick={() => onClick(1)}
              isActive={1 === activePage}
              customStyle={styles.pagination_pageButton}
              disabled={isLoading}
            />
            <CommonButton
              name={'...'}
              onClick={() => null}
              customStyle={`${styles.pagination_pageButton} ${styles.pagination_dotsButton}`}
              disabled={isLoading}
            />
            {[...Array(totalPages).keys()]
              .slice(totalPages - activePages, totalPages)
              .map((el) => (
                <CommonButton
                  key={el}
                  name={el + 1}
                  onClick={() => onClick(el + 1)}
                  isActive={el + 1 === activePage}
                  customStyle={styles.pagination_pageButton}
                  disabled={isLoading}
                />
              ))}
          </>
        )
      default:
        return (
          <>
            <CommonButton
              name={1}
              onClick={() => onClick(1)}
              isActive={1 === activePage}
              customStyle={styles.pagination_pageButton}
              disabled={isLoading}
            />
            <CommonButton
              name={'...'}
              onClick={() => null}
              customStyle={`${styles.pagination_pageButton} ${styles.pagination_dotsButton}`}
              disabled={isLoading}
            />
            {[...Array(totalPages).keys()]
              .slice(
                activePage - pageDifference,
                activePage + activeLength - pageDifference
              )
              .map((el) => (
                <CommonButton
                  key={el}
                  name={el + 1}
                  onClick={() => onClick(el + 1)}
                  isActive={el + 1 === activePage}
                  customStyle={styles.pagination_pageButton}
                  disabled={isLoading}
                />
              ))}
            <CommonButton
              name={'...'}
              onClick={() => null}
              customStyle={`${styles.pagination_pageButton} ${styles.pagination_dotsButton}`}
              disabled={isLoading}
            />
            <CommonButton
              name={totalPages}
              onClick={() => onClick(totalPages)}
              isActive={totalPages === activePage}
              customStyle={styles.pagination_pageButton}
              disabled={isLoading}
            />
          </>
        )
    }
  }, [totalPages, activePage, onClick, isLoading])

  return totalPages > 1 ? (
    <div className={`${styles.pagination} ${customStyle}`}>
      <button
        className={styles.pagination_button}
        onClick={handlePrev}
        disabled={isLoading || activePage === 1}
        type="button"
      >
        {t('common:prev')}
      </button>
      {renderPages()}
      <button
        className={styles.pagination_button}
        onClick={handleNext}
        disabled={isLoading || activePage === totalPages}
        type="button"
      >
        {t('common:next')}
      </button>
    </div>
  ) : null
}

Pagination.propTypes = {
  onClick: PropTypes.func.isRequired,
  activePage: PropTypes.number,
  totalPages: PropTypes.number,
  isLoading: PropTypes.bool,
  customStyle: PropTypes.string,
}

export default Pagination
