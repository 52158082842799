const SET_BREADCRUMB = 'SET_BREADCRUMB'
const CLEAR_BREADCRUMB = 'CLEAR_BREADCRUMB'
const SET_LANGUAGE = 'SET_LANGUAGE'
const SET_THEME = 'SET_THEME'
const SET_ACTION_LOADING = 'SET_ACTION_LOADING'

const FETCH_GLOBAL_SEARCH = 'FETCH_GLOBAL_SEARCH'

export default {
  SET_BREADCRUMB,
  CLEAR_BREADCRUMB,
  SET_LANGUAGE,
  SET_THEME,
  SET_ACTION_LOADING,
  FETCH_GLOBAL_SEARCH,
}
