const getProductionOrders = (state) =>
  state.productionOrder.productionOrders.items
const getProductionOrdersLoading = (state) =>
  state.productionOrder.productionOrders.loading
const getProductionOrdersPages = (state) =>
  state.productionOrder.productionOrders.totalPages
const getProductionOrdersMoreLoading = (state) =>
  state.productionOrder.productionOrders.moreLoading

const getProductionOrder = (state) => state.productionOrder.activeOrder.item
const getProductionOrderLoading = (state) =>
  state.productionOrder.activeOrder.loading

const getProductionOrdeProducts = (state) =>
  state.productionOrder.orderProducts.items
const getProductionOrdeProductsLoading = (state) =>
  state.productionOrder.orderProducts.loading
const getProductionOrdeProductsPages = (state) =>
  state.productionOrder.orderProducts.totalPages
const getProductionOrdeProductsTotal = (state) =>
  state.productionOrder.orderProducts.total

const getProductionOrderCollections = (state) =>
  state.productionOrder.productionOrderCollections.items
const getProductionOrderCollectionsLoading = (state) =>
  state.productionOrder.productionOrderCollections.loading
const getProductionOrderCollectionsMoreLoading = (state) =>
  state.productionOrder.productionOrderCollections.moreLoading
const getProductionOrderCollectionsPages = (state) =>
  state.productionOrder.productionOrderCollections.totalPages

const getProductionOrderVariants = (state) =>
  state.productionOrder.orderVariants.items
const getProductionOrderVariantsLoading = (state) =>
  state.productionOrder.orderVariants.loading
const getProductionOrderVariantsPages = (state) =>
  state.productionOrder.orderVariants.totalPages

const getProductionOrderSerials = (state) =>
  state.productionOrder.orderSerials.items
const getProductionOrderSerialsLoading = (state) =>
  state.productionOrder.orderSerials.loading
const getProductionOrderSerialsPages = (state) =>
  state.productionOrder.orderSerials.totalPages
const getProductionOrderSerialsTotal = (state) =>
  state.productionOrder.orderSerials.total

const getProductionOrderImportsInfo = (state) =>
  state.productionOrder.orderImports

export default {
  getProductionOrders,
  getProductionOrdersLoading,
  getProductionOrdersPages,
  getProductionOrder,
  getProductionOrderLoading,
  getProductionOrdeProducts,
  getProductionOrdeProductsLoading,
  getProductionOrdeProductsTotal,
  getProductionOrdeProductsPages,
  getProductionOrderCollections,
  getProductionOrderCollectionsLoading,
  getProductionOrderCollectionsPages,
  getProductionOrderCollectionsMoreLoading,
  getProductionOrderVariants,
  getProductionOrderVariantsLoading,
  getProductionOrderVariantsPages,
  getProductionOrderSerials,
  getProductionOrderSerialsLoading,
  getProductionOrderSerialsPages,
  getProductionOrderSerialsTotal,
  getProductionOrdersMoreLoading,
  getProductionOrderImportsInfo,
}
