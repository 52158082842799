import { THEMES, DEFAULT_LANGUAGE } from 'constants/configs'

export const getTokenFromStorage = () =>
  JSON.parse(localStorage.getItem('token'))?.access_token || ''

export const getRefreshTokenFromStorage = () =>
  JSON.parse(localStorage.getItem('token'))?.refresh_token || ''

export const removeTokens = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('isAdmin')
}

export const getThemeFromStorage = () =>
  localStorage.getItem('theme') || THEMES.light

export const getLanguageFromStorage = () =>
  (localStorage.getItem('i18nextLng') || DEFAULT_LANGUAGE).toUpperCase()

export const getIsAdminFromStorage = () =>
  localStorage.getItem('isAdmin') || false

export const filesToFormData = (files, key = 'files[]') => {
  const formData = new FormData()

  if (files?.length) {
    for (let i = 0; i < files.length; i++) {
      formData.append(key, files[i])
    }
  } else {
    formData.append(key, files)
  }

  return formData
}

export const objectsToFormData = (objects, listName = 'files[]') => {
  const formData = new FormData()
  objects.forEach((obj) =>
    Object.keys(obj).forEach((key) =>
      formData.append(`${listName}[${key}]`, obj[key])
    )
  )
  return formData
}

export const objectToFormData = (object) => {
  const formData = new FormData()
  Object.keys(object).forEach((key) => formData.append(`${key}`, object[key]))
  return formData
}
