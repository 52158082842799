export default {
  baseInfo: 'Base Product Information',
  create: 'Create Product',
  productName: 'Product Name',
  productNameHolder: 'Enter Base Product Name*',
  productDescHolder: 'Enter Product Description',
  ownerHolder: 'Enter Owner’s Name*',
  productInfo: 'Product Information',
  addVariants: 'Add Variants',
  variants: 'Variants',
  variant: 'Variant',
  varAttributes: 'Variant Attributes',
  varInfo: 'Variant Information',
  createVariant: 'Create Variant',
  refHolder: 'Enter Reference Name',
  variantNameHolder: 'Enter Variant Name',
  variantDescHolder: 'Enter Variant Description',
  variantName: 'Variant Name',
  attributes: 'Attributes',
  product: 'Product',
  serialTableTitle: 'Serial Numbers and Unique Products',
  emptySerialDesc: 'There are no serial numbers yet',
  addNumber: 'Add Number',
  addSerialNubmer: 'Add serial numbers',
  addSerialDesc: 'Enter serial numbers, new serial number in each row',
  addProduct: 'Add New Product',
  serialNumber: 'Serial number',
  deleteSerialTitle: 'You are about to delete a serial number',
  deleteSerialDesc: 'This will delete the serial number from catalog',
  deleteVariantTitle: 'You are about to delete a variant',
  deleteVariantDesc: 'This will delete your variant from catalog',
  deleteProductTitle: 'You are about to delete a product',
  deleteProductDesc: 'This will delete your product from catalog',
  deleteProduct: 'Delete Product',
  serial_numbers: 'Serial Numbers',
  unassigned: 'Unassigned products',
  base_product: 'Base Product',
  serials_empty: 'There is no serial numbers',
  empty_description: 'There is no products',
  serial_numbers_small: 'serial numbers',
  edit_comment_for_product: 'Edit Comment for Product',
}
