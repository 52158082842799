import productDefect from 'services/defects/productDefect'
import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import { clearParams } from 'utils/helpers'

const fetchProductDefects =
  ({ params, isPaginate = false }) =>
  (dispatch) => {
    const activeType = isPaginate
      ? types.GET_MORE_PRODUCT_DEFECTS
      : types.GET_PRODUCT_DEFECTS
    dispatch({ type: PENDING(activeType) })
    clearParams({ params })
    productDefect
      .getProductDefects({ params })
      .then((res) => {
        dispatch({
          type: SUCCESS(activeType),
          payload: res,
        })
      })
      .catch((err) =>
        dispatch({
          type: FAILURE(activeType),
          payload: err.response.data.message,
        })
      )
  }

const clearProductDefects = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_PRODUCT_DEFECTS) })

export default {
  fetchProductDefects,
  clearProductDefects,
}
