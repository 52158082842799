import types from './actionTypes'
import { FAILURE, PENDING, SUCCESS } from 'constants/configs'
import { THEMES } from 'constants/configs'
import { getThemeFromStorage, getLanguageFromStorage } from 'utils/serializer'

const initialState = {
  customBreadcrumbs: [],
  activeLang: getLanguageFromStorage(),
  theme: getThemeFromStorage(),
  isActionLoading: false,
  globalSearch: {
    items: null,
    loading: true,
  },
}

const commonReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case SUCCESS(types.SET_BREADCRUMB):
      return {
        ...state,
        customBreadcrumbs: payload,
      }
    case SUCCESS(types.CLEAR_BREADCRUMB):
      return {
        ...state,
        customBreadcrumbs: [],
      }
    case SUCCESS(types.SET_LANGUAGE):
      return {
        ...state,
        activeLang: payload,
      }
    case SUCCESS(types.SET_THEME):
      const activeTheme =
        state.theme === THEMES.light ? THEMES.dark : THEMES.light
      localStorage.setItem('theme', activeTheme)
      return {
        ...state,
        theme: activeTheme,
      }
    case SUCCESS(types.SET_ACTION_LOADING):
      return {
        ...state,
        isActionLoading: payload,
      }
    case PENDING(types.FETCH_GLOBAL_SEARCH):
      return {
        ...state,
        globalSearch: {
          ...state.globalSearch,
          loading: true,
        },
      }
    case SUCCESS(types.FETCH_GLOBAL_SEARCH):
      return {
        ...state,
        globalSearch: {
          ...state.globalSearch,
          items: payload,
          loading: false,
        },
      }
    case FAILURE(types.FETCH_GLOBAL_SEARCH):
      return {
        ...state,
        globalSearch: {
          ...state.globalSearch,
          loading: false,
        },
      }
    default:
      return state
  }
}

export default commonReducer
