import React, { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { useSelector } from 'react-redux'

import { THEMES } from 'constants/configs'

import { commonSelectors } from 'store/ducks/common'
import { authSelectors } from 'store/ducks/auth'

import CommonAccordion from 'components/common/CommonAccordion'
import CommonButton from 'components/common/CommonButton'

import blueLogo from 'assets/icons/logos/blueLogo.svg'
import whiteLogo from 'assets/icons/logos/whiteLogo.svg'

import { getActiveLinks } from './links'

import styles from './Sidebar.module.scss'

const Sidebar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useSelector(commonSelectors.getActiveTheme)
  const { items: tools } = useSelector(authSelectors.getMyToolsInfo)
  const { isAdmin } = useSelector(authSelectors.getAuthInfo)

  const isLight = theme === THEMES.light

  const links = getActiveLinks(tools, isAdmin)

  const handleNavigate = useCallback(
    (route) => {
      navigate(route)
    },
    [navigate]
  )

  return (
    <div className={styles.sidebarContainer}>
      <img
        src={isLight ? blueLogo : whiteLogo}
        alt="logo"
        className={styles.sidebarContainer_logo}
      />
      <div className={styles.sidebarContainer_navigation}>
        {links.map((el) =>
          el?.subRoutes?.length ? (
            <div
              key={el.name}
              className={`${styles.sidebarContainer_navigation_item} ${styles.sidebarContainer_navigation_accordion}`}
            >
              <CommonAccordion
                name={el.name}
                Icon={el.Icon}
                customIsActive={
                  !!el.subRoutes.find((el) =>
                    location.pathname.includes(el.link)
                  )
                }
              >
                {el.subRoutes.map((subLink) => (
                  <div
                    key={subLink.name}
                    className={`${styles.subLink} ${
                      location.pathname.includes(subLink.link)
                        ? styles.subLink_active
                        : ''
                    }`}
                  >
                    <p
                      className={styles.subLink_name}
                      onClick={() => handleNavigate(subLink.link)}
                    >
                      {subLink.name}
                    </p>
                  </div>
                ))}
              </CommonAccordion>
            </div>
          ) : (
            <CommonButton
              key={el.name}
              name={el.name}
              onClick={() => handleNavigate(el.link)}
              Icon={el.Icon}
              customStyle={`${styles.sidebarContainer_navigation_item} ${styles.sidebarContainer_navigation_link}`}
              customActive={styles.sidebarContainer_navigation_link_active}
              isActive={location.pathname.includes(el.link)}
            />
          )
        )}
      </div>
    </div>
  )
}

export default Sidebar
