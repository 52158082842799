const getProductDefects = (state) => state.productDefect.productDefects.items
const getProductDefectsLoading = (state) =>
  state.productDefect.productDefects.loading
const getProductDefectsPages = (state) =>
  state.productDefect.productDefects.totalPages
const getProductDefectsMoreLoading = (state) =>
  state.productDefect.productDefects.moreLoading

export default {
  getProductDefects,
  getProductDefectsLoading,
  getProductDefectsPages,
  getProductDefectsMoreLoading,
}
